import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { labTemplatesPaths } from "pages/HealthTemplate/LabParameters/constants/labTemplatesPaths";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDuplicateLabTemplateMutation, useGetLabTemplateQuery } from "../gql";

export const useDuplicateLabTemplate = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const params = useParams() as { id: string };
  const { id } = params;

  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);
  const { data, loading } = useGetLabTemplateQuery({
    variables: {
      id: id!,
    },
  });

  const [labTemplateClone, { loading: isSubmitting }] = useDuplicateLabTemplateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.duplicateLabTemplate?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Lab Template Cloned Successfully"));
        navigate(labTemplatesPaths.listPath.fullPath);
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Lab Template Cloned Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmit = input => {
    labTemplateClone({
      variables: {
        id: id,
        input: {
          ...input,
          isActive: input?.isActive || false,
          code: input?.code?.value?.code ?? "",
        },
      },
    });
  };

  return {
    errors,
    handleSubmit,
    isSubmitting,
    isLoading: loading,
    clonedData: data?.getLabTemplate,
  };
};
