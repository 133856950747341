import {
  combineErrors,
  getMaxLengthValidation,
  getRequiredValidation,
  patternArabicTextWithSpecialCharAndNumbers,
  required,
  validateValueIsNotEmpty,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Divider, Grid, TextField, ToggleButtonController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IForm, IFormData } from "../types";

export const GeneralInformation: FC<IForm> = ({ riskTemplate, errors, isClone }) => {
  const { t } = useTranslation("gcadmin");
  const {
    register,
    formState: { errors: reactFormErrors },
    control,
  } = useFormContext<IFormData>();

  const formErrors = combineErrors(reactFormErrors, errors);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("risk-display")}
          error={Boolean(formErrors.display?.message)}
          helperText={t(formErrors.display?.message)}
          defaultValue={riskTemplate?.display}
          {...register("display", {
            required: required(t),
            maxLength: getMaxLengthValidation(t, 255),
            validate: value => validateValueIsNotEmpty(value, t),
          })}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("risk-arabic-display")}
          error={Boolean(formErrors.arabicDisplay?.message)}
          helperText={t(formErrors.arabicDisplay?.message)}
          defaultValue={riskTemplate?.arabicDisplay}
          {...register("arabicDisplay", {
            required: required(t),
            maxLength: getMaxLengthValidation(t, 255),
            pattern: patternArabicTextWithSpecialCharAndNumbers,
          })}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("Code")}
          disabled={Boolean(riskTemplate && !isClone)}
          error={Boolean(formErrors.code?.message)}
          helperText={t(formErrors.code?.message)}
          defaultValue={!isClone ? riskTemplate?.code : undefined}
          {...register("code", {
            required: getRequiredValidation(t, !riskTemplate?.code),
            maxLength: getMaxLengthValidation(t, 255),
          })}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box display='flex' alignItems='center'>
          <ToggleButtonController
            {...register("isActive")}
            control={control}
            defaultValue={riskTemplate?.isActive || false}
            label={t("Is Active")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
