/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthParameterFragmentDoc } from '../../../../HealthParameters/Parameters/gql/fragments/__generated__/HealthParameter';
export type HealthConditionFragmentFragment = { __typename?: 'HealthCondition', arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, disease?: string | null, display?: string | null, id?: string | null, isActive?: boolean | null, type?: Types.HealthConditionType | null, umlsCode?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null> | null };

export const HealthConditionFragmentFragmentDoc = gql`
    fragment HealthConditionFragment on HealthCondition {
  arabicDisplay
  code
  createdDate
  disease
  display
  errors {
    code
    errorType
    field
    message
  }
  id
  isActive
  type
  umlsCode
  fields {
    ...HealthParameter
  }
}
    ${HealthParameterFragmentDoc}`;