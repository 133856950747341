/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthConditionFragmentFragmentDoc } from '../../fragments/__generated__/healthConditionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHealthConditionQueryVariables = Types.Exact<{
  getHealthConditionId: Types.Scalars['ID'];
}>;


export type GetHealthConditionQuery = { __typename?: 'Query', getHealthCondition?: { __typename?: 'HealthCondition', arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, disease?: string | null, display?: string | null, id?: string | null, isActive?: boolean | null, type?: Types.HealthConditionType | null, umlsCode?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null> | null } | null };


export const GetHealthConditionDocument = gql`
    query GetHealthCondition($getHealthConditionId: ID!) {
  getHealthCondition(id: $getHealthConditionId) {
    ...HealthConditionFragment
  }
}
    ${HealthConditionFragmentFragmentDoc}`;

/**
 * __useGetHealthConditionQuery__
 *
 * To run a query within a React component, call `useGetHealthConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthConditionQuery({
 *   variables: {
 *      getHealthConditionId: // value for 'getHealthConditionId'
 *   },
 * });
 */
export function useGetHealthConditionQuery(baseOptions: Apollo.QueryHookOptions<GetHealthConditionQuery, GetHealthConditionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthConditionQuery, GetHealthConditionQueryVariables>(GetHealthConditionDocument, options);
      }
export function useGetHealthConditionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthConditionQuery, GetHealthConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthConditionQuery, GetHealthConditionQueryVariables>(GetHealthConditionDocument, options);
        }
export type GetHealthConditionQueryHookResult = ReturnType<typeof useGetHealthConditionQuery>;
export type GetHealthConditionLazyQueryHookResult = ReturnType<typeof useGetHealthConditionLazyQuery>;
export type GetHealthConditionQueryResult = Apollo.QueryResult<GetHealthConditionQuery, GetHealthConditionQueryVariables>;