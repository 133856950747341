import { z } from "zod";
import { guidedCareTemplateGoalRecommendationInterventionActivityUpsertFormSchema } from "../TemplateGoalRecommendationInterventionActivityUpsert/GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormSchema";

export const guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema = z.object({
  interventionId: z.string().nullable().optional(),
  uniqueId: z.string().nullable().optional(),
  name: z.string(),
  nameAr: z.string().nullable().optional(),
  duration: z.number(),
  description: z.string(),
  descriptionAr: z.string().nullable().optional(),
  activities: z.array(guidedCareTemplateGoalRecommendationInterventionActivityUpsertFormSchema),
});

export type IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues = z.infer<
  typeof guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema
>;

export const guidedCareTemplateGoalRecommendationInterventionUpsertFormDefaultValues: Partial<IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues> =
  {
    interventionId: undefined,
    uniqueId: undefined,
    name: undefined,
    nameAr: undefined,
    duration: undefined,
    description: undefined,
    descriptionAr: undefined,
    activities: [],
  };
