import { healthConditionTypeOptions, healthConditionTypeOptionsMap } from "@health/enum-options";
import {
  combineErrors,
  getMaxLengthValidation,
  getRequiredValidation,
  patternArabicTextWithSpecialCharAndNumbers,
  required,
  validateValueIsNotEmpty,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Box, Divider, Grid, TextField, ToggleButtonController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IForm, IFormData } from "../types";

export const GeneralInformation: FC<IForm> = ({ healthCondition, errors, isClone }) => {
  const { t } = useTranslation("gcadmin");
  const {
    register,
    formState: { errors: reactFormErrors },
    control,
  } = useFormContext<IFormData>();
  const formErrors = combineErrors(reactFormErrors, errors);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("risk-display")}
          error={Boolean(formErrors.display?.message)}
          helperText={t(formErrors.display?.message)}
          defaultValue={healthCondition?.display}
          {...register("display", {
            required: required(t),
            maxLength: getMaxLengthValidation(t, 255),
            validate: value => validateValueIsNotEmpty(value, t),
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("risk-arabic-display")}
          error={Boolean(formErrors.arabicDisplay?.message)}
          helperText={t(formErrors.arabicDisplay?.message)}
          defaultValue={healthCondition?.arabicDisplay}
          {...register("arabicDisplay", {
            required: required(t),
            maxLength: getMaxLengthValidation(t, 255),
            pattern: patternArabicTextWithSpecialCharAndNumbers,
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("Code")}
          disabled={Boolean(healthCondition) && !isClone}
          error={Boolean(formErrors.code?.message)}
          helperText={t(formErrors.code?.message)}
          defaultValue={!isClone ? healthCondition?.code : undefined}
          {...register("code", {
            required: getRequiredValidation(t, !healthCondition?.code),
            maxLength: getMaxLengthValidation(t, 255),
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("UMLS code")}
          error={Boolean(formErrors.umlsCode?.message)}
          helperText={t(formErrors.umlsCode?.message)}
          defaultValue={healthCondition?.umlsCode}
          {...register("umlsCode", {
            required: getRequiredValidation(t, !healthCondition?.umlsCode),
            maxLength: getMaxLengthValidation(t, 255),
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("Disease")}
          error={Boolean(formErrors.disease?.message)}
          helperText={t(formErrors.disease?.message)}
          defaultValue={healthCondition?.disease}
          {...register("disease", {
            required: getRequiredValidation(t, !healthCondition?.disease),
            maxLength: getMaxLengthValidation(t, 255),
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AutocompleteController
          ControllerProps={{
            name: "type",
            control: control,
            defaultValue: healthConditionTypeOptionsMap[healthCondition?.type || ""] || undefined,
          }}
          TextFieldProps={{
            placeholder: t("Type"),
            error: Boolean(formErrors?.type?.message),
            helperText: t(formErrors?.type?.message),
            label: t("Type"),
          }}
          getOptionLabel={option => t(option?.label)}
          options={healthConditionTypeOptions}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Box display='flex' alignItems='center'>
          <ToggleButtonController
            {...register("isActive")}
            control={control}
            defaultValue={healthCondition?.isActive || false}
            label={t("Is Active")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
