import { replacePathParams } from "utils";

export const labTemplatesRoute = `/lab-templates`;
const labTemplatesNewRoute = "new";
const labTemplatesEditRoute = "edit/:id";
const labTemplatesCloneRoute = "clone/:id";
const labTemplatesFieldRoute = "field/:id";

export const labTemplatesPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${labTemplatesRoute}/${this.route}`;
    },
  },
  newPath: {
    route: labTemplatesNewRoute,
    get fullPath() {
      return `${labTemplatesRoute}/${this.route}`;
    },
  },
  editPath: {
    route: labTemplatesEditRoute,
    get fullPath() {
      return `${labTemplatesRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  clonePath: {
    route: labTemplatesCloneRoute,
    get fullPath() {
      return `${labTemplatesRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  fieldPath: {
    route: labTemplatesFieldRoute,
    get fullPath() {
      return `${labTemplatesRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
