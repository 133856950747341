import { RiskFactorTemplate } from "@health/queries/types";
import { useToggle } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormCard, Grid, MainListActions, PageWrapper, TableGrid, TextField, useBreadCrumbs, useGridContext } from "@toolkit/ui";
import { FC, useEffect } from "react";
import {
  healthConditionsFieldBreadcrumb,
  healthConditionsListBreadcrumb,
  healthConditionsParametersBreadcrumb,
  healthConditionsTitleBreadcrumb,
} from "../constants/healthConditionsBreadcrumbs";
import { AddFields } from "./AddFields";

export const RiskFactorFieldsTemplate: FC<{ template: RiskFactorTemplate | undefined }> = ({ template }) => {
  const { t } = useTranslation("gcadmin");
  const { open, handleToggle } = useToggle();

  const { setBreadCrumb } = useBreadCrumbs();

  const { customTableProps } = useGridContext();

  useEffect(() => {
    if (template?.display) {
      setBreadCrumb({
        title: healthConditionsTitleBreadcrumb(),
        values: [
          healthConditionsListBreadcrumb(),
          healthConditionsFieldBreadcrumb(template.display),
          healthConditionsParametersBreadcrumb(),
        ],
      });
    }
  }, [template?.display]);

  return (
    <>
      <PageWrapper actions={<MainListActions hasAddNew onAddNewItem={handleToggle} />}>
        <Box sx={{ marginBottom: 1 }}>
          <FormCard title={t("Condition information's")} loading={customTableProps?.isLoading} doYouHaveData={Boolean(template)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("risk-display")} defaultValue={template?.display} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("risk-arabic-display")} defaultValue={template?.arabicDisplay} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("Code")} defaultValue={template?.code} />
              </Grid>
            </Grid>
          </FormCard>
        </Box>
        <TableGrid />
      </PageWrapper>

      {open && <AddFields onToggle={handleToggle} open={open} />}
    </>
  );
};
