import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { healthConditionsPaths } from "pages/HealthConditions/constants/healthConditionsPaths";
import { useAddHealthConditionMutation } from "pages/HealthConditions/gql";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IFormData } from "../types";

export const useAddHealthCondition = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const [riskFactorTemplateCreate, { loading: isSubmitting }] = useAddHealthConditionMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.addHealthCondition?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Health Condition Created Successfully"));
        navigate(healthConditionsPaths.listPath.fullPath);
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Health Condition Create Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmit = (input: IFormData) => {
    riskFactorTemplateCreate({
      variables: {
        input: {
          ...input,
          isActive: input?.isActive || false,
          type: input?.type?.value,
        } as IFormData,
      },
    });
  };

  return {
    errors,
    handleSubmit,
    isSubmitting,
  };
};
