/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHealthParameterUnitsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetHealthParameterUnitsQuery = { __typename?: 'Query', getHealthParameterUnits?: Array<{ __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null> | null };


export const GetHealthParameterUnitsDocument = gql`
    query GetHealthParameterUnits {
  getHealthParameterUnits {
    arabicDisplay
    code
    display
    id
  }
}
    `;

/**
 * __useGetHealthParameterUnitsQuery__
 *
 * To run a query within a React component, call `useGetHealthParameterUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthParameterUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthParameterUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHealthParameterUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetHealthParameterUnitsQuery, GetHealthParameterUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthParameterUnitsQuery, GetHealthParameterUnitsQueryVariables>(GetHealthParameterUnitsDocument, options);
      }
export function useGetHealthParameterUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthParameterUnitsQuery, GetHealthParameterUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthParameterUnitsQuery, GetHealthParameterUnitsQueryVariables>(GetHealthParameterUnitsDocument, options);
        }
export type GetHealthParameterUnitsQueryHookResult = ReturnType<typeof useGetHealthParameterUnitsQuery>;
export type GetHealthParameterUnitsLazyQueryHookResult = ReturnType<typeof useGetHealthParameterUnitsLazyQuery>;
export type GetHealthParameterUnitsQueryResult = Apollo.QueryResult<GetHealthParameterUnitsQuery, GetHealthParameterUnitsQueryVariables>;