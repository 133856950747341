import { FormProvider, UseFormReturn } from "react-hook-form";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useCustomForm } from "@toolkit/core";
import { FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent } from "../../types";
import {
  guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema,
  IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues,
  guidedCareTemplateGoalRecommendationInterventionUpsertFormDefaultValues,
} from "./GuidedCareTemplateGoalRecommendationInterventionUpsertFormSchema";
import { uniqueId } from "lodash";

type GuidedCareTemplateGoalRecommendationInterventionUpsertFormProps = {
  goalIndex: number;
  recommendationIndex: number;
  interventionIndex?: number;
  intervention?: IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues;
  onChange: (event: IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent) => void;
};

export type GuidedCareTemplateGoalRecommendationInterventionUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues>;
  submit: () => void;
};

const GuidedCareTemplateGoalRecommendationInterventionUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateGoalRecommendationInterventionUpsertFormRef,
  GuidedCareTemplateGoalRecommendationInterventionUpsertFormProps
> = (props, ref) => {
  const { intervention, goalIndex, recommendationIndex, interventionIndex, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues>({
    defaultValues: guidedCareTemplateGoalRecommendationInterventionUpsertFormDefaultValues,
    schema: guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues) => {
    if (intervention) {
      onChange({
        type: "UPDATE",
        payload: {
          values: {
            ...values,
            activities: intervention?.activities,
          },
          goalIndex,
          recommendationIndex,
          interventionIndex: interventionIndex!,
        },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: {
          values: {
            ...values,
            uniqueId: uniqueId("intervention_"),
          },
          goalIndex,
          recommendationIndex,
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (intervention) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { activities, ...rest } = intervention;
      setValues(rest);
    }
  }, [intervention, setValues]);

  return (
    <FormProvider {...form}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
        </Grid>

        <Grid item xs={6}>
          <FormTextField name={"nameAr"} label={t("Name (Arabic)")} placeholder={t("Name (Arabic)")} />
        </Grid>

        <Grid item xs={12}>
          <FormNumberField name={"duration"} label={t("Duration")} placeholder={t("Duration")} />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormTextField name={"description"} label={t("Description")} placeholder={t("Description")} multiline rows={3} />
            </Grid>

            <Grid item xs={6}>
              <FormTextField
                name={"descriptionAr"}
                label={t("Description (Arabic)")}
                placeholder={t("Description (Arabic)")}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const GuidedCareTemplateGoalRecommendationInterventionUpsertForm = forwardRef(
  GuidedCareTemplateGoalRecommendationInterventionUpsertFormForwardRef
);
