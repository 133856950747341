import { useBreadCrumbs } from "@toolkit/ui";
import {
  labTemplateFieldBreadcrumb,
  labTemplateListBreadcrumb,
  labTemplatesTitleBreadcrumb,
} from "pages/HealthTemplate/LabParameters/constants/labTemplatesBreadcrumbs";
import React, { FC, useEffect } from "react";
import { LabTemplateForm } from "../LabTemplateForm";
import { useLabTemplateEdit } from "./useLabTemplateEdit";

export const LabTemplateEditPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, loading, labTemplate, handleSubmit, isSubmitting } = useLabTemplateEdit();

  useEffect(() => {
    if (labTemplate?.display) {
      setBreadCrumb({
        title: labTemplatesTitleBreadcrumb(),
        values: [labTemplateListBreadcrumb(), labTemplateFieldBreadcrumb(labTemplate.display)],
      });
    }
  }, [labTemplate?.display]);

  return (
    <LabTemplateForm isSubmitting={isSubmitting} loading={loading} defaultValue={labTemplate!} errors={errors} onSubmit={handleSubmit} />
  );
};
