/* eslint-disable max-lines */
import { Button, Grid, PlusIcon, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useGuidedCareTemplateGoalsFormStyle } from "./useGuidedCareTemplateGoalsFormStyle";
import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useFieldArray } from "react-hook-form";
import {
  GuidedCareTemplateCustomAccordion,
  IGuidedCareTemplateCustomAccordionEvent,
} from "../../components/TemplateCustomAccordion/GuidedCareTemplateCustomAccordion";
import {
  GuidedCareTemplateGoalRecommendationsForm,
  GuidedCareTemplateGoalRecommendationsFormRef,
} from "../TemplateGoalRecommendations/GuidedCareTemplateGoalRecommendationsForm";
import {
  GuidedCareTemplateGoalUpsertModal,
  GuidedCareTemplateGoalUpsertModalApi,
} from "../../modals/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertModal";
import {
  IGuidedCareTemplateGoalFormUpsertFormEvent,
  IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent,
  IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormEvent,
  IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent,
} from "../../types";
import { findIndex } from "lodash";
import React, { createRef, useEffect, useRef } from "react";
import {
  GuidedCareTemplateGoalRecommendationUpsertModal,
  GuidedCareTemplateGoalRecommendationUpsertModalApi,
} from "../../modals/TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertModal";
import {
  GuidedCareTemplateGoalRecommendationInterventionUpsertModal,
  GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi,
} from "../../modals/TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertModal";
import {
  GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModal,
  GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalApi,
} from "../../modals/TemplateGoalRecommendationInterventionActivityUpsert/GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModal";
import {
  GuidedCareTemplateGoalDeleteConfirmationModal,
  GuidedCareTemplateGoalDeleteConfirmationModalApi,
  IGuidedCareTemplateGoalDeleteConfirmationModalEvent,
} from "../../modals/TemplateGoalDeleteConfirmation/GuidedCareTemplateGoalDeleteConfirmationModal";
import { GuidedCareTemplateGoalRecommendationInterventionActivityServicesModal } from "../../modals/TemplateGoalRecommendationInterventionActivityServices/GuidedCareTemplateGoalRecommendationInterventionActivityServicesModal";

export const GuidedCareTemplateGoalsForm = () => {
  const { t } = useTranslation("gcadmin");

  const { classes } = useGuidedCareTemplateGoalsFormStyle();

  const refs = useRef<GuidedCareTemplateGoalRecommendationsFormRef[]>([]);

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const { fields, append, update, remove } = useFieldArray<IGuidedCareTemplateUpsertFormValues, "goals">({
    control: form.control,
    name: "goals",
  });

  const onAddGoalClick = () => {
    GuidedCareTemplateGoalUpsertModalApi.show(true);
  };

  const onGuidedCareTemplateCustomAccordionChange = (event: IGuidedCareTemplateCustomAccordionEvent, goalIndex: number) => {
    if (event.type === "ADD_CLICK") {
      GuidedCareTemplateGoalRecommendationUpsertModalApi.show(true, { goalIndex });
    } else if (event.type === "UPDATE_CLICK") {
      GuidedCareTemplateGoalUpsertModalApi.show(true, { goal: fields[goalIndex] });
    } else if (event.type === "DELETE_CLICK") {
      GuidedCareTemplateGoalDeleteConfirmationModalApi.show(true, { type: "GOAL", goalIndex });
    }
  };

  const onGuidedCareTemplateGoalUpsertModalChange = (event: IGuidedCareTemplateGoalFormUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
    } else if (event.type === "UPDATE") {
      const index = findIndex(fields, { uniqueId: event.payload.values.uniqueId });
      update(index, event.payload.values);
    }

    GuidedCareTemplateGoalUpsertModalApi.show(false);
  };

  const onGuidedCareTemplateGoalRecommendationUpsertModalChange = (event: IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent) => {
    const currentRef = refs.current?.[event.payload.goalIndex!]?.fieldArray();

    if (!currentRef) return;

    if (event.type === "CREATE") {
      currentRef.append(event.payload.values);
    } else if (event.type === "UPDATE") {
      currentRef.update(event.payload.recommendationIndex, event.payload.values);
    }

    GuidedCareTemplateGoalRecommendationUpsertModalApi.show(false);
  };

  const onGuidedCareTemplateGoalRecommendationInterventionUpsertModalChange = (
    event: IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent
  ) => {
    const { goalIndex, recommendationIndex } = event.payload;

    const currentRef = refs.current?.[goalIndex!].recommendationsRefs.current?.[recommendationIndex]?.fieldArray();

    if (!currentRef) return;

    if (event.type === "CREATE") {
      currentRef?.append(event.payload.values);
    } else if (event.type === "UPDATE") {
      currentRef?.update(event.payload.interventionIndex, event.payload.values);
    }

    GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi.show(false);
  };

  const onGuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalChange = (
    event: IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormEvent
  ) => {
    const { goalIndex, recommendationIndex, interventionIndex } = event.payload;

    const currentRef =
      refs.current?.[goalIndex!].recommendationsRefs.current?.[recommendationIndex]?.interventionsRefs?.current?.[
        interventionIndex
      ]?.fieldArray();

    if (!currentRef) return;

    if (event.type === "CREATE") {
      currentRef?.prepend(event.payload.values);
    } else if (event.type === "UPDATE") {
      currentRef?.update(event.payload.activityIndex, event.payload.values);
    }

    GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalApi.show(false);
  };

  const onGuidedCareTemplateGoalDeleteConfirmationModalChange = (event: IGuidedCareTemplateGoalDeleteConfirmationModalEvent) => {
    if (event.type === "DELETE_GOAL") {
      remove(event.payload.goalIndex);
    } else if (event.type === "DELETE_RECOMMENDATION") {
      const currentRef = refs.current?.[event.payload.goalIndex!]?.fieldArray();
      currentRef?.remove(event.payload.recommendationIndex);
    } else if (event.type === "DELETE_INTERVENTION") {
      const currentRef =
        refs.current?.[event.payload.goalIndex!].recommendationsRefs.current?.[event.payload.recommendationIndex]?.fieldArray();
      currentRef?.remove(event.payload.interventionIndex);
    }

    GuidedCareTemplateGoalDeleteConfirmationModalApi.show(false);
  };

  useEffect(() => {
    refs.current = fields.map((_, index) => refs.current[index] || createRef());
  }, [fields]);

  return (
    <Grid container spacing={2}>
      <GuidedCareTemplateGoalUpsertModal onChange={onGuidedCareTemplateGoalUpsertModalChange} />
      <GuidedCareTemplateGoalRecommendationUpsertModal onChange={onGuidedCareTemplateGoalRecommendationUpsertModalChange} />
      <GuidedCareTemplateGoalRecommendationInterventionUpsertModal
        onChange={onGuidedCareTemplateGoalRecommendationInterventionUpsertModalChange}
      />
      <GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModal
        onChange={onGuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalChange}
      />
      <GuidedCareTemplateGoalDeleteConfirmationModal onChange={onGuidedCareTemplateGoalDeleteConfirmationModalChange} />
      <GuidedCareTemplateGoalRecommendationInterventionActivityServicesModal />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography className={classes.title}> {t("Template Goals")} </Typography>
          </Grid>

          <Grid xs={4} lg={2}>
            <Button fullWidth onClick={onAddGoalClick} startIcon={<PlusIcon />}>
              {t("Add Goal")}
            </Button>
          </Grid>

          <Grid xs={12} className={classes.fieldsWrapper}>
            {fields?.length ? (
              fields.map((item, index) => {
                return (
                  <GuidedCareTemplateCustomAccordion
                    key={item?.id}
                    type={"GOAL"}
                    title={pickLocalizedValue(item?.name, item?.nameAr || item?.name)}
                    description={pickLocalizedValue(item?.description, item?.descriptionAr || item?.description)}
                    onChange={event => onGuidedCareTemplateCustomAccordionChange(event, index)}
                  >
                    <GuidedCareTemplateGoalRecommendationsForm
                      goalIndex={index}
                      ref={el => (refs.current[index] = el as GuidedCareTemplateGoalRecommendationsFormRef)}
                    />
                  </GuidedCareTemplateCustomAccordion>
                );
              })
            ) : (
              <Typography>{t("There are no goals yet")}.</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
