/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { RiskFactorTemplateFragmentFragmentDoc } from '../../fragments/__generated__/RiskFactorTemplateFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRiskFactorTemplateQueryVariables = Types.Exact<{
  getRiskFactorTemplateId: Types.Scalars['ID'];
}>;


export type GetRiskFactorTemplateQuery = { __typename?: 'Query', getRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', id?: string | null, isActive?: boolean | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, fields?: Array<{ __typename?: 'HealthParameter', createdDate?: string | null, allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null> | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', message?: string | null, field?: string | null, errorType?: Types.ErrorType | null, code?: Types.DecisionMakerErrorCodes | null } | null> | null } | null };


export const GetRiskFactorTemplateDocument = gql`
    query GetRiskFactorTemplate($getRiskFactorTemplateId: ID!) {
  getRiskFactorTemplate(id: $getRiskFactorTemplateId) {
    ...RiskFactorTemplateFragment
  }
}
    ${RiskFactorTemplateFragmentFragmentDoc}`;

/**
 * __useGetRiskFactorTemplateQuery__
 *
 * To run a query within a React component, call `useGetRiskFactorTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiskFactorTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiskFactorTemplateQuery({
 *   variables: {
 *      getRiskFactorTemplateId: // value for 'getRiskFactorTemplateId'
 *   },
 * });
 */
export function useGetRiskFactorTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetRiskFactorTemplateQuery, GetRiskFactorTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRiskFactorTemplateQuery, GetRiskFactorTemplateQueryVariables>(GetRiskFactorTemplateDocument, options);
      }
export function useGetRiskFactorTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRiskFactorTemplateQuery, GetRiskFactorTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRiskFactorTemplateQuery, GetRiskFactorTemplateQueryVariables>(GetRiskFactorTemplateDocument, options);
        }
export type GetRiskFactorTemplateQueryHookResult = ReturnType<typeof useGetRiskFactorTemplateQuery>;
export type GetRiskFactorTemplateLazyQueryHookResult = ReturnType<typeof useGetRiskFactorTemplateLazyQuery>;
export type GetRiskFactorTemplateQueryResult = Apollo.QueryResult<GetRiskFactorTemplateQuery, GetRiskFactorTemplateQueryVariables>;