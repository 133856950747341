/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthConditionDiagnosisFragmentFragmentDoc } from '../../fragments/__generated__/healthConditionDiagnosisFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddDiagnosisToHealthConditionMutationVariables = Types.Exact<{
  diagnosisCode?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  healthConditionId: Types.Scalars['ID'];
}>;


export type AddDiagnosisToHealthConditionMutation = { __typename?: 'Mutation', addDiagnosisToHealthCondition?: Array<{ __typename?: 'HealthConditionDiagnosis', id?: string | null, diagnosisCode?: string | null, diagnosisDisplay?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null> | null };


export const AddDiagnosisToHealthConditionDocument = gql`
    mutation AddDiagnosisToHealthCondition($diagnosisCode: [String!], $healthConditionId: ID!) {
  addDiagnosisToHealthCondition(
    diagnosisCode: $diagnosisCode
    healthConditionId: $healthConditionId
  ) {
    ...healthConditionDiagnosisFragment
    errors {
      code
      errorType
      field
      message
    }
  }
}
    ${HealthConditionDiagnosisFragmentFragmentDoc}`;
export type AddDiagnosisToHealthConditionMutationFn = Apollo.MutationFunction<AddDiagnosisToHealthConditionMutation, AddDiagnosisToHealthConditionMutationVariables>;

/**
 * __useAddDiagnosisToHealthConditionMutation__
 *
 * To run a mutation, you first call `useAddDiagnosisToHealthConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiagnosisToHealthConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiagnosisToHealthConditionMutation, { data, loading, error }] = useAddDiagnosisToHealthConditionMutation({
 *   variables: {
 *      diagnosisCode: // value for 'diagnosisCode'
 *      healthConditionId: // value for 'healthConditionId'
 *   },
 * });
 */
export function useAddDiagnosisToHealthConditionMutation(baseOptions?: Apollo.MutationHookOptions<AddDiagnosisToHealthConditionMutation, AddDiagnosisToHealthConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiagnosisToHealthConditionMutation, AddDiagnosisToHealthConditionMutationVariables>(AddDiagnosisToHealthConditionDocument, options);
      }
export type AddDiagnosisToHealthConditionMutationHookResult = ReturnType<typeof useAddDiagnosisToHealthConditionMutation>;
export type AddDiagnosisToHealthConditionMutationResult = Apollo.MutationResult<AddDiagnosisToHealthConditionMutation>;
export type AddDiagnosisToHealthConditionMutationOptions = Apollo.BaseMutationOptions<AddDiagnosisToHealthConditionMutation, AddDiagnosisToHealthConditionMutationVariables>;