import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useFieldArray, UseFieldArrayReturn } from "react-hook-form";
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { CustomTable } from "@toolkit/ui";
import { IGuidedCareTemplateActivityItem, IGuidedCareTemplateGoalRecommendationInterventionActivitiesFields } from "../../types";
import { GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalApi } from "../../modals/TemplateGoalRecommendationInterventionActivityUpsert/GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModal";
import { findIndex } from "lodash";
import { useGuidedCareTemplateGoalRecommendationInterventionActivitiesColumns } from "./useGuidedCareTemplateGoalRecommendationInterventionActivitiesColumns";

type GuidedCareTemplateGoalRecommendationInterventionActivitiesFormProps = {
  goalIndex: number;
  recommendationIndex: number;
  interventionIndex: number;
};

export type GuidedCareTemplateGoalRecommendationInterventionActivitiesFormRef = {
  fieldArray: () => UseFieldArrayReturn<
    IGuidedCareTemplateUpsertFormValues,
    IGuidedCareTemplateGoalRecommendationInterventionActivitiesFields
  >;
};

const GuidedCareTemplateGoalRecommendationInterventionActivitiesFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateGoalRecommendationInterventionActivitiesFormRef,
  GuidedCareTemplateGoalRecommendationInterventionActivitiesFormProps
> = (props, ref) => {
  const { goalIndex, recommendationIndex, interventionIndex } = props;

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();
  const { control, getValues } = form;

  const { teamMembers } = getValues();

  const fieldArray = useFieldArray<IGuidedCareTemplateUpsertFormValues, IGuidedCareTemplateGoalRecommendationInterventionActivitiesFields>({
    control,
    name: `goals.${goalIndex}.recommendations.${recommendationIndex}.interventions.${interventionIndex}.activities`,
  });

  const onEditRowClick = (item: IGuidedCareTemplateActivityItem) => {
    GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalApi.show(true, {
      goalIndex,
      recommendationIndex,
      interventionIndex,
      teamMembers,
      activity: item,
      activityIndex: findIndex(fieldArray.fields, { id: item?.id }),
    });
  };

  const onDeleteRowClick = (_: IGuidedCareTemplateActivityItem, index: number) => {
    fieldArray.remove(index);
  };

  useImperativeHandle(ref, () => ({
    fieldArray: () => fieldArray,
  }));

  return (
    <CustomTable
      data={fieldArray?.fields}
      columns={useGuidedCareTemplateGoalRecommendationInterventionActivitiesColumns({
        goalIndex,
        recommendationIndex,
        interventionIndex,
      })}
      isEditVisible
      isDeleteVisible
      scrollable
      hasFooter={false}
      onEditRow={onEditRowClick}
      onDeleteRow={onDeleteRowClick}
    />
  );
};

export const GuidedCareTemplateGoalRecommendationInterventionActivitiesForm = forwardRef(
  GuidedCareTemplateGoalRecommendationInterventionActivitiesFormForwardRef
);
