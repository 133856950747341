import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent } from "pages/Templates/types";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateGoalRecommendationInterventionUpsertForm,
  GuidedCareTemplateGoalRecommendationInterventionUpsertFormRef,
} from "../../forms/TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertForm";
import { IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues } from "../../forms/TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertFormSchema";

type IGuidedCareTemplateGoalRecommendationInterventionUpsertModalShowOptions = {
  goalIndex: number;
  recommendationIndex: number;
  interventionIndex?: number;
  intervention?: IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues;
};

type IShow = (show: boolean, showOptions?: IGuidedCareTemplateGoalRecommendationInterventionUpsertModalShowOptions) => void;

export const GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi: { show: IShow } = {
  show: () => {},
};

type GuidedCareTemplateGoalRecommendationInterventionUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent) => void;
};

export const GuidedCareTemplateGoalRecommendationInterventionUpsertModal: FC<
  GuidedCareTemplateGoalRecommendationInterventionUpsertModalProps
> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState<IGuidedCareTemplateGoalRecommendationInterventionUpsertModalShowOptions>();

  const { t } = useTranslation("gcadmin");

  const templateGoalRecommendationInterventionUpsertFormRef = useRef<GuidedCareTemplateGoalRecommendationInterventionUpsertFormRef>(null);

  const show: IShow = (open, _showOptions) => {
    setIsOpen(open);
    setShowOptions(_showOptions);
  };

  const onButtonClick = () => {
    templateGoalRecommendationInterventionUpsertFormRef.current?.submit();
  };

  useEffect(() => {
    GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi.show = show;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: showOptions?.intervention ? t("Update Intervention") : t("Add New Intervention"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: !!showOptions?.intervention,
        submitTitle: showOptions?.intervention ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateGoalRecommendationInterventionUpsertForm
        goalIndex={showOptions?.goalIndex!}
        recommendationIndex={showOptions?.recommendationIndex!}
        interventionIndex={showOptions?.interventionIndex}
        intervention={showOptions?.intervention}
        ref={templateGoalRecommendationInterventionUpsertFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
