/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type RiskFactorTemplateOperationsFragmentFragment = { __typename?: 'RiskFactorTemplate', id?: string | null, isActive?: boolean | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', message?: string | null, field?: string | null, errorType?: Types.ErrorType | null, code?: Types.DecisionMakerErrorCodes | null } | null> | null };

export const RiskFactorTemplateOperationsFragmentFragmentDoc = gql`
    fragment RiskFactorTemplateOperationsFragment on RiskFactorTemplate {
  id
  isActive
  code
  display
  arabicDisplay
  errors {
    message
    field
    errorType
    code
  }
}
    `;