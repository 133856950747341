/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthParameterFragmentDoc } from '../../fragments/__generated__/HealthParameter';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddHealthParameterMutationVariables = Types.Exact<{
  input: Types.HealthParameterInput;
}>;


export type AddHealthParameterMutation = { __typename?: 'Mutation', addHealthParameter?: { __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null };


export const AddHealthParameterDocument = gql`
    mutation AddHealthParameter($input: HealthParameterInput!) {
  addHealthParameter(input: $input) {
    ...HealthParameter
  }
}
    ${HealthParameterFragmentDoc}`;
export type AddHealthParameterMutationFn = Apollo.MutationFunction<AddHealthParameterMutation, AddHealthParameterMutationVariables>;

/**
 * __useAddHealthParameterMutation__
 *
 * To run a mutation, you first call `useAddHealthParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHealthParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHealthParameterMutation, { data, loading, error }] = useAddHealthParameterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHealthParameterMutation(baseOptions?: Apollo.MutationHookOptions<AddHealthParameterMutation, AddHealthParameterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHealthParameterMutation, AddHealthParameterMutationVariables>(AddHealthParameterDocument, options);
      }
export type AddHealthParameterMutationHookResult = ReturnType<typeof useAddHealthParameterMutation>;
export type AddHealthParameterMutationResult = Apollo.MutationResult<AddHealthParameterMutation>;
export type AddHealthParameterMutationOptions = Apollo.BaseMutationOptions<AddHealthParameterMutation, AddHealthParameterMutationVariables>;