import { combineErrors } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useGetHealthParameterUnitsQuery } from "../../gql";
import { IForm } from "../types";

export const Units: FC<IForm> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("gcadmin");
  const {
    control,
    formState: { errors: reactFormErrors },
  } = useFormContext();

  const { data, loading } = useGetHealthParameterUnitsQuery();

  const formErrors = combineErrors(reactFormErrors, errors);
  const getFieldUnits = data?.getHealthParameterUnits?.map(item => item);
  const unit = defaultValue?.unit;

  return (
    <AutocompleteController
      loading={loading}
      options={getFieldUnits || []}
      getOptionLabel={option => (i18n.language === "ar" ? option?.arabicDisplay : option?.display)}
      TextFieldProps={{
        label: t("Units"),
        error: Boolean(formErrors?.unit?.message),
        helperText: formErrors.unit?.message,
      }}
      ControllerProps={{
        control: control,
        name: "unit",
        defaultValue: unit || undefined,
        rules: {
          required: false,
        },
      }}
    />
  );
};
