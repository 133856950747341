import { LabTemplate, LabTemplateSortingField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomIcon, CustomTableColumnProps, ShowButton, formatDate } from "@toolkit/ui";
import { labTemplatesPaths } from "pages/HealthTemplate/LabParameters/constants/labTemplatesPaths";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export type LabTemplatesColumnTypes = CustomTableColumnProps<LabTemplate>;

export const useLabTemplatesColumns = (): LabTemplatesColumnTypes[] => {
  const { t, i18n } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const handleShowClick = id => () => {
    navigate(labTemplatesPaths.fieldPath.fullPathWithParams(id));
  };
  const handleClone = id => () => {
    navigate(labTemplatesPaths.clonePath.fullPathWithParams(id));
  };

  return useMemo(() => {
    return [
      {
        key: "display",
        header: t("lab-display"),
        accessor: ({ display, arabicDisplay }) => (i18n.language === "ar" ? arabicDisplay || display : display),
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: i18n.language === "ar" ? LabTemplateSortingField.ArabicDisplay : LabTemplateSortingField.Display,
        filter: {
          type: "string",
          name: i18n.language === "ar" ? "arabicDisplay" : "display",
        },
      },
      {
        key: "code",
        header: t("lab-ar-display"),
        accessor: "code",
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: LabTemplateSortingField.Code,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: LabTemplateSortingField.CreatedDate,
      },
      {
        key: "fields",
        header: t("Parameters"),
        accessor: ({ id }) => <ShowButton onClick={handleShowClick(id)} variant='text' />,
        type: "string",
      },
      {
        key: "Clone",
        header: t("Clone"),
        accessor: ({ id }) => (
          <Button startIcon={<CustomIcon icon='clone' />} variant='text' type='submit' onClick={handleClone(id)}>
            {t("Clone")}
          </Button>
        ),
        type: "string",
      },
    ];
  }, []);
};
