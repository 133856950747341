import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { healthParametersPaths } from "pages/HealthParameters/constants/healthParametersPaths";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddHealthParameterMutation } from "../../gql";

export const useHealthParameterAdd = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const [healthParametersCreate, { loading: isSubmitting }] = useAddHealthParameterMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.addHealthParameter?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Health Parameter Created Successfully"));
        navigate(healthParametersPaths.listPath.fullPath);
      } else {
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        formatMessageErrors(mutationErrors);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmit = input => {
    healthParametersCreate({
      variables: {
        input: {
          ...input,
          type: input?.type?.value,
          standard: input?.standard?.value,
          unit: input?.unit?.id && { id: input?.unit?.id },
          code: input?.code,
          category: { id: input?.category.value },
          standardCode: input?.standardCode?.value?.code,
          chartType: input?.chartType?.value,
          maxNormalRangeValue: input?.maxNormalRangeValue && parseFloat(input?.maxNormalRangeValue),
          minNormalRangeValue: input?.minNormalRangeValue && parseFloat(input?.minNormalRangeValue),
        },
      },
    });
  };

  return {
    errors,
    handleSubmit,
    isSubmitting,
  };
};
