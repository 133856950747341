import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useCustomFormContext } from "@toolkit/core";
import { Box, Typography } from "@toolkit/ui";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useFieldArray, UseFieldArrayReturn } from "react-hook-form";
import {
  GuidedCareTemplateCustomAccordion,
  IGuidedCareTemplateCustomAccordionEvent,
} from "../../components/TemplateCustomAccordion/GuidedCareTemplateCustomAccordion";
import React, { createRef, forwardRef, ForwardRefRenderFunction, RefObject, useEffect, useImperativeHandle, useRef } from "react";
import {
  GuidedCareTemplateGoalRecommendationInterventionsForm,
  GuidedCareTemplateGoalRecommendationInterventionsFormRef,
} from "../TemplateGoalRecommendationInterventions/GuidedCareTemplateGoalRecommendationInterventionsForm";
import { GuidedCareTemplateGoalRecommendationUpsertModalApi } from "../../modals/TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertModal";
import { GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi } from "../../modals/TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertModal";
import { GuidedCareTemplateGoalDeleteConfirmationModalApi } from "../../modals/TemplateGoalDeleteConfirmation/GuidedCareTemplateGoalDeleteConfirmationModal";
import { IGuidedCareTemplateGoalRecommendationsFields } from "../../types";

type GuidedCareTemplateGoalRecommendationsFormProps = {
  goalIndex: number;
};

export type GuidedCareTemplateGoalRecommendationsFormRef = {
  fieldArray: () => UseFieldArrayReturn<IGuidedCareTemplateUpsertFormValues, IGuidedCareTemplateGoalRecommendationsFields>;
  recommendationsRefs: RefObject<GuidedCareTemplateGoalRecommendationInterventionsFormRef[]>;
};

const GuidedCareTemplateGoalRecommendationsFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateGoalRecommendationsFormRef,
  GuidedCareTemplateGoalRecommendationsFormProps
> = (props, ref) => {
  const { goalIndex } = props;

  const { t } = useTranslation("gcadmin");

  const refs = useRef<GuidedCareTemplateGoalRecommendationInterventionsFormRef[]>([]);

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const fieldArray = useFieldArray<IGuidedCareTemplateUpsertFormValues, IGuidedCareTemplateGoalRecommendationsFields>({
    control: form.control,
    name: `goals.${goalIndex}.recommendations`,
  });

  const onGuidedCareTemplateCustomAccordionChange = (event: IGuidedCareTemplateCustomAccordionEvent, recommendationIndex: number) => {
    if (event.type === "ADD_CLICK") {
      GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi.show(true, { goalIndex, recommendationIndex });
    } else if (event.type === "UPDATE_CLICK") {
      GuidedCareTemplateGoalRecommendationUpsertModalApi.show(true, {
        goalIndex,
        recommendationIndex,
        recommendation: fieldArray.fields[recommendationIndex],
      });
    } else if (event.type === "DELETE_CLICK") {
      GuidedCareTemplateGoalDeleteConfirmationModalApi.show(true, { type: "RECOMMENDATION", goalIndex, recommendationIndex });
    }
  };

  useImperativeHandle(ref, () => ({
    fieldArray: () => fieldArray,
    recommendationsRefs: refs,
  }));

  useEffect(() => {
    refs.current = fieldArray.fields.map((_, index) => refs.current[index] || createRef());
  }, [fieldArray.fields]);

  return (
    <Box>
      {fieldArray?.fields?.length ? (
        fieldArray?.fields.map((item, index) => {
          return (
            <GuidedCareTemplateCustomAccordion
              key={item?.id}
              type={"RECOMMENDATION"}
              title={pickLocalizedValue(item?.name, item?.nameAr || item?.name)}
              description={pickLocalizedValue(item?.description, item?.descriptionAr || item?.description)}
              onChange={event => onGuidedCareTemplateCustomAccordionChange(event, index)}
            >
              <GuidedCareTemplateGoalRecommendationInterventionsForm
                goalIndex={goalIndex}
                recommendationIndex={index}
                ref={el => (refs.current[index] = el as GuidedCareTemplateGoalRecommendationInterventionsFormRef)}
              />
            </GuidedCareTemplateCustomAccordion>
          );
        })
      ) : (
        <Typography>{t("There are no recommendations linked to this goal")}.</Typography>
      )}
    </Box>
  );
};

export const GuidedCareTemplateGoalRecommendationsForm = forwardRef(GuidedCareTemplateGoalRecommendationsFormForwardRef);
