import { useBreadCrumbs } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import {
  healthConditionsListBreadcrumb,
  healthConditionsNewBreadcrumb,
  healthConditionsTitleBreadcrumb,
} from "pages/HealthConditions/constants/healthConditionsBreadcrumbs";
import { HealthConditionForm } from "../HealthConditionForm";
import { useAddHealthCondition } from "./useAddHealthCondition";

export const HealthConditionNewPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, handleSubmit, isSubmitting } = useAddHealthCondition();

  useEffect(() => {
    setBreadCrumb({
      title: healthConditionsTitleBreadcrumb(),
      values: [healthConditionsListBreadcrumb(), healthConditionsNewBreadcrumb()],
    });
  }, []);

  return <HealthConditionForm errors={errors} onSubmit={handleSubmit} isSubmitting={isSubmitting} />;
};
