import { DecisionCreateContainer } from "@health/domains";
import { DecisionPlanCategory, HealthParameter } from "@health/queries/types";
import { CircularProgress } from "@toolkit/ui";
import { useDecisionPlanListQuery } from "pages/Decisions/gql/queries";
import { useGetHealthParameterQuery } from "pages/HealthParameters/Parameters/gql";
import { DecisionPlanCode, IHealthParameterDecisionCreateContainerParams } from "pages/HealthParameters/types/types";
import React, { FC } from "react";
import { useParams } from "react-router-dom";

export const HealthParameterDecisionCreateContainer: FC = () => {
  const { healthParameterId } = useParams<IHealthParameterDecisionCreateContainerParams>();

  const { data, loading } = useGetHealthParameterQuery({
    variables: {
      id: healthParameterId!,
    },
    skip: !healthParameterId,
  });

  const { data: healthParameterDecisionPlanData, loading: isDecisionPlanLoading } = useDecisionPlanListQuery({
    variables: {
      first: 10,
      filter: {
        planCode: DecisionPlanCode.HealthParameterReference,
      },
    },
  });

  const healthParameterDecisionPlan = healthParameterDecisionPlanData?.getPlans?.edges?.[0]?.node!;

  if (loading || isDecisionPlanLoading) {
    return <CircularProgress color='inherit' size={20} />;
  }

  return (
    <DecisionCreateContainer
      category={DecisionPlanCategory.HealthCondition}
      healthParameter={data?.getHealthParameter as HealthParameter}
      healthParameterDecisionPlan={healthParameterDecisionPlan}
    />
  );
};
