import { useBreadCrumbs } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { HealthProviderForm } from "../HealthProviderForm";
import { useHealthParameterEdit } from "./useHealthParameterEdit";
import {
  healthParametersListBreadcrumb,
  healthParametersTitleBreadcrumb,
  healthParametersFieldBreadcrumb,
} from "pages/HealthParameters/constants/healthParametersBreadcrumbs";

export const HealthParameterEditPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, loading, healthParameter, handleSubmit, isSubmitting } = useHealthParameterEdit();

  useEffect(() => {
    if (healthParameter?.display) {
      setBreadCrumb({
        title: healthParametersTitleBreadcrumb(),
        values: [healthParametersListBreadcrumb(), healthParametersFieldBreadcrumb(healthParameter.display)],
      });
    }
  }, [healthParameter?.display, setBreadCrumb]);

  return (
    <HealthProviderForm
      loading={loading}
      defaultValue={healthParameter!}
      errors={errors}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};
