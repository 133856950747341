import { MedicalFormActivation } from "@health/domains";
import {
  medicalFormCategoryOptions,
  medicalFormCategoryOptionsMap,
  medicalFormTypeOptions,
  medicalFormTypeOptionsMap,
} from "@health/enum-options";
import { MedicalForm, MedicalFormSortingField } from "@health/queries/types";
import { TFunction, i18n } from "@toolkit/i18n";
import { CustomTableColumnProps, OrderDirection, TruncateTypography } from "@toolkit/ui";
import { getActiveInActiveFilter } from "shared/components";

export const useAdminMedicalFormsTableColumns = (t: TFunction): CustomTableColumnProps<MedicalForm>[] => {
  return [
    {
      key: "name",
      header: t("Name", { ns: "gcadmin" }),
      accessor: ({ name, nameAr }) => <TruncateTypography text={i18n.language === "en" ? name! : nameAr! || name!} />,
      type: "string",
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MedicalFormSortingField.Name,
      filter: {
        type: "string",
        name: "nameContains",
      },
    },
    {
      key: "code",
      header: t("Code", { ns: "gcadmin" }),
      accessor: "code",
      type: "string",
      filter: {
        type: "string",
        name: "codes",
        getValueForBackend: name => (name ? [name] : undefined),
      },
    },
    {
      key: "type",
      header: t("Type", { ns: "gcadmin" }),
      accessor: ({ type }) => medicalFormTypeOptionsMap[type!]?.label,
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MedicalFormSortingField.Type,
      filter: {
        type: "autocomplete",
        name: "type",
        options: medicalFormTypeOptions,
        getOptionLabel: option => option?.label,
        getValueForBackend: option => option?.value,
      },
    },
    {
      key: "category",
      header: t("Category", { ns: "gcadmin" }),
      accessor: ({ category }) => medicalFormCategoryOptionsMap[category!]?.label,
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MedicalFormSortingField.Category,
      filter: {
        type: "autocomplete",
        name: "category",
        options: medicalFormCategoryOptions,
        getOptionLabel: option => option?.label,
        getValueForBackend: option => option?.value,
      },
    },
    {
      key: "active",
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MedicalFormSortingField.Active,
      header: t("Active", { ns: "gcadmin" }),
      accessor: ({ id, active }) => <MedicalFormActivation id={id!} isActive={!!active} />,
      filter: {
        ...getActiveInActiveFilter(t),
      },
    },
  ];
};
