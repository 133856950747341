/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { LabTemplateFragmentDoc } from '../../fragments/__generated__/LabTemplate';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLabTemplatesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.LabTemplateFilterInput>;
  sortBy?: Types.InputMaybe<Types.LabTemplateSortingInput>;
}>;


export type GetLabTemplatesQuery = { __typename?: 'Query', getLabTemplates?: { __typename?: 'LabTemplateConnection', edges?: Array<{ __typename?: 'LabTemplateEdge', node?: { __typename?: 'LabTemplate', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, createdDate?: string | null, isActive?: boolean | null, labCodeDisplay?: string | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, display?: string | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null> | null } | null } | null> | null } | null };


export const GetLabTemplatesDocument = gql`
    query getLabTemplates($first: Int, $last: Int, $before: String, $after: String, $filter: LabTemplateFilterInput, $sortBy: LabTemplateSortingInput) {
  getLabTemplates(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    edges {
      node {
        ...LabTemplate
      }
    }
  }
}
    ${LabTemplateFragmentDoc}`;

/**
 * __useGetLabTemplatesQuery__
 *
 * To run a query within a React component, call `useGetLabTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabTemplatesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetLabTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetLabTemplatesQuery, GetLabTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabTemplatesQuery, GetLabTemplatesQueryVariables>(GetLabTemplatesDocument, options);
      }
export function useGetLabTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabTemplatesQuery, GetLabTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabTemplatesQuery, GetLabTemplatesQueryVariables>(GetLabTemplatesDocument, options);
        }
export type GetLabTemplatesQueryHookResult = ReturnType<typeof useGetLabTemplatesQuery>;
export type GetLabTemplatesLazyQueryHookResult = ReturnType<typeof useGetLabTemplatesLazyQuery>;
export type GetLabTemplatesQueryResult = Apollo.QueryResult<GetLabTemplatesQuery, GetLabTemplatesQueryVariables>;