import { RiskFactorTemplate, RiskFactorTemplateSortingField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomIcon, CustomTableColumnProps, ShowButton, TruncateTypography, formatDate } from "@toolkit/ui";
import { riskFactorTemplatesPaths } from "pages/HealthTemplate/RiskRulesParameters/constants/riskFactorTemplatesPaths";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getActiveInActiveFilter } from "shared/components";
import { RiskFactorTemplateToggleButton, UpdateRequiredInput } from "./RiskFactorTemplateToggleButton.component";

export type RiskFactorTemplatesColumnTypes = CustomTableColumnProps<RiskFactorTemplate>;

export const useRiskFactorTemplatesColumns = (): RiskFactorTemplatesColumnTypes[] => {
  const { t, i18n } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const handleShowClick = id => () => {
    navigate(riskFactorTemplatesPaths.fieldPath.fullPathWithParams(id));
  };

  const handleClone = id => () => {
    navigate(riskFactorTemplatesPaths.clonePath.fullPathWithParams(id));
  };

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: ({ code }) => <TruncateTypography text={code || "-"} />,
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: RiskFactorTemplateSortingField.Code,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "display",
        header: t("risk-display"),
        accessor: ({ display, arabicDisplay }) => (
          <TruncateTypography text={(i18n.language === "ar" ? arabicDisplay || display : display) || "-"} />
        ),
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: i18n.language === "ar" ? RiskFactorTemplateSortingField.ArabicDisplay : RiskFactorTemplateSortingField.Display,
        filter: {
          type: "string",
          name: i18n.language === "ar" ? "arabicDisplay" : "display",
        },
      },
      {
        key: "ArabicDisplay",
        header: t("risk-arabic-display"),
        type: "string",
        filter: {
          type: "string",
          name: "arabicDisplay",
        },
        showOnlyForFilterField: true,
        hideFromSettings: true,
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: RiskFactorTemplateSortingField.CreatedDate,
      },
      {
        key: "Parameters",
        header: t("Parameters"),
        accessor: ({ id }) => <ShowButton variant='text' onClick={handleShowClick(id)} />,
      },
      {
        key: "Clone",
        header: t("Clone"),
        accessor: ({ id }) => (
          <Button startIcon={<CustomIcon icon='clone' />} variant='text' type='submit' onClick={handleClone(id)}>
            {t("Clone")}
          </Button>
        ),
        type: "string",
      },
      {
        key: "isActive",
        header: t("Active"),
        type: "string",
        accessor: ({ arabicDisplay, display, isActive, id }) => {
          return (
            <RiskFactorTemplateToggleButton
              id={id as string}
              isDisabled={false}
              updateRequiredInput={
                {
                  arabicDisplay,
                  display,
                } as UpdateRequiredInput
              }
              isActive={Boolean(isActive)}
            />
          );
        },
        filter: {
          ...getActiveInActiveFilter(t),
        },
      },
    ];
  }, []);
};
