import { combineErrors } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import { AutocompleteController, useAutocompleteCustomHook } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useGetHealthParameterCategoriesQuery } from "../../gql";
import { IForm } from "../types";

export const HealthParameterCategory: FC<IForm> = ({ errors, defaultValue }) => {
  const { t } = useTranslation("gcadmin");
  const { formState, control } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);

  const {
    data: categoriesData,
    isLoading: loadingSpecialization,
    onFetchMoreData,
    refetch,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useGetHealthParameterCategoriesQuery,
    input: {
      first: 10,
      after: null,
      before: null,
      last: null,
    },
    dataAccessor: "getHealthParameterCategories",
  });

  const categoriesPageInfo = categoriesData?.getHealthParameterCategories?.pageInfo;
  const hasNextPage = categoriesPageInfo?.hasNextPage;
  const categories = categoriesData?.getHealthParameterCategories?.edges?.map(item => item?.node)!;
  const categoriesOptions = categories?.map(item => {
    return { name: i18n.language.toLowerCase() === "ar" ? item?.arabicDisplay : item?.display, value: item?.id };
  });
  const defaultCategory = defaultValue?.category;
  const categoriesDefaultValues = {
    name: i18n.language.toLowerCase() === "ar" ? defaultCategory?.arabicDisplay : defaultCategory?.display,
    value: defaultCategory?.id,
  };
  const handleSearch = (filter: string) => {
    refetch({
      keyword: filter,
    });
  };
  return (
    <AutocompleteController
      ControllerProps={{
        name: "category",
        control: control,
        rules: { required: true },
        defaultValue: defaultValue?.category ? categoriesDefaultValues : [],
      }}
      loading={loadingSpecialization}
      fetchMoreData={onFetchMoreData}
      hasMore={hasNextPage}
      TextFieldProps={{
        error: Boolean(combinedErrors?.category),
        helperText: combinedErrors?.doctorInfo?.categories && t("Required"),
        label: t("Category"),
      }}
      getOptionLabel={o => o?.name || o?.code || o}
      options={categoriesOptions || []}
      isOptionEqualToValue={(option, value) => option?.value === value.value}
      onSearchInput={handleSearch}
    />
  );
};
