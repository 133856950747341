import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  healthConditionsCloneBreadcrumb,
  healthConditionsListBreadcrumb,
  healthConditionsTitleBreadcrumb,
} from "../constants/healthConditionsBreadcrumbs";
import { healthConditionsPaths } from "../constants/healthConditionsPaths";
import { useDuplicateHealthConditionMutation, useGetHealthConditionQuery } from "../gql";

export const useHealthConditionClone = () => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();

  const params = useParams() as { id: string };
  const { id } = params;

  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);
  const { data, loading } = useGetHealthConditionQuery({
    variables: {
      getHealthConditionId: id!,
    },
  });
  const [HealthConditionClone] = useDuplicateHealthConditionMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.duplicateHealthCondition?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Health Condition Cloned Successfully"));
        navigate(healthConditionsPaths.listPath.fullPath);
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Health Condition Cloned Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmit = input => {
    HealthConditionClone({
      variables: {
        duplicateHealthConditionId: id,
        input: {
          ...input,
          isActive: input?.isActive || false,
          type: input?.type?.value,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: healthConditionsTitleBreadcrumb(),
      values: [healthConditionsListBreadcrumb(), healthConditionsCloneBreadcrumb()],
    });
  }, []);

  return {
    errors,
    handleSubmit,
    isLoading: loading,
    clonedData: data?.getHealthCondition,
  };
};
