/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type HealthConditionDiagnosisFragmentFragment = { __typename?: 'HealthConditionDiagnosis', id?: string | null, diagnosisCode?: string | null, diagnosisDisplay?: string | null };

export const HealthConditionDiagnosisFragmentFragmentDoc = gql`
    fragment healthConditionDiagnosisFragment on HealthConditionDiagnosis {
  id
  diagnosisCode
  diagnosisDisplay
}
    `;