import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, FormAutocomplete, Grid, PlusIcon, useTheme } from "@toolkit/ui";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { serviceSystemCodeOptions } from "@health/enum-options";
import { IGuidedCareTemplateActivityServiceUpsertFormEvent } from "pages/Templates/types";
import {
  GuidedCareTemplateActivityServiceUpsertFormSchema,
  IGuidedCareTemplateActivityServiceUpsertFormValues,
  guidedCareTemplateActivityServiceUpsertFormDefaultValues,
} from "./GuidedCareTemplateActivityServiceUpsertFormSchema";

type GuidedCareTemplateActivityServiceUpsertFormProps = {
  onChange: (event: IGuidedCareTemplateActivityServiceUpsertFormEvent) => void;
};

export const GuidedCareTemplateActivityServiceUpsertForm: FC<GuidedCareTemplateActivityServiceUpsertFormProps> = props => {
  const { onChange } = props;

  const { t } = useTranslation("gcadmin");
  const theme = useTheme();

  const form = useCustomForm<IGuidedCareTemplateActivityServiceUpsertFormValues>({
    defaultValues: guidedCareTemplateActivityServiceUpsertFormDefaultValues,
    schema: GuidedCareTemplateActivityServiceUpsertFormSchema,
  });

  const { watch, reset, handleSubmit } = form;

  const standard = watch("standard")?.value;
  const service = watch("service")?.key;

  const onSubmit = (values: IGuidedCareTemplateActivityServiceUpsertFormValues) => {
    onChange({
      type: "CREATE",
      payload: { values },
    });

    reset();
  };

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormAutocomplete name={"standard"} label={t("Standard")} options={serviceSystemCodeOptions} />
        </Grid>

        <Grid item xs={7}>
          <SystemCodeAutocomplete name={"service"} label={t("Specialization")} disabled={!standard} filter={{ codeSystemCode: standard }} />
        </Grid>

        <Grid item xs={2}>
          <Button
            fullWidth
            disabled={!standard || !service}
            onClick={handleSubmit(onSubmit)}
            startIcon={<PlusIcon fill={theme.palette.common.white} />}
          >
            {t("Add")}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
