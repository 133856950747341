import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import {
  RiskFactorFieldsTemplateList,
  RiskFactorTemplateEditPage,
  RiskFactorTemplateNewPage,
  RiskFactorTemplatesClone,
  RiskFactorTemplatesList,
} from "../RiskRulesParameters";
import { riskFactorTemplatesPaths, riskFactorTemplatesRoute } from "./constants/riskFactorTemplatesPaths";

export const riskRulesParametersRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "risk-factor-templates-route",
    text: t("Risk Factor Templates", { ns: "gcadmin" }),
    route: riskFactorTemplatesRoute,
    subItems: [
      {
        id: "risk-factor-templates-list-route",
        text: t("Risk Factor Templates", { ns: "gcadmin" }),
        route: riskFactorTemplatesPaths.listPath.route,
        fullPath: riskFactorTemplatesPaths.listPath.fullPath,
        element: <RiskFactorTemplatesList />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "risk-factor-templates-new-route",
        text: t("Risk Factor Templates New", { ns: "gcadmin" }),
        route: riskFactorTemplatesPaths.newPath.route,
        fullPath: riskFactorTemplatesPaths.newPath.fullPath,
        element: <RiskFactorTemplateNewPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "risk-factor-templates-edit-route",
        text: t("Risk Factor Templates Edit", { ns: "gcadmin" }),
        route: riskFactorTemplatesPaths.editPath.route,
        fullPath: riskFactorTemplatesPaths.editPath.fullPath,
        element: <RiskFactorTemplateEditPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "risk-factor-templates-field-route",
        text: t("Risk Factor Template Fields", { ns: "gcadmin" }),
        route: riskFactorTemplatesPaths.fieldPath.route,
        fullPath: riskFactorTemplatesPaths.fieldPath.fullPath,
        element: <RiskFactorFieldsTemplateList />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
      {
        id: "risk-factor-templates-clone-route",
        text: t("Risk Factor Templates Clone", { ns: "gcadmin" }),
        route: riskFactorTemplatesPaths.clonePath.route,
        fullPath: riskFactorTemplatesPaths.clonePath.fullPath,
        element: <RiskFactorTemplatesClone />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
      // {
      //   id: "risk-factor-templates-new-field",
      //   text: t("Risk Factor Template FieldTemplateNewPage Add", { ns: "gcadmin" }),
      //   route: riskFactorTemplatesPaths.newFieldPath.route,
      //   fullPath: riskFactorTemplatesPaths.newFieldPath.fullPath,
      //   element: <FieldTemplateNewPage />,
      //   onClick: (route: string) => navigate(route),
      //   hidden: true,
      // },
    ],
  };
};
