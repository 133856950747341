import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useGridContext } from "@toolkit/ui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAddDiagnosisToHealthConditionByDiseaseMutation } from "../gql";

export const useAddDiagnosesToHealthConditionByDisease = (handleToggle?) => {
  const { t } = useTranslation("gcadmin");
  const params = useParams();
  const { succeeded, failed } = useAddToast();
  const [error, setErrors] = useState<string>("");
  const [diseases, setDiseases] = useState<string>("");
  const { refetch } = useGridContext();

  const [addDiagnosesToHealthCondition, { loading: isSubmitting }] = useAddDiagnosisToHealthConditionByDiseaseMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.addDiagnosisToHealthConditionByDisease?.[0]?.errors || [];
      if (mutationErrors?.length === 0) {
        refetch({
          first: 10,
          after: null,
          before: null,
          last: null,
          filter: {
            healthConditionId: params.id!,
          },
        });
        succeeded(t("Diagnoses Added Successfully"));
        handleToggle();
      } else {
        setErrors(mutationErrors[0]?.message);
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const onDiseasesValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const diseases = event.target.value;
    setDiseases(diseases);
    if (diseases.trim()) {
      error && setErrors("");
    } else if (!error) {
      setErrors(t("Required"));
    }
  };
  const handleAddDiagnosesToHealthCondition = diseases => {
    !error &&
      addDiagnosesToHealthCondition({
        variables: {
          disease: diseases,
          healthConditionId: params.id!,
        },
      });
  };

  return {
    error,
    handleAddDiagnosesToHealthCondition,
    isSubmitting,
    diseases,
    onDiseasesValueChange,
  };
};
