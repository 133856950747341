import { getMediaLink } from "@health/common";
import { medicalMessageCategoryOptionsMap, medicalMessageTypeOptionsMap } from "@health/enum-options";
import { MedicalMessageInput, MedicalMessageType, MedicalMessageVariant, MedicalMessageVariantInput } from "@health/queries/types";
import { createAutocompleteOption } from "@toolkit/ui";
import { IMedicalMessageUpsertFormValues, IMedicalMessageVariantUpsertFormValues } from "pages/MedicalMessages/forms";
import { IMedicalMessage, IMedicalMessageVariantExtraData } from "pages/MedicalMessages/types";

export const convertMedicalMessageToFormValues = (medicalMessage: IMedicalMessage): IMedicalMessageUpsertFormValues => {
  return {
    name: medicalMessage?.name!,
    code: medicalMessage?.code!,
    headerImage: getMediaLink(medicalMessage?.headerImage),
    medicalMessageType: medicalMessageTypeOptionsMap[medicalMessage?.medicalMessageType!],
    medicalMessageCategory: medicalMessageCategoryOptionsMap[medicalMessage?.medicalMessageCategory!],
    healthCondition: medicalMessage?.healthCondition
      ? createAutocompleteOption(
          {
            id: medicalMessage?.healthCondition?.id || "",
            code: medicalMessage?.healthCondition?.code || "",
            display: medicalMessage?.healthCondition?.display || "",
          },
          "code",
          "display"
        )
      : null,
  };
};

export const convertMedicalMessageFormValuesToBackEndValues = (values: IMedicalMessageUpsertFormValues): MedicalMessageInput => {
  return {
    name: values.name,
    code: values.code,
    headerImage: values.headerImage,
    medicalMessageType: values.medicalMessageType?.value,
    medicalMessageCategory: values.medicalMessageCategory?.value,
    healthConditionId: values.healthCondition?.value?.id,
  };
};

export const convertMedicalMessageVariantToFormValues = (
  medicalMessageVariant: MedicalMessageVariant,
  medicalMessageType: MedicalMessageType
): IMedicalMessageVariantUpsertFormValues => {
  return {
    presentedTitleArabic: medicalMessageVariant.presentedTitleArabic!,
    presentedTitleEnglish: medicalMessageVariant.presentedTitleEnglish!,
    bodyArabic: medicalMessageVariant.bodyArabic!,
    bodyEnglish: medicalMessageVariant.bodyEnglish!,
    buttonTextArabic: medicalMessageVariant.buttonTextArabic || undefined,
    buttonTextEnglish: medicalMessageVariant.buttonTextEnglish || undefined,
    contentURLEnglish: medicalMessageVariant?.contentURLEnglish || undefined,
    contentURLArabic: medicalMessageVariant?.contentURLArabic || undefined,
    headerImage: getMediaLink(medicalMessageVariant?.headerImage),
    ...convertMedicalMessageVariantSessionVideoToFormValues(medicalMessageVariant, medicalMessageType),
  };
};

export const convertMedicalMessageVariantSessionVideoToFormValues = (
  medicalMessageVariant: MedicalMessageVariant,
  medicalMessageType: MedicalMessageType
) => {
  const extraData: IMedicalMessageVariantExtraData = medicalMessageVariant?.extraData && JSON.parse(medicalMessageVariant?.extraData);

  let article: IMedicalMessageVariantUpsertFormValues["article"] = undefined;
  let videoArabic: IMedicalMessageVariantUpsertFormValues["videoArabic"] = undefined;
  let videoEnglish: IMedicalMessageVariantUpsertFormValues["videoEnglish"] = undefined;

  if (medicalMessageType === MedicalMessageType.Article) {
    if (extraData?.article) {
      article = createAutocompleteOption(extraData?.article, "id", "title");
    }
  } else if (medicalMessageType === MedicalMessageType.Video) {
    if (extraData?.videoArabic) {
      videoArabic = createAutocompleteOption(extraData?.videoArabic, "id", "title");
    }

    if (extraData?.videoEnglish) {
      videoEnglish = createAutocompleteOption(extraData?.videoEnglish, "id", "title");
    }
  }

  return {
    article,
    videoArabic,
    videoEnglish,
  };
};

export const convertMedicalMessageVariantFormValuesToBackEndValues = (
  values: IMedicalMessageVariantUpsertFormValues,
  medicalMessageType: MedicalMessageType
): MedicalMessageVariantInput => {
  const extraData: IMedicalMessageVariantExtraData | undefined = {};

  if (medicalMessageType === MedicalMessageType.Article) {
    if (values?.article?.value) {
      extraData.article = values?.article?.value;
    }
  } else if (medicalMessageType === MedicalMessageType.Video) {
    if (values?.videoArabic?.value) {
      extraData.videoArabic = values?.videoArabic?.value;
    }

    if (values?.videoEnglish?.value) {
      extraData.videoEnglish = values?.videoEnglish?.value;
    }
  }

  return {
    bodyArabic: values?.bodyArabic,
    bodyEnglish: values?.bodyEnglish,
    buttonTextArabic: values?.buttonTextArabic,
    buttonTextEnglish: values?.buttonTextEnglish,
    contentURLArabic: values?.contentURLArabic,
    contentURLEnglish: values?.contentURLEnglish,
    presentedTitleArabic: values?.presentedTitleArabic,
    presentedTitleEnglish: values?.presentedTitleEnglish,
    headerImage: values?.headerImage,
    extraData: Object.keys(extraData)?.length ? JSON.stringify(extraData) : undefined,
  };
};
