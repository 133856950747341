import { TemplateFieldType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { healthParametersPaths } from "pages/HealthParameters/constants/healthParametersPaths";
import { HealthParameterDecisionList } from "pages/HealthParameters/containers";
import React, { FC } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GeneralInformation } from "./components/GeneralInformation";
import { RangeValue } from "./components/RangeValue";
import { IFormData, IHealthProviderForm } from "./types";

export const HealthProviderForm: FC<IHealthProviderForm> = props => {
  const { t } = useTranslation("gcadmin");
  const { errors, defaultValue, loading, onSubmit: handleSubmitData, isSubmitting } = props;
  const navigate = useNavigate();
  const methods = useForm<IFormData>({
    mode: "onChange",
  });
  const { handleSubmit, watch } = methods;
  const isNumber = watch("type")?.value === TemplateFieldType.Number || defaultValue?.type === TemplateFieldType.Number;

  const handleCancel = () => {
    navigate(healthParametersPaths.listPath.fullPath);
  };

  const onSubmit: SubmitHandler<IFormData> = data => {
    handleSubmitData(data as IFormData);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageWrapper
            actions={
              <FormActions
                hasCancel
                hasFormButton
                formButtonTitle={defaultValue ? t("Update") : t("Create")}
                onNavigation={handleCancel}
                newButtonDisabled={isSubmitting}
              />
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormCard title={t("General Information")} loading={loading} doYouHaveData>
                  <GeneralInformation errors={errors} defaultValue={defaultValue} />
                </FormCard>
              </Grid>
              {isNumber && (
                <Grid item xs={12}>
                  <FormCard title={t("Default Range Values")} loading={loading} doYouHaveData>
                    <RangeValue errors={errors} defaultValue={defaultValue} />
                  </FormCard>
                </Grid>
              )}
            </Grid>
          </PageWrapper>
        </form>
      </FormProvider>

      {isNumber && defaultValue?.id && (
        <Grid container spacing={2} px={5} mb={3}>
          <Grid item xs={12}>
            <FormCard title={t("Default Range Rules")} loading={loading} doYouHaveData>
              <HealthParameterDecisionList healthParameterId={defaultValue?.id!} />
            </FormCard>
          </Grid>
        </Grid>
      )}
    </>
  );
};
