import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { labTemplatesPaths } from "pages/HealthTemplate/LabParameters/constants/labTemplatesPaths";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetLabTemplateQuery, useUpdateLabTemplateMutation } from "../../gql";
import { IFormData } from "../types";

export const useLabTemplateEdit = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const params = useParams() as { id: string };
  const { id } = params;

  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const { data, loading } = useGetLabTemplateQuery({
    variables: {
      id: id!,
    },
  });

  const [labTemplateUpdate, { loading: isSubmitting }] = useUpdateLabTemplateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.updateLabTemplate?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Lab Template Updated Successfully"));
        navigate(labTemplatesPaths.listPath.fullPath);
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Lab Template Update Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handleSubmit = (input: Partial<IFormData>) => {
    delete input?.code;
    labTemplateUpdate({
      variables: {
        id: id,
        input: {
          ...input,
          isActive: input?.isActive || false,
        } as IFormData,
      },
    });
  };

  const labTemplate = data?.getLabTemplate;

  return {
    errors,
    loading,
    labTemplate,
    handleSubmit,
    isSubmitting,
  };
};
