import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { labTemplatesPaths } from "pages/HealthTemplate/LabParameters/constants/labTemplatesPaths";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddLabTemplateMutation } from "../../gql";
import { IFormData } from "../types";

export const useLabTemplateAdd = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const [labTemplateCreate, { loading: isSubmitting }] = useAddLabTemplateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.addLabTemplate?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Lab Template Created Successfully"));
        navigate(labTemplatesPaths.listPath.fullPath);
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Lab Template Create Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmit = (input: IFormData) => {
    labTemplateCreate({
      variables: {
        input: {
          ...input,
          isActive: input?.isActive || false,
          code: input?.code?.value?.code ?? "",
        },
      },
    });
  };

  return {
    errors,
    handleSubmit,
    isSubmitting,
  };
};
