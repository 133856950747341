import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { healthParametersPaths } from "pages/HealthParameters/constants/healthParametersPaths";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetHealthParameterQuery, useUpdateHealthParameterMutation } from "../../gql";

export const useHealthParameterEdit = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const params = useParams() as { id: string };
  const { id } = params;

  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const { data, loading } = useGetHealthParameterQuery({
    variables: {
      id: id!,
    },
  });
  const [healthParametersUpdate, { loading: isSubmitting }] = useUpdateHealthParameterMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.updateHealthParameter?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Health Parameter Updated Successfully"));
        navigate(healthParametersPaths.listPath.fullPath);
      } else {
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        formatMessageErrors(mutationErrors);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmit = input => {
    delete input?.code;
    delete input?.type;
    delete input?.allowedValues;
    healthParametersUpdate({
      variables: {
        id: id,
        input: {
          ...input,
          standardCode: input?.standardCode?.value?.code,
          standard: input?.standard?.value,
          unit: input?.unit?.id && { id: input?.unit?.id },
          category: { id: input?.category.value },
          chartType: input?.chartType?.value,
          maxNormalRangeValue: input?.maxNormalRangeValue && parseFloat(input?.maxNormalRangeValue),
          minNormalRangeValue: input?.minNormalRangeValue && parseFloat(input?.minNormalRangeValue),
        },
      },
    });
  };

  const healthParameter = data?.getHealthParameter;

  return {
    errors,
    loading,
    healthParameter,
    handleSubmit,
    isSubmitting,
  };
};
