import { RiskFactorTemplateUpdateInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { UpdateRiskFactorTemplateMutation, useUpdateRiskFactorTemplateMutation } from "../../gql";
export type UpdateRequiredInput = Omit<RiskFactorTemplateUpdateInput, "isActive">;
type ActiveToggleProps = {
  id: string;
  isActive: boolean;
  isDisabled?: boolean;
  updateRequiredInput: UpdateRequiredInput;
};

export const RiskFactorTemplateToggleButton: FC<ActiveToggleProps> = ({ id, isActive, isDisabled = false, updateRequiredInput }) => {
  const { t } = useTranslation("gcadmin");
  const [checked, setChecked] = useState(isActive);
  const { succeeded, failed } = useAddToast();

  const [fetchRiskFactorTemplateUpdate, { loading }] = useUpdateRiskFactorTemplateMutation({
    onCompleted: (data: UpdateRiskFactorTemplateMutation) => {
      const errors = data?.updateRiskFactorTemplate?.errors;
      if (errors && errors.length > 0) {
        const error = formatMessageErrors(errors);
        failed(t(error));
      } else {
        setChecked(!!data?.updateRiskFactorTemplate?.isActive);
        succeeded(t("Risk Factor Template Updated Successfully"));
      }
    },
    onError: () => {
      failed(t("Risk Factor Template Update Failed"));
    },
  });

  const handleChangeActivity = event => {
    const isChecked: boolean = event.target.checked;
    fetchRiskFactorTemplateUpdate({
      variables: {
        updateRiskFactorTemplateId: id,
        input: {
          isActive: isChecked,
          ...updateRequiredInput,
        },
      },
    });
  };
  return <CustomToggleButton disabled={isDisabled} checked={checked} onChange={handleChangeActivity} isLoading={loading} />;
};
