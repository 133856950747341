import { GridProvider } from "@health/domains";
import { RiskFactorTemplate } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  FormCard,
  Grid,
  MainListActions,
  PageWrapper,
  TableGrid,
  TextField,
  extractCountableConnectionData,
  useBreadCrumbs,
  useGridContext,
} from "@toolkit/ui";
import { useHealthParametersColumns } from "pages/HealthParameters";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToggle } from "shared/hooks";
import {
  riskFactorTemplateFieldBreadcrumb,
  riskFactorTemplateListBreadcrumb,
  riskFactorTemplateParametersBreadcrumb,
  riskFactorTemplatesTitleBreadcrumb,
} from "../../constants/riskFactorTemplatesBreadcrumbs";
import { useDeleteFieldFromRiskFactorTemplateMutation, useGetRiskFactorTemplateQuery } from "../gql";
import { AddFields } from "./AddFields";

export const RiskFactorFieldsTemplateList: FC = () => {
  const params = useParams() as { id: string };
  const { id } = params;
  const [template, setTemplate] = useState<RiskFactorTemplate | undefined>(undefined);

  const handleChangeTemplate = template => {
    setTemplate(template);
  };

  return (
    <GridProvider
      gridName='getRiskFactorTemplate'
      columns={useHealthParametersColumns()}
      extractCountableConnectionData={({ responseData }) => {
        handleChangeTemplate(responseData?.getRiskFactorTemplate);
        return extractCountableConnectionData({ responseData: responseData?.getRiskFactorTemplate!, entityName: "fields" });
      }}
      variables={{
        getRiskFactorTemplateId: id!,
      }}
      query={useGetRiskFactorTemplateQuery}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: true,
      }}
      deleteItemProps={{
        useDeleteMutation: useDeleteFieldFromRiskFactorTemplateMutation,
        idPropName: "healthParameterId",
        name: "Risk Template Parameter",
        entityTypeName: "RiskFactorTemplate",
        extraVariables: { riskFactorTemplateId: id },
      }}
    >
      <RiskFactorFieldsTemplate template={template} />
    </GridProvider>
  );
};

const RiskFactorFieldsTemplate: FC<{ template: RiskFactorTemplate | undefined }> = ({ template }) => {
  const { t } = useTranslation("gcadmin");
  const { open, handleToggle } = useToggle();

  const { setBreadCrumb } = useBreadCrumbs();

  const { customTableProps } = useGridContext();

  useEffect(() => {
    if (template?.display) {
      setBreadCrumb({
        title: riskFactorTemplatesTitleBreadcrumb(),
        values: [
          riskFactorTemplateListBreadcrumb(),
          riskFactorTemplateFieldBreadcrumb(template.display),
          riskFactorTemplateParametersBreadcrumb(),
        ],
      });
    }
  }, [template?.display]);

  return (
    <>
      <PageWrapper actions={<MainListActions hasAddNew onAddNewItem={handleToggle} />}>
        <Box sx={{ marginBottom: 1 }}>
          <FormCard title={t("Template")} loading={customTableProps?.isLoading} doYouHaveData={Boolean(template)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("risk-display")} defaultValue={template?.display} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("risk-arabic-display")} defaultValue={template?.arabicDisplay} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("Code")} defaultValue={template?.code} />
              </Grid>
            </Grid>
          </FormCard>
        </Box>
        <TableGrid />
      </PageWrapper>

      {open && <AddFields onToggle={handleToggle} open={open} />}
    </>
  );
};
