/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFieldFromRiskFactorTemplateMutationVariables = Types.Exact<{
  healthParameterId: Types.Scalars['ID'];
  riskFactorTemplateId: Types.Scalars['ID'];
}>;


export type DeleteFieldFromRiskFactorTemplateMutation = { __typename?: 'Mutation', deleteFieldFromRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, display?: string | null, id?: string | null, isActive?: boolean | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, fields?: Array<{ __typename?: 'HealthParameter', id?: string | null } | null> | null } | null };


export const DeleteFieldFromRiskFactorTemplateDocument = gql`
    mutation DeleteFieldFromRiskFactorTemplate($healthParameterId: ID!, $riskFactorTemplateId: ID!) {
  deleteFieldFromRiskFactorTemplate(
    healthParameterId: $healthParameterId
    riskFactorTemplateId: $riskFactorTemplateId
  ) {
    arabicDisplay
    code
    createdDate
    display
    errors {
      code
      errorType
      field
      message
    }
    fields {
      id
    }
    id
    isActive
  }
}
    `;
export type DeleteFieldFromRiskFactorTemplateMutationFn = Apollo.MutationFunction<DeleteFieldFromRiskFactorTemplateMutation, DeleteFieldFromRiskFactorTemplateMutationVariables>;

/**
 * __useDeleteFieldFromRiskFactorTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteFieldFromRiskFactorTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldFromRiskFactorTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldFromRiskFactorTemplateMutation, { data, loading, error }] = useDeleteFieldFromRiskFactorTemplateMutation({
 *   variables: {
 *      healthParameterId: // value for 'healthParameterId'
 *      riskFactorTemplateId: // value for 'riskFactorTemplateId'
 *   },
 * });
 */
export function useDeleteFieldFromRiskFactorTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFieldFromRiskFactorTemplateMutation, DeleteFieldFromRiskFactorTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFieldFromRiskFactorTemplateMutation, DeleteFieldFromRiskFactorTemplateMutationVariables>(DeleteFieldFromRiskFactorTemplateDocument, options);
      }
export type DeleteFieldFromRiskFactorTemplateMutationHookResult = ReturnType<typeof useDeleteFieldFromRiskFactorTemplateMutation>;
export type DeleteFieldFromRiskFactorTemplateMutationResult = Apollo.MutationResult<DeleteFieldFromRiskFactorTemplateMutation>;
export type DeleteFieldFromRiskFactorTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteFieldFromRiskFactorTemplateMutation, DeleteFieldFromRiskFactorTemplateMutationVariables>;