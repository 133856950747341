import { TFunction } from "@toolkit/i18n";
import { CareGapsIcon, useTheme } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { HealthParameterEditPage, HealthParameterNewPage, HealthParametersList } from "./Parameters";
import { healthParametersPaths, healthParametersRoute } from "./constants/healthParametersPaths";
import { HealthParameterDecisionCreateContainer, HealthParameterDecisionUpdateContainer } from "./containers";

export const HealthParametersRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  const theme = useTheme();
  return {
    id: "health-Parameters-routes",
    text: t("Health Parameters", { ns: "gcadmin" }),
    icon: (
      <CareGapsIcon
        sx={{
          "& path": {
            fill: theme.palette.primary.main,
          },
        }}
      />
    ),
    route: healthParametersRoute,
    subItems: [
      {
        id: "health-Parameters-list-route",
        text: t("Health Parameters", { ns: "gcadmin" }),
        route: healthParametersPaths.listPath.route,
        fullPath: healthParametersPaths.listPath.fullPath,
        element: <HealthParametersList />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-fields-new-route",
        text: t("Lab Parameters New", { ns: "gcadmin" }),
        route: healthParametersPaths.newPath.route,
        fullPath: healthParametersPaths.newPath.fullPath,
        element: <HealthParameterNewPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-fields-edit-route",
        text: t("Lab Parameters Edit", { ns: "gcadmin" }),
        route: healthParametersPaths.editPath.route,
        fullPath: healthParametersPaths.editPath.fullPath,
        element: <HealthParameterEditPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-parameter-rule-new-route",
        text: "",
        route: healthParametersPaths.newDecisionPath.route,
        fullPath: healthParametersPaths.newDecisionPath.fullPath,
        element: <HealthParameterDecisionCreateContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-parameter-rule-update-route",
        text: "",
        route: healthParametersPaths.updateDecisionPath.route,
        fullPath: healthParametersPaths.updateDecisionPath.fullPath,
        element: <HealthParameterDecisionUpdateContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
