import { z } from "zod";
import { GuidedCareTemplateActivityItemUpdateFormSchema } from "../TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

export const guidedCareTemplateGoalRecommendationInterventionActivityItemsFormSchema = z.object({
  activityItems: z.array(GuidedCareTemplateActivityItemUpdateFormSchema),
});

export type IGuidedCareTemplateGoalRecommendationInterventionActivityItemsFormValues = z.infer<
  typeof guidedCareTemplateGoalRecommendationInterventionActivityItemsFormSchema
>;

export const guidedCareTemplateGoalRecommendationInterventionActivityItemsFormDefaultValues: Partial<IGuidedCareTemplateGoalRecommendationInterventionActivityItemsFormValues> =
  {
    activityItems: [],
  };
