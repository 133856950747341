import { HealthCondition } from "@health/queries/types";
import { useToggle } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormCard, Grid, MainListActions, PageWrapper, TableGrid, TextField, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { convertToTitleCase } from "utils";
import {
  healthConditionsDiagnosisBreadcrumb,
  healthConditionsFieldBreadcrumb,
  healthConditionsListBreadcrumb,
  healthConditionsTitleBreadcrumb,
} from "../constants/healthConditionsBreadcrumbs";
import { AddFields } from "./AddDiagnoses";
import { AddDiagnosesByByDisease } from "./AddDiagnosesByDisease";

export const HealthConditionData: FC<{ condition?: HealthCondition; isLoading: boolean }> = ({ condition, isLoading }) => {
  const { t } = useTranslation("gcadmin");
  const { open, handleToggle } = useToggle();

  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    if (condition?.display) {
      setBreadCrumb({
        title: healthConditionsTitleBreadcrumb(),
        values: [
          healthConditionsListBreadcrumb(),
          healthConditionsFieldBreadcrumb(condition.display),
          healthConditionsDiagnosisBreadcrumb(),
        ],
      });
    }
  }, [condition?.display]);

  return (
    <>
      <PageWrapper
        actions={
          <>
            <AddDiagnosesByByDisease />
            <MainListActions hasAddNew onAddNewItem={handleToggle} />
          </>
        }
      >
        <Box sx={{ marginBottom: 1 }}>
          <FormCard title={t("Condition information's")} loading={isLoading} doYouHaveData={Boolean(condition)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("risk-display")} defaultValue={condition?.display} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("risk-arabic-display")} defaultValue={condition?.arabicDisplay} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("Code")} defaultValue={condition?.code} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("Disease")} defaultValue={condition?.disease} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("Type")} defaultValue={convertToTitleCase(condition?.type || "")} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  disabled
                  fullWidth
                  size='small'
                  label={t("UMLS Code")}
                  defaultValue={convertToTitleCase(condition?.umlsCode || "")}
                />
              </Grid>
            </Grid>
          </FormCard>
        </Box>
        <TableGrid />
      </PageWrapper>

      {open && <AddFields onToggle={handleToggle} open={open} />}
    </>
  );
};
