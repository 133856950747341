/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type LabTemplateFragment = { __typename?: 'LabTemplate', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, createdDate?: string | null, isActive?: boolean | null, labCodeDisplay?: string | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, display?: string | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null> | null };

export const LabTemplateFragmentDoc = gql`
    fragment LabTemplate on LabTemplate {
  id
  code
  display
  arabicDisplay
  createdDate
  isActive
  labCodeDisplay
  fields {
    allowedValues
    arabicDisplay
    code
    createdDate
    defaultValue
    display
    id
    isMandatory
    maxNormalRangeValue
    minNormalRangeValue
    referenceRange
    standard
    type
    unit {
      arabicDisplay
      display
    }
    category {
      id
      code
      display
      arabicDisplay
    }
  }
}
    `;