import { useBreadCrumbs } from "@toolkit/ui";
import {
  riskFactorTemplateListBreadcrumb,
  riskFactorTemplateNewBreadcrumb,
  riskFactorTemplatesTitleBreadcrumb,
} from "../../../constants/riskFactorTemplatesBreadcrumbs";
import React, { FC, useEffect } from "react";
import { RiskFactorTemplateForm } from "../RiskFactorTemplateForm";
import { useRiskFactorTemplateField } from "./useRiskFactorTemplateField";

export const RiskFactorTemplateNewPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, handleSubmit, isSubmitting } = useRiskFactorTemplateField();

  useEffect(() => {
    setBreadCrumb({
      title: riskFactorTemplatesTitleBreadcrumb(),
      values: [riskFactorTemplateListBreadcrumb(), riskFactorTemplateNewBreadcrumb()],
    });
  }, []);

  return <RiskFactorTemplateForm errors={errors} onSubmit={handleSubmit} isSubmitting={isSubmitting} />;
};
