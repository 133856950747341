import { ArticleCategory, ArticleCategoryInput } from "@health/queries/types";
import { IArticleCategoryUpsertFormValues } from "../forms";

export const convertArticleCategoryFormValuesToBackEndValues = (values: IArticleCategoryUpsertFormValues): ArticleCategoryInput => {
  return {
    code: values?.code,
    display: values?.display,
    displayAr: values?.displayAr,
  };
};

export const convertArticleCategoryToFormValues = (category: ArticleCategory): IArticleCategoryUpsertFormValues => {
  return {
    code: category?.code!,
    display: category?.display!,
    displayAr: category?.displayAr,
  };
};
