import { GridProvider } from "@health/domains";
import { RiskFactorTemplate } from "@health/queries/types";
import { extractCountableConnectionData } from "@toolkit/ui";
import { useHealthParametersColumns } from "pages/HealthParameters";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteFieldFromHealthConditionMutation, useGetHealthConditionQuery } from "../gql";
import { RiskFactorFieldsTemplate } from "./RiskFactorFieldsTemplate";

export const HealthConditionParameterList: FC = () => {
  const params = useParams() as { id: string };
  const { id } = params;
  const [template, setTemplate] = useState<RiskFactorTemplate | undefined>(undefined);

  const handleChangeTemplate = template => {
    setTemplate(template);
  };

  return (
    <GridProvider
      gridName='getRiskFactorTemplate'
      columns={useHealthParametersColumns()}
      extractCountableConnectionData={({ responseData }) => {
        handleChangeTemplate(responseData?.getHealthCondition);
        return extractCountableConnectionData({ responseData: responseData?.getHealthCondition!, entityName: "fields" });
      }}
      variables={{
        getHealthConditionId: id!,
      }}
      query={useGetHealthConditionQuery}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: true,
      }}
      deleteItemProps={{
        useDeleteMutation: useDeleteFieldFromHealthConditionMutation,
        idPropName: "healthParameterId",
        name: "Health Condition",
        entityTypeName: "RiskFactorTemplate",
        extraVariables: { healthConditionId: id },
      }}
    >
      <RiskFactorFieldsTemplate template={template} />
    </GridProvider>
  );
};
