import { z } from "zod";
import { guidedCareTemplateGoalRecommendationUpsertFormSchema } from "../TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertFormSchema";

export const guidedCareTemplateGoalUpsertFormSchema = z.object({
  uniqueId: z.string().nullable().optional(),
  name: z.string(),
  nameAr: z.string().nullable().optional(),
  target: z.string(),
  description: z.string(),
  descriptionAr: z.string().nullable().optional(),
  recommendations: z.array(guidedCareTemplateGoalRecommendationUpsertFormSchema),
});

export type IGuidedCareTemplateGoalUpsertFormValues = z.infer<typeof guidedCareTemplateGoalUpsertFormSchema>;

export const guidedCareTemplateGoalUpsertFormDefaultValues: Partial<IGuidedCareTemplateGoalUpsertFormValues> = {
  uniqueId: undefined,
  name: undefined,
  nameAr: undefined,
  target: undefined,
  description: undefined,
  descriptionAr: undefined,
  recommendations: [],
};
