import { i18n } from "@toolkit/i18n";
import { healthParametersPaths } from "./healthParametersPaths";

export const healthParametersTitleBreadcrumb = () => {
  return i18n.t("Health Parameters", { ns: "gcadmin" });
};

export const healthParametersListBreadcrumb = () => {
  return {
    id: "health-parameters-list",
    name: i18n.t("Health Parameters", { ns: "gcadmin" }),
    route: healthParametersPaths.listPath.fullPath,
  };
};

export const healthParametersNewBreadcrumb = () => {
  return {
    id: "health-parameters-new",
    name: i18n.t("New Parameter", { ns: "gcadmin" }),
  };
};

export const healthParametersFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "health-parameters-field",
    name: fieldName,
  };
};
