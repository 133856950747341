/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { LabTemplateFragmentDoc } from '../../fragments/__generated__/LabTemplate';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DuplicateLabTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.LabTemplateInput;
}>;


export type DuplicateLabTemplateMutation = { __typename?: 'Mutation', duplicateLabTemplate?: { __typename?: 'LabTemplate', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, createdDate?: string | null, isActive?: boolean | null, labCodeDisplay?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, display?: string | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null> | null } | null };


export const DuplicateLabTemplateDocument = gql`
    mutation DuplicateLabTemplate($id: ID!, $input: LabTemplateInput!) {
  duplicateLabTemplate(id: $id, input: $input) {
    ...LabTemplate
    errors {
      code
      errorType
      field
      message
    }
  }
}
    ${LabTemplateFragmentDoc}`;
export type DuplicateLabTemplateMutationFn = Apollo.MutationFunction<DuplicateLabTemplateMutation, DuplicateLabTemplateMutationVariables>;

/**
 * __useDuplicateLabTemplateMutation__
 *
 * To run a mutation, you first call `useDuplicateLabTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateLabTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateLabTemplateMutation, { data, loading, error }] = useDuplicateLabTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateLabTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateLabTemplateMutation, DuplicateLabTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateLabTemplateMutation, DuplicateLabTemplateMutationVariables>(DuplicateLabTemplateDocument, options);
      }
export type DuplicateLabTemplateMutationHookResult = ReturnType<typeof useDuplicateLabTemplateMutation>;
export type DuplicateLabTemplateMutationResult = Apollo.MutationResult<DuplicateLabTemplateMutation>;
export type DuplicateLabTemplateMutationOptions = Apollo.BaseMutationOptions<DuplicateLabTemplateMutation, DuplicateLabTemplateMutationVariables>;