import { z } from "zod";
import { guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema } from "../TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertFormSchema";

export const guidedCareTemplateGoalRecommendationUpsertFormSchema = z.object({
  uniqueId: z.string().nullable().optional(),
  name: z.string(),
  nameAr: z.string().nullable().optional(),
  code: z.string(),
  description: z.string(),
  descriptionAr: z.string().nullable().optional(),
  interventions: z.array(guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema),
});

export type IGuidedCareTemplateGoalRecommendationUpsertFormValues = z.infer<typeof guidedCareTemplateGoalRecommendationUpsertFormSchema>;

export const guidedCareTemplateGoalRecommendationUpsertFormDefaultValues: Partial<IGuidedCareTemplateGoalRecommendationUpsertFormValues> = {
  uniqueId: undefined,
  name: undefined,
  nameAr: undefined,
  code: undefined,
  description: undefined,
  descriptionAr: undefined,
  interventions: [],
};
