import { FC, PropsWithChildren, useState } from "react";
import { toSentenceCase } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import { Box, Tooltip } from "@toolkit/ui";
import { guidedCareActivityTypeMapper, guidedCareActivityTypeOptionsMap } from "@health/enum-options";
import { TemplateActivity } from "@health/queries/types";
import { GuidedCareTemplateActivitiesItemsTimelineItemContent } from "../TemplateActivitiesItemsTimelineItemContent/GuidedCareTemplateActivitiesItemsTimelineItemContent";
import { useGuidedCareTemplateActivitiesItemsTimelineItemStyle } from "./useGuidedCareTemplateActivitiesItemsTimelineItemStyle";

type TemplateActivityTimelineItemProps = {
  item: TemplateActivity;
  offsetInDays: number;
};

export const GuidedCareTemplateActivitiesItemsTimelineItem: FC<PropsWithChildren<TemplateActivityTimelineItemProps>> = props => {
  const { item, offsetInDays, children } = props;

  const { t } = useTranslation("gcadmin");

  const { classes, theme } = useGuidedCareTemplateActivitiesItemsTimelineItemStyle();

  const currentDir = i18n.dir(i18n.language);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      disableFocusListener
      disableTouchListener
      placement={currentDir === "ltr" ? "right" : "left"}
      classes={{
        tooltip: classes.tooltip,
        popper: classes.popper,
      }}
      title={
        <>
          <Box>
            <GuidedCareTemplateActivitiesItemsTimelineItemContent
              title={
                t("Assignee") +
                " : " +
                toSentenceCase(item?.templateTeamMember?.position!) +
                (item?.templateTeamMember?.specialization ? " - " + item?.templateTeamMember?.specialization! || "" : "")
              }
              icon='calender'
            />

            <GuidedCareTemplateActivitiesItemsTimelineItemContent
              title={guidedCareActivityTypeOptionsMap[item?.templateActivityType!]?.label}
              icon={guidedCareActivityTypeMapper(item?.templateActivityType!, t, theme.palette)?.icon!}
              isBold
            />

            <GuidedCareTemplateActivitiesItemsTimelineItemContent
              title={t("Offset (Days)") + " " + String(offsetInDays)!}
              icon='calender'
            />

            <GuidedCareTemplateActivitiesItemsTimelineItemContent title={item?.medicalMessage?.name! || "-"} icon='medical-massage' />
          </Box>
        </>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};
