/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddDiagnosisToHealthConditionByDiseaseMutationVariables = Types.Exact<{
  disease: Types.Scalars['String'];
  healthConditionId: Types.Scalars['ID'];
}>;


export type AddDiagnosisToHealthConditionByDiseaseMutation = { __typename?: 'Mutation', addDiagnosisToHealthConditionByDisease?: { __typename?: 'HealthCondition', id?: string | null } | null };


export const AddDiagnosisToHealthConditionByDiseaseDocument = gql`
    mutation AddDiagnosisToHealthConditionByDisease($disease: String!, $healthConditionId: ID!) {
  addDiagnosisToHealthConditionByDisease(
    disease: $disease
    healthConditionId: $healthConditionId
  ) {
    id
  }
}
    `;
export type AddDiagnosisToHealthConditionByDiseaseMutationFn = Apollo.MutationFunction<AddDiagnosisToHealthConditionByDiseaseMutation, AddDiagnosisToHealthConditionByDiseaseMutationVariables>;

/**
 * __useAddDiagnosisToHealthConditionByDiseaseMutation__
 *
 * To run a mutation, you first call `useAddDiagnosisToHealthConditionByDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiagnosisToHealthConditionByDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiagnosisToHealthConditionByDiseaseMutation, { data, loading, error }] = useAddDiagnosisToHealthConditionByDiseaseMutation({
 *   variables: {
 *      disease: // value for 'disease'
 *      healthConditionId: // value for 'healthConditionId'
 *   },
 * });
 */
export function useAddDiagnosisToHealthConditionByDiseaseMutation(baseOptions?: Apollo.MutationHookOptions<AddDiagnosisToHealthConditionByDiseaseMutation, AddDiagnosisToHealthConditionByDiseaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiagnosisToHealthConditionByDiseaseMutation, AddDiagnosisToHealthConditionByDiseaseMutationVariables>(AddDiagnosisToHealthConditionByDiseaseDocument, options);
      }
export type AddDiagnosisToHealthConditionByDiseaseMutationHookResult = ReturnType<typeof useAddDiagnosisToHealthConditionByDiseaseMutation>;
export type AddDiagnosisToHealthConditionByDiseaseMutationResult = Apollo.MutationResult<AddDiagnosisToHealthConditionByDiseaseMutation>;
export type AddDiagnosisToHealthConditionByDiseaseMutationOptions = Apollo.BaseMutationOptions<AddDiagnosisToHealthConditionByDiseaseMutation, AddDiagnosisToHealthConditionByDiseaseMutationVariables>;