import { find } from "lodash";
import { toSentenceCase } from "@toolkit/core";
import { mapToAutocompleteOptions } from "@toolkit/ui";
import {
  guidedCareActivityTypeToTeamMemberPositionMap,
  guidedCareTeamMemberPositionOptionsMap,
  guidedCareTeamMemberWithActivityOptions,
  isGuidedCareActivityTypeMedicalMessage,
  isGuidedCareTeamMemberNonManagement,
  medicalMessageCategoryOptionsMap,
} from "@health/enum-options";
import { GuidedCareActivityType } from "@health/queries/types";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";

export const getTeamMemberLabel = (teamMember: IGuidedCareTemplateMemberUpsertFormValues) => {
  const teamMemberSpecialization =
    isGuidedCareTeamMemberNonManagement(teamMember?.position?.value!) && teamMember?.specialization?.label
      ? ` - ${toSentenceCase(teamMember?.specialization?.label)}`
      : "";

  return `${teamMember?.position?.label}${teamMemberSpecialization}`;
};

export const filterTeamMembersByActivityType = (
  teamMembers: IGuidedCareTemplateMemberUpsertFormValues[],
  activityType: GuidedCareActivityType
) => {
  const activityTypePositions = guidedCareActivityTypeToTeamMemberPositionMap[activityType] || [];

  const filteredTeamMembers =
    teamMembers?.filter(item => {
      const teamMember = guidedCareTeamMemberPositionOptionsMap[item?.position?.value];
      return (
        teamMember &&
        guidedCareTeamMemberWithActivityOptions.some(teamMemberActivity => {
          return teamMemberActivity?.value === teamMember?.value && activityTypePositions.includes(teamMember?.value);
        })
      );
    }) || [];

  return mapToAutocompleteOptions(filteredTeamMembers, "uniqueId", getTeamMemberLabel);
};

export const getMedicalMessageCategory = (medicalMessageLabel: string) => {
  const medicalMessageLabelSplit = medicalMessageLabel?.split("-");
  const medicalMessageCategory = medicalMessageLabelSplit?.length > 1 ? medicalMessageLabelSplit[1]?.toLowerCase()?.trim() : "";

  const medicalMessageCategoryObject = find(medicalMessageCategoryOptionsMap, option =>
    option?.label?.toLowerCase()?.includes(medicalMessageCategory)
  );

  return medicalMessageCategoryObject?.key || undefined;
};

export const shouldTemplateActivityMessagesAndOffsetsShow = (templateActivityType: GuidedCareActivityType) => {
  return templateActivityType !== GuidedCareActivityType.MedicalForm && !isGuidedCareActivityTypeMedicalMessage(templateActivityType);
};
