import { HealthConditionDiagnosis } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export type HealthConditionsDiagnosesColumnTypes = CustomTableColumnProps<HealthConditionDiagnosis>;

export const useHealthParametersColumns = (): HealthConditionsDiagnosesColumnTypes[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "diagnosisCode",
        accessor: "diagnosisCode",
        header: t("diagnosis Code"),
      },
      {
        key: "diagnosisDisplay",
        accessor: "diagnosisDisplay",
        header: t("Diagnosis Display"),
      },
    ];
  }, [t]);
};
