import { ArticleCategory } from "@health/queries/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { ArticleCategoryInformationForm } from "../ArticleCategoryFromInformation";
import {
  ArticleCategoryUpsertFormSchema,
  articleCategoryUpsertFormSchemaDefaultValues,
  IArticleCategoryUpsertFormValues,
} from "./ArticleCategoryUpsertFormSchema";
import { convertArticleCategoryToFormValues } from "../../utils";
import { useNavigate } from "react-router-dom";
import { articlesCategoriesPaths } from "../../constants";

type ArticleCategoryUpsertFormProps = {
  articleCategory?: ArticleCategory;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel: string;
  onChange: (values: IArticleCategoryUpsertFormValues) => void;
};

export const ArticleCategoryUpsertForm: FC<ArticleCategoryUpsertFormProps> = props => {
  const { articleCategory, isLoading, isSubmitting, submitButtonLabel, onChange } = props;
  const navigate = useNavigate();
  const form = useCustomForm<IArticleCategoryUpsertFormValues>({
    schema: ArticleCategoryUpsertFormSchema,
    defaultValues: articleCategoryUpsertFormSchemaDefaultValues,
    mode: "onChange",
  });

  const { handleSubmit, setValues } = form;
  const { t } = useTranslation("domains");
  const isButtonDisabled = isLoading || isSubmitting;

  const onSubmit = (values: IArticleCategoryUpsertFormValues) => {
    onChange(values);
  };
  const handleCancel = () => {
    navigate(articlesCategoriesPaths.main.fullPath);
  };
  useEffect(() => {
    if (articleCategory) {
      const _articleCategory = convertArticleCategoryToFormValues(articleCategory);

      setValues(_articleCategory);
    }
  }, [articleCategory, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Category Information")} loading={isLoading} doYouHaveData>
                <ArticleCategoryInformationForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
