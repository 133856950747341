import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useGetHealthConditionQuery, useUpdateHealthConditionMutation } from "pages/HealthConditions/gql";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { healthConditionsPaths } from "../../constants/healthConditionsPaths";
import { IFormData } from "../types";

export const useHealthConditionEdit = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const params = useParams() as { id: string };
  const { id } = params;

  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const { data, loading } = useGetHealthConditionQuery({
    variables: {
      getHealthConditionId: id!,
    },
  });

  const [HealthConditionUpdate, { loading: isSubmitting }] = useUpdateHealthConditionMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.updateHealthCondition?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Health Condition Updated Successfully"));
        navigate(healthConditionsPaths.listPath.fullPath);
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Health Condition Update Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const handleSubmit = (input: Partial<IFormData>) => {
    delete input?.code;
    HealthConditionUpdate({
      variables: {
        updateHealthConditionId: id,
        input: {
          ...input,
          isActive: input?.isActive || false,
          type: input?.type?.value,
        } as IFormData,
      },
    });
  };

  const healthCondition = data?.getHealthCondition;

  return {
    errors,
    loading,
    healthCondition,
    handleSubmit,
    isSubmitting,
  };
};
