import { createPathDefinition } from "@toolkit/core";

export const guidedCareTemplatesRoute = `/guided-care/templates`;

export const guidedCareTemplatesPaths = createPathDefinition(guidedCareTemplatesRoute, {
  list: "",
  create: "new",
  update: ":templateId",
  activitiesItemList: ":templateId/activity-items",
  activityItemList: "activities/:activityId/activity-items",
  goalRecommendationInterventionActivityItemList: "intervention-activities/:activityId/activity-items",
});
