import { GridProvider } from "@health/domains";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useDeleteHealthConditionDiagnosisMutation, useGetHealthConditionDiagnosesQuery, useGetHealthConditionQuery } from "../gql";
import { HealthConditionData } from "./HealthConditionData";
import { useHealthParametersColumns } from "./useHealthConditionDiagnosesCoulmns";

export const HealthConditionsDiagnosesList: FC = () => {
  const params = useParams() as { id: string };
  const { id } = params;
  const { data, loading } = useGetHealthConditionQuery({
    variables: {
      getHealthConditionId: id!,
    },
  });

  return (
    <GridProvider
      gridName='getRiskFactorTemplate'
      columns={useHealthParametersColumns()}
      variables={{
        filter: {
          healthConditionId: id!,
        },
      }}
      dataAccessor={"getHealthConditionDiagnoses"}
      query={useGetHealthConditionDiagnosesQuery}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: true,
      }}
      deleteItemProps={{
        useDeleteMutation: useDeleteHealthConditionDiagnosisMutation,
        idPropName: "deleteHealthConditionDiagnosisId",
        name: "Health Condition Diagnosis",
        entityTypeName: "HealthConditionDiagnosis",
      }}
    >
      <HealthConditionData condition={data?.getHealthCondition!} isLoading={loading} />
    </GridProvider>
  );
};
