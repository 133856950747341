import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  riskFactorTemplateCloneBreadcrumb,
  riskFactorTemplateListBreadcrumb,
  riskFactorTemplatesTitleBreadcrumb,
} from "../../constants/riskFactorTemplatesBreadcrumbs";
import { riskFactorTemplatesPaths } from "../../constants/riskFactorTemplatesPaths";
import { useDuplicateRiskFactorTemplateMutation, useGetRiskFactorTemplateQuery } from "../gql";

export const useRiskFactorTemplateClone = () => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();

  const params = useParams() as { id: string };
  const { id } = params;
  const { data, loading } = useGetRiskFactorTemplateQuery({
    variables: {
      getRiskFactorTemplateId: id!,
    },
  });

  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const [riskFactorTemplateClone] = useDuplicateRiskFactorTemplateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.duplicateRiskFactorTemplate?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Risk Factor Template Cloned Successfully"));
        navigate(riskFactorTemplatesPaths.listPath.fullPath);
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Risk Factor Template Cloned Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const handleSubmit = input => {
    riskFactorTemplateClone({
      variables: {
        duplicateRiskFactorTemplateId: id,
        input: {
          ...input,
          isActive: input?.isActive || false,
          diagnosisCode: input?.diagnosisCode?.value?.code,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: riskFactorTemplatesTitleBreadcrumb(),
      values: [riskFactorTemplateListBreadcrumb(), riskFactorTemplateCloneBreadcrumb()],
    });
  }, []);

  return {
    errors,
    handleSubmit,
    loading,
    clonedTemplate: data?.getRiskFactorTemplate,
  };
};
