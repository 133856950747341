import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  EditIcon,
  IconButton,
  MuiDeleteForeverIcon,
  MuiExpandMoreIcon,
  PlusIcon,
  Tooltip,
  Typography,
} from "@toolkit/ui";
import React, { FC, MouseEvent, PropsWithChildren } from "react";
import { useGuidedCareTemplateCustomAccordionStyle } from "./useGuidedCareTemplateCustomAccordionStyle";
import { useTranslation } from "@toolkit/i18n";

export type IGuidedCareTemplateCustomAccordionEvent = {
  type: "ADD_CLICK" | "UPDATE_CLICK" | "DELETE_CLICK";
};

type GuidedCareTemplateCustomAccordionProps = PropsWithChildren<{
  title: string;
  type: "GOAL" | "RECOMMENDATION" | "INTERVENTION";
  description?: string;
  onChange: (event: IGuidedCareTemplateCustomAccordionEvent) => void;
}>;

export const GuidedCareTemplateCustomAccordion: FC<GuidedCareTemplateCustomAccordionProps> = props => {
  const { title, type, description, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const { classes, theme } = useGuidedCareTemplateCustomAccordionStyle();

  const onAddClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onChange({ type: "ADD_CLICK" });
  };

  const onUpdateClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onChange({ type: "UPDATE_CLICK" });
  };

  const onDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onChange({ type: "DELETE_CLICK" });
  };

  const getTooltipByType = () => {
    if (type === "GOAL") {
      return {
        addTooltip: t("Add New Recommendation"),
        updateTooltip: t("Update Goal"),
        deleteTooltip: t("Delete Update"),
      };
    } else if (type === "RECOMMENDATION") {
      return {
        addTooltip: t("Add New Intervention"),
        updateTooltip: t("Update Recommendation"),
        deleteTooltip: t("Delete Recommendation"),
      };
    } else if (type === "INTERVENTION") {
      return {
        addTooltip: t("Add New Activity"),
        updateTooltip: t("Update Intervention"),
        deleteTooltip: t("DeleteIntervention"),
      };
    }
  };

  const tooltip = getTooltipByType();

  return (
    <Accordion className={classes.accordion} elevation={0} disableGutters>
      <AccordionSummary expandIcon={<MuiExpandMoreIcon />} className={classes.accordionSummary}>
        <Box className={classes.titleWrapper}>
          <Typography className={classes.title}> {title} </Typography>
          {description && <Typography variant={"body2"}>{description}</Typography>}
        </Box>

        <Box>
          <Tooltip title={tooltip?.addTooltip!}>
            <IconButton color={"primary"} onClick={onAddClick}>
              <PlusIcon fill={theme.palette.primary.main} fontSize={"medium"} />
            </IconButton>
          </Tooltip>

          <Tooltip title={tooltip?.updateTooltip!}>
            <IconButton color={"primary"} onClick={onUpdateClick}>
              <EditIcon fontSize={"small"} />
            </IconButton>
          </Tooltip>

          <Tooltip title={tooltip?.deleteTooltip!}>
            <IconButton onClick={onDeleteClick} color={"primary"}>
              <MuiDeleteForeverIcon fontSize={"medium"} />
            </IconButton>
          </Tooltip>
        </Box>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}> {props.children} </AccordionDetails>
    </Accordion>
  );
};
