import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid } from "@toolkit/ui";
import React, { FC } from "react";

export const ArticleCategoryInformationForm: FC = () => {
  const { t } = useTranslation("gcadmin");

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"display"} label={t("Display")} />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"displayAr"} label={t("Arabic display")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"code"} label={t("Code")} />
      </Grid>
    </Grid>
  );
};
