import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { labTemplatesTitleBreadcrumb } from "pages/HealthTemplate/LabParameters/constants/labTemplatesBreadcrumbs";
import { labTemplatesPaths } from "pages/HealthTemplate/LabParameters/constants/labTemplatesPaths";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetLabTemplatesQuery } from "../gql";
import { useLabTemplatesColumns } from "./useLabTemplatesColumns";

export const LabTemplatesList: FC = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const handleEditRow = data => {
    navigate(labTemplatesPaths.editPath.fullPathWithParams(data?.id));
  };

  const handleAddNew = () => {
    navigate(labTemplatesPaths.newPath.fullPath);
  };

  useEffect(() => {
    setBreadCrumb({ title: labTemplatesTitleBreadcrumb() });
  }, []);

  return (
    <GridProvider
      gridName='getLabTemplates'
      columns={useLabTemplatesColumns()}
      query={useGetLabTemplatesQuery}
      fetchPolicy='no-cache'
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddNew} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
