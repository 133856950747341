import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalEvent } from "pages/Templates/types";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateActivityItemUpdateForm,
  GuidedCareTemplateActivityItemUpdateFormRef,
} from "../../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateForm";
import { IGuidedCareTemplateActivityItemUpdateFormValues } from "../../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

type IGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalShowOptions = {
  activityItem?: IGuidedCareTemplateActivityItemUpdateFormValues;
};

type IShow = (show: boolean, showOptions?: IGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalShowOptions) => void;

export const GuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalApi: { show: IShow } = {
  show: () => {},
};

type GuidedCareTemplateActivityItemUpdateModalProps = {
  onChange: (values: IGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalEvent) => void;
};

export const GuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModal: FC<
  GuidedCareTemplateActivityItemUpdateModalProps
> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState<IGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalShowOptions>();

  const { t } = useTranslation("gcadmin");
  const activityItemUpdateFormRef = useRef<GuidedCareTemplateActivityItemUpdateFormRef>(null);

  const onUpdateClick = () => {
    activityItemUpdateFormRef.current?.submit();
  };

  const show: IShow = (open, _showOptions) => {
    setIsOpen(open);
    setShowOptions(_showOptions);
  };

  useEffect(() => {
    GuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalApi.show = show;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Update Activity Item"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Update"),
        onSubmit: onUpdateClick,
      }}
    >
      <GuidedCareTemplateActivityItemUpdateForm
        activityItem={showOptions?.activityItem}
        ref={activityItemUpdateFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
