import { combineErrors, getMaxValueValidation, getMinValueValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IForm, IFormData } from "../types";

export const RangeValue: FC<IForm> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("gcadmin");
  const {
    register,
    watch,
    formState: { errors: reactFormErrors },
  } = useFormContext<IFormData>();
  const formErrors = combineErrors(reactFormErrors, errors);
  const minNormalRangeValue = watch("minNormalRangeValue");
  const maxNormalRangeValue = watch("maxNormalRangeValue");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          type='number'
          size='small'
          inputProps={{ step: "any" }}
          label={t("Min Value")}
          error={Boolean(formErrors.minNormalRangeValue?.message)}
          helperText={t(formErrors.minNormalRangeValue?.message)}
          defaultValue={defaultValue?.minNormalRangeValue}
          {...register("minNormalRangeValue", {
            max: getMaxValueValidation(t, Number(maxNormalRangeValue) || 100),
            required: getRequiredValidation(t, true),
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          type='number'
          inputProps={{ step: "any" }}
          size='small'
          label={t("Max Value")}
          error={Boolean(formErrors.maxNormalRangeValue?.message)}
          helperText={t(formErrors.maxNormalRangeValue?.message)}
          defaultValue={defaultValue?.maxNormalRangeValue}
          {...register("maxNormalRangeValue", {
            min: getMinValueValidation(t, Number(minNormalRangeValue)),
            required: getRequiredValidation(t, true),
          })}
        />
      </Grid>
    </Grid>
  );
};
