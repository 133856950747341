import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent } from "pages/Templates/types";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateGoalRecommendationUpsertForm,
  GuidedCareTemplateGoalRecommendationUpsertFormRef,
} from "../../forms/TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertForm";
import { IGuidedCareTemplateGoalRecommendationUpsertFormValues } from "../../forms/TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertFormSchema";

type IGuidedCareTemplateGoalRecommendationUpsertModalShowOptions = {
  goalIndex?: number;
  recommendationIndex?: number;
  recommendation?: IGuidedCareTemplateGoalRecommendationUpsertFormValues;
};

type IShow = (show: boolean, showOptions?: IGuidedCareTemplateGoalRecommendationUpsertModalShowOptions) => void;

export const GuidedCareTemplateGoalRecommendationUpsertModalApi: { show: IShow } = {
  show: () => {},
};

type GuidedCareTemplateGoalRecommendationUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent) => void;
};

export const GuidedCareTemplateGoalRecommendationUpsertModal: FC<GuidedCareTemplateGoalRecommendationUpsertModalProps> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState<IGuidedCareTemplateGoalRecommendationUpsertModalShowOptions>();

  const { t } = useTranslation("gcadmin");

  const templateGoalRecommendationUpsertFormRef = useRef<GuidedCareTemplateGoalRecommendationUpsertFormRef>(null);

  const show: IShow = (open, _showOptions) => {
    setIsOpen(open);
    setShowOptions(_showOptions);
  };

  const onButtonClick = () => {
    templateGoalRecommendationUpsertFormRef.current?.submit();
  };

  useEffect(() => {
    GuidedCareTemplateGoalRecommendationUpsertModalApi.show = show;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: showOptions?.recommendation ? t("Update Recommendation") : t("Add New Recommendation"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: !!showOptions?.recommendation,
        submitTitle: showOptions?.recommendation ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateGoalRecommendationUpsertForm
        goalIndex={showOptions?.goalIndex!}
        recommendationIndex={showOptions?.recommendationIndex}
        recommendation={showOptions?.recommendation}
        ref={templateGoalRecommendationUpsertFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
