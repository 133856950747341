import { FC } from "react";
import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareTemplateActivityUpsertFormValues } from "../TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { Box, Divider, FormAutocomplete, Grid, mapToAutocompleteOptions, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { IGuidedCareTemplateGoalUpsertFormValues } from "../TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";
import { flatMap } from "lodash";
import { useGuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormStyle } from "./useGuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormStyle";

type GuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormProps = {
  goals: IGuidedCareTemplateGoalUpsertFormValues[];
};

export const GuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertForm: FC<
  GuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormProps
> = props => {
  const { goals } = props;

  const { t } = useTranslation("gcadmin");

  const { classes } = useGuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormStyle();

  const form = useCustomFormContext<IGuidedCareTemplateActivityUpsertFormValues>();

  const { watch } = form;

  const medicalFormClassificationsInterventions = watch("medicalFormClassificationsInterventions");

  const interventions = flatMap(goals, goal => flatMap(goal.recommendations, recommendation => recommendation.interventions));

  const interventionsOptions = mapToAutocompleteOptions(interventions, "uniqueId", intervention =>
    pickLocalizedValue(intervention?.name, intervention?.nameAr || intervention?.name)
  );

  if (!medicalFormClassificationsInterventions || !medicalFormClassificationsInterventions?.length) {
    return <></>;
  }

  return (
    <Box>
      <Typography className={classes.title}>{t("Conditions")}</Typography>

      <Divider className={classes.divider} />

      <Grid container spacing={2} className={classes.root}>
        {medicalFormClassificationsInterventions?.map((item, index) => {
          return (
            <>
              <Grid key={item?.id} item xs={6}>
                <Typography className={classes.classificationName}>{item?.classification}</Typography>
              </Grid>

              <Grid item xs={6}>
                <FormAutocomplete
                  name={`medicalFormClassificationsInterventions.${index}.intervention`}
                  label={t("Intervention")}
                  options={interventionsOptions}
                />
              </Grid>

              <Divider variant={"middle"} className={classes.divider} />
            </>
          );
        })}
      </Grid>
    </Box>
  );
};
