import { replacePathParams } from "utils";

export const healthConditionsRoute = `/health-conditions`;
const healthConditionsNewRoute = "new";
const healthConditionsEditRoute = "edit/:id";
const healthConditionsCloneRoute = "clone/:id";
const healthConditionsFieldRoute = "field/:id";
const healthConditionsDiagnosisRoute = "diagnosis/:id";

export const healthConditionsPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${healthConditionsRoute}${this.route}`;
    },
  },
  newPath: {
    route: healthConditionsNewRoute,
    get fullPath() {
      return `${healthConditionsRoute}/${this.route}`;
    },
  },
  editPath: {
    route: healthConditionsEditRoute,
    get fullPath() {
      return `${healthConditionsRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  clonePath: {
    route: healthConditionsCloneRoute,
    get fullPath() {
      return `${healthConditionsRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  fieldPath: {
    route: healthConditionsFieldRoute,
    get fullPath() {
      return `${healthConditionsRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  diagnosisPath: {
    route: healthConditionsDiagnosisRoute,
    get fullPath() {
      return `${healthConditionsRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
