import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormEvent } from "pages/Templates/types";
import { FC, useEffect, useRef, useState } from "react";
import { GuidedCareTemplateActivityUpsertFormRef } from "../../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertForm";
import { IGuidedCareTemplateActivityUpsertFormValues } from "../../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { GuidedCareTemplateGoalRecommendationInterventionActivityUpsertForm } from "../../forms/TemplateGoalRecommendationInterventionActivityUpsert/GuidedCareTemplateGoalRecommendationInterventionActivityUpsertForm";

type IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalShowOptions = {
  goalIndex: number;
  recommendationIndex: number;
  interventionIndex: number;
  activityIndex?: number;
  teamMembers: IGuidedCareTemplateMemberUpsertFormValues[];
  activity?: IGuidedCareTemplateActivityUpsertFormValues;
};

type IShow = (show: boolean, showOptions?: IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalShowOptions) => void;

export const GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalApi: { show: IShow } = {
  show: () => {},
};

type GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormEvent) => void;
};

export const GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModal: FC<
  GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalProps
> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState<IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalShowOptions>();

  const { t } = useTranslation("gcadmin");
  const activityUpsertFormRef = useRef<GuidedCareTemplateActivityUpsertFormRef>(null);

  const show: IShow = (open, _showOptions) => {
    setIsOpen(open);
    setShowOptions(_showOptions);
  };

  const onButtonClick = () => {
    activityUpsertFormRef.current?.submit();
  };

  useEffect(() => {
    GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalApi.show = show;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: showOptions?.activity ? t("Update Activity") : t("Create Activity"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: showOptions?.activity ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateGoalRecommendationInterventionActivityUpsertForm
        goalIndex={showOptions?.goalIndex!}
        recommendationIndex={showOptions?.recommendationIndex!}
        interventionIndex={showOptions?.interventionIndex!}
        activityIndex={showOptions?.activityIndex}
        teamMembers={showOptions?.teamMembers!}
        activity={showOptions?.activity}
        onChange={onChange}
        ref={activityUpsertFormRef}
      />
    </CustomDialog>
  );
};
