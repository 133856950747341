import { Article, ArticleInput, Maybe } from "@health/queries/types";
import { IArticleUpsertFormValues } from "../forms";
import { createAutocompleteOption } from "@toolkit/ui";
import { ArticleQueryData } from "../types";
import { pickLocalizedValue } from "@toolkit/i18n";

export const convertArticleFormValuesToBackEndValues = (values: IArticleUpsertFormValues): ArticleInput => {
  return {
    title: values?.title!,
    active: values?.active!,
    categoryId: values?.category?.value?.id!,
    content: values?.content!,
    contentAr: values?.contentAr!,
    contentImageInputs: values?.contentImages!.map(image => ({
      alias: image.alias!,
      url: image.url!,
    })),
    description: values?.description!,
    headerImage: values?.headerImage!,
    readTimeMinutes: values?.readTimeMinutes!,
    relatedArticles: values?.relatedArticles!.map(article => article.value.id),
    tags: values?.tags!,
  };
};

export const convertArticleToFormValues = (article: ArticleQueryData): IArticleUpsertFormValues => {
  return {
    title: article.title!,
    active: article.active!,
    category: getCategoryOption(article?.category!),
    content: article.content!,
    contentAr: article.contentAr!,
    contentImages: article.contentImages!.map(image => ({
      alias: image?.alias!,
      url: image?.url!,
    })),
    description: article.description!,
    headerImage: article.headerImage!,
    readTimeMinutes: article.readTimeMinutes!,
    relatedArticles: article.relatedArticles!.map(article => getArticleOption(article)),
    tags: article?.tags?.map(tag => tag!),
  };
};
const getCategoryOption = (category: Article["category"]) => {
  return createAutocompleteOption(
    {
      id: category?.id!,
      display: category?.display!,
      displayAr: category?.displayAr!,
    },
    "id",
    item => pickLocalizedValue(item?.display, item?.displayAr || item?.display)
  );
};

const getArticleOption = (article: Maybe<Partial<Article>>) => {
  return createAutocompleteOption(
    {
      id: article?.id!,
      title: article?.title!,
    },
    "id",
    "title"
  );
};
