import { TFunction } from "@toolkit/i18n";
import { CareGapsIcon, useTheme } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { labRoutes } from "./LabParameters/route";
import { riskRulesParametersRoutes } from "./RiskRulesParameters/route";

export const HealthTemplates: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  const theme = useTheme();
  return {
    id: "Health-templates-routes",
    text: t("Health Parameters Set", { ns: "gcadmin" }),
    icon: (
      <CareGapsIcon
        sx={{
          "& path": {
            fill: theme.palette.primary.main,
          },
        }}
      />
    ),
    subItems: [labRoutes({ navigate, t }), riskRulesParametersRoutes({ navigate, t })],
  };
};
