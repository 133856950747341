import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { riskFactorTemplatesPaths } from "../../constants/riskFactorTemplatesPaths";
import { GeneralInformation } from "./components/GeneralInformation";
import { IFormData, IRiskFactorTemplateForm } from "./types";

export const RiskFactorTemplateForm: FC<IRiskFactorTemplateForm> = props => {
  const { t } = useTranslation("gcadmin");
  const { errors, riskTemplate, loading, onSubmit: handleSubmitData, isSubmitting, isClone } = props;
  const navigate = useNavigate();

  const methods = useForm<IFormData>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const handleCancel = () => {
    navigate(riskFactorTemplatesPaths.listPath.fullPath);
  };

  const onSubmit: SubmitHandler<IFormData> = data => {
    handleSubmitData(data as IFormData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              formButtonTitle={riskTemplate && !isClone ? t("Update") : t("Create")}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("General Information")} loading={loading} doYouHaveData>
                <GeneralInformation isClone={isClone} errors={errors} riskTemplate={riskTemplate} loading={loading} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
