import React, { FC } from "react";
import { useFieldArray } from "react-hook-form";
import { useCustomFormContext } from "@toolkit/core";
import { CustomTable, Grid } from "@toolkit/ui";
import {
  IGuidedCareActivityServiceListItem,
  IGuidedCareTemplateActivityServiceUpsertFormEvent,
  IGuidedCareTemplateGoalRecommendationInterventionActivityServicesFields,
} from "pages/Templates/types";
import { GuidedCareTemplateActivityServiceUpsertForm } from "../TemplateActivityServiceUpsert/GuidedCareTemplateActivityServiceUpsertForm";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useGuidedCareTemplateGoalRecommendationInterventionActivityServicesColumns } from "./useGuidedCareTemplateGoalRecommendationInterventionActivityServicesColumns";

type GuidedCareTemplateGoalRecommendationInterventionActivityServicesFormProps = {
  goalIndex: number;
  recommendationIndex: number;
  interventionIndex: number;
  activityIndex: number;
};

export const GuidedCareTemplateGoalRecommendationInterventionActivityServicesForm: FC<
  GuidedCareTemplateGoalRecommendationInterventionActivityServicesFormProps
> = props => {
  const { goalIndex, recommendationIndex, interventionIndex, activityIndex } = props;

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const { control } = form;

  const { fields, append, remove } = useFieldArray<
    IGuidedCareTemplateUpsertFormValues,
    IGuidedCareTemplateGoalRecommendationInterventionActivityServicesFields
  >({
    control,
    name: `goals.${goalIndex}.recommendations.${recommendationIndex}.interventions.${interventionIndex}.activities.${activityIndex}.activityServices`,
  });

  const onActivityServiceUpsertFormChange = (event: IGuidedCareTemplateActivityServiceUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
    }
  };

  const onDeleteRowClick = (item: IGuidedCareActivityServiceListItem) => {
    const itemIndex = fields.findIndex(field => field.id === item.id);

    if (itemIndex > -1) {
      remove(itemIndex);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GuidedCareTemplateActivityServiceUpsertForm onChange={onActivityServiceUpsertFormChange} />
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useGuidedCareTemplateGoalRecommendationInterventionActivityServicesColumns()}
          isDeleteVisible
          isRowDeletable
          hasFooter={false}
          scrollable
          onDeleteRow={onDeleteRowClick}
        />
      </Grid>
    </Grid>
  );
};
