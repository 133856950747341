/* eslint-disable max-lines */
import React, { Children, cloneElement, isValidElement, useEffect, useState } from "react";
import { Box, Button, IconButton, Step, StepLabel, Stepper, Typography } from "../../base/mui";
import { CustomIcon } from "../CustomIcon";
import { useTimelineStyles } from "./useTimelineStyles";
import { alpha } from "@mui/material";

interface AnnualTimeLineProps<T> {
  year?: number;
  timeline: { label: string; activities: T[]; isTemplateIntervention: boolean }[];
  children: React.ReactNode;
  isDisableNext?: boolean;
  isDisablePrev?: boolean;
  onIncrement?: () => void;
  onDecrement?: () => void;
}

export const AnnualTimeLine = <T,>({
  year,
  timeline,
  children,
  isDisableNext,
  isDisablePrev,
  onIncrement,
  onDecrement,
}: AnnualTimeLineProps<T>) => {
  const { classes, theme } = useTimelineStyles();
  const [step, setStep] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);
  const numItemsToShow = window.innerWidth <= 940 ? Math.floor(windowWidth / 75) - 1 : 12;

  const handleNext = () => {
    step === timeline.length - numItemsToShow
      ? (setStep(0), onIncrement?.())
      : setStep(Math.min(step + numItemsToShow, timeline.length - numItemsToShow));
  };

  const handlePrev = () => {
    step === 0 ? (setStep(timeline.length - numItemsToShow), onDecrement?.()) : setStep(Math.max(step - numItemsToShow, 0));
  };

  const showArrows = timeline.length > numItemsToShow;

  return (
    <Box display='flex' alignItems='end' justifyContent='center' position='relative'>
      {year ? (
        <>
          {step !== 0 ? (
            <IconButton className={classes.btn} onClick={handlePrev}>
              <CustomIcon icon='arrow' color='white' transform='rotate(180deg)' viewBox='-3 -3 24 24' />
            </IconButton>
          ) : (
            <Button
              className={`${classes.year} ${classes.prevYear}`}
              onClick={handlePrev}
              startIcon={<CustomIcon icon='arrow' color={theme.palette.common.white} transform='rotate(180deg)' viewBox='-4 -3 24 24' />}
            >
              {year - 1}
            </Button>
          )}
        </>
      ) : (
        showArrows && (
          <IconButton disabled={step === 0 || isDisablePrev} className={classes.btn} onClick={handlePrev}>
            <CustomIcon icon='arrow' color='white' transform='rotate(180deg)' viewBox='-3 -3 24 24' />
          </IconButton>
        )
      )}

      <Stepper connector={<Box />}>
        {timeline?.map((item, i) => {
          const childrenWithProps = Children.map(children, child => {
            if (isValidElement(child)) {
              return cloneElement<any>(child, { activities: item?.activities });
            }

            return child;
          });

          return (
            <Step
              key={item?.label}
              className={classes.step}
              sx={{ display: i >= step && i < step + numItemsToShow ? "flex" : "none !important" }}
            >
              <StepLabel
                StepIconComponent={props => <Box {...props} className={classes.stepIcon} marginBottom='34px' />}
                classes={{
                  root: classes.stepLabel,
                  iconContainer: classes.iconContainer,
                }}
              >
                <Box className={classes.centerFlex}>
                  <Box
                    className={window.innerWidth <= 1300 ? classes.activitiesMonth : classes.activities}
                    bgcolor={item?.isTemplateIntervention ? alpha(theme.palette.secondary.main, 0.5) : undefined}
                  >
                    {childrenWithProps}
                  </Box>

                  <Box display='flex'>
                    {Array.from({ length: 4 }, (_, index) => (
                      <Box display='flex' alignItems='baseline' key={index}>
                        <Box className={classes.separate} mx={window.innerWidth > 1300 ? 1 : "6px"} />

                        {i == timeline?.length - 1 && index === 3 && (
                          <Box
                            className={classes.stepIcon}
                            sx={{
                              marginBottom: "0px !important",
                              marginLeft: window.innerWidth > 1300 ? "8px" : "4px",
                              marginTop: "-16px",
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>

                  <Typography
                    fontSize={theme.mixins.fonts.fontSize.xs}
                    marginTop={2}
                    color={theme.palette.primary.main}
                    fontWeight={theme.mixins.fonts.fontWeight.medium}
                  >
                    {item?.label}
                  </Typography>
                </Box>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {year ? (
        <>
          {step !== timeline.length - numItemsToShow ? (
            <IconButton disabled={step === timeline.length - numItemsToShow || isDisableNext} className={classes.btn} onClick={handleNext}>
              <CustomIcon icon='arrow' color='white' viewBox='-3 -3 24 24' />
            </IconButton>
          ) : (
            <Button className={classes.year} onClick={handleNext} endIcon={<CustomIcon icon='arrow' color='white' viewBox='-4 -3 24 24' />}>
              {year + 1}
            </Button>
          )}
        </>
      ) : (
        showArrows && (
          <IconButton disabled={step === timeline.length - numItemsToShow || isDisableNext} className={classes.btn} onClick={handleNext}>
            <CustomIcon icon='arrow' color='white' viewBox='-3 -3 24 24' />
          </IconButton>
        )
      )}
    </Box>
  );
};

AnnualTimeLine.defaultProps = {
  year: undefined,
  timeline: [],
  children: null,
  isDisableNext: false,
  isDisablePrev: false,
  onIncrement: () => {},
  onDecrement: () => {},
};
