import { chartTypeTypeOptionsMap, chartTypeTypeOptions } from "@health/enum-options";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IForm } from "../types";

export const HealthParameterChartType: FC<IForm> = ({ errors, defaultValue }) => {
  const { t } = useTranslation("gcadmin");
  const { formState, control } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);

  return (
    <AutocompleteController
      ControllerProps={{
        name: "chartType",
        control: control,
        defaultValue: chartTypeTypeOptionsMap[defaultValue?.chartType!] || undefined,
      }}
      TextFieldProps={{
        placeholder: t("Chart type"),
        error: Boolean(combinedErrors?.type?.message),
        helperText: t(combinedErrors?.type?.message),
        label: t("Chart type"),
      }}
      getOptionLabel={option => t(option?.label)}
      options={chartTypeTypeOptions}
    />
  );
};
