import { TFunction } from "@toolkit/i18n";
import { CheckListIcon, useTheme } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { HealthConditionsDiagnosesList } from "./addDiagnosesToHealthCondition";
import { HealthConditionParameterList } from "./addFieldToHealthCondition";
import { healthConditionsPaths, healthConditionsRoute } from "./constants/healthConditionsPaths";
import { HealthConditionEditPage, HealthConditionNewPage } from "./form";
import { HealthConditionClone } from "./healthConditionClone";
import { HealthConditionsList } from "./list";

export const HealthConditionsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  const theme = useTheme();
  return {
    id: "risk-rules-parameters-routes",
    text: t("Health Conditions", { ns: "gcadmin" }),
    icon: (
      <CheckListIcon
        sx={{
          "& path": {
            fill: theme.palette.primary.main,
          },
        }}
      />
    ),
    route: healthConditionsRoute,
    subItems: [
      {
        id: "health-conditions-list-route",
        text: t("Health Condition", { ns: "gcadmin" }),
        route: healthConditionsPaths.listPath.route,
        fullPath: healthConditionsPaths.listPath.fullPath,
        element: <HealthConditionsList />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-conditions-new-route",
        text: t("Health Condition Templates New", { ns: "gcadmin" }),
        route: healthConditionsPaths.newPath.route,
        fullPath: healthConditionsPaths.newPath.fullPath,
        element: <HealthConditionNewPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-conditions-edit-route",
        text: t("Health Condition Templates Edit", { ns: "gcadmin" }),
        route: healthConditionsPaths.editPath.route,
        fullPath: healthConditionsPaths.editPath.fullPath,
        element: <HealthConditionEditPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-conditions-field-route",
        text: t("Health Condition Template Fields", { ns: "gcadmin" }),
        route: healthConditionsPaths.fieldPath.route,
        fullPath: healthConditionsPaths.fieldPath.fullPath,
        element: <HealthConditionParameterList />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
      {
        id: "health-conditions-diagnosis-route",
        text: t("Health Condition Diagnosis Fields", { ns: "gcadmin" }),
        route: healthConditionsPaths.diagnosisPath.route,
        fullPath: healthConditionsPaths.diagnosisPath.fullPath,
        element: <HealthConditionsDiagnosesList />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
      {
        id: "health-conditions-clone-route",
        text: t("Health Condition Templates Clone", { ns: "gcadmin" }),
        route: healthConditionsPaths.clonePath.route,
        fullPath: healthConditionsPaths.clonePath.fullPath,
        element: <HealthConditionClone />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
    ],
  };
};
