import { useCallback, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationInterventionActivitiesField } from "pages/Templates/types";
import { GuidedCareTemplateGoalRecommendationInterventionActivityServicesModalApi } from "../../modals/TemplateGoalRecommendationInterventionActivityServices/GuidedCareTemplateGoalRecommendationInterventionActivityServicesModal";
import { GuidedCareActivityType } from "@health/queries/types";
import { useNavigate } from "react-router-dom";
import { guidedCareTemplatesPaths } from "../../constants";

type GuidedCareTemplateGoalRecommendationInterventionActivitiesColumnsProps = {
  goalIndex: number;
  recommendationIndex: number;
  interventionIndex: number;
};

export const useGuidedCareTemplateGoalRecommendationInterventionActivitiesColumns = (
  props: GuidedCareTemplateGoalRecommendationInterventionActivitiesColumnsProps
): CustomTableColumnProps<IGuidedCareTemplateGoalRecommendationInterventionActivitiesField>[] => {
  const { goalIndex, recommendationIndex, interventionIndex } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onManageActivityServicesClick = useCallback(
    (activityIndex: number) => {
      GuidedCareTemplateGoalRecommendationInterventionActivityServicesModalApi.show(true, {
        goalIndex,
        recommendationIndex,
        interventionIndex,
        activityIndex,
      });
    },
    [goalIndex, interventionIndex, recommendationIndex]
  );

  const onManageActivityItemsClick = useCallback(
    (activityId: string) => {
      navigate(guidedCareTemplatesPaths.goalRecommendationInterventionActivityItemList.fullPathWithParams({ activityId }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "type",
        header: t("Type"),
        accessor: ({ templateActivityType }) => templateActivityType?.label,
      },
      {
        key: "position",
        header: t("Position"),
        accessor: ({ teamMember }) => teamMember?.label,
      },
      {
        key: "numberOfOccurrences",
        header: t("Occurrences"),
        accessor: ({ numberOfOccurrences, frequencyType }) => `${numberOfOccurrences} / ${frequencyType?.label}`,
      },
      {
        key: "services",
        header: t("Services"),
        accessor: ({ templateActivityType }, index) => (
          <Button
            variant={"contained"}
            onClick={() => onManageActivityServicesClick(index)}
            disabled={
              templateActivityType?.value !== GuidedCareActivityType.Lab && templateActivityType?.value !== GuidedCareActivityType.Rad
            }
          >
            {t("Manage")}
          </Button>
        ),
      },
      {
        key: "items",
        header: t("Items"),
        accessor: ({ activityId }) => (
          <Button onClick={() => onManageActivityItemsClick(activityId!)} disabled={!activityId}>
            {t("Manage")}
          </Button>
        ),
      },
    ];
  }, [onManageActivityItemsClick, onManageActivityServicesClick, t]);
};
