import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomDialog, PlusIcon, TextField, useModal, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useAddDiagnosesToHealthConditionByDisease } from "./useAddDiagnosesToHealthConditionByDisease";

export const AddDiagnosesByByDisease: FC = () => {
  const { t } = useTranslation("gcadmin");
  const { open, handleToggle } = useModal();
  const { handleAddDiagnosesToHealthCondition, diseases, error, onDiseasesValueChange, isSubmitting } =
    useAddDiagnosesToHealthConditionByDisease(handleToggle);
  const theme = useTheme();
  return (
    <CustomDialog
      type='base'
      maxWidth='sm'
      open={open}
      DialogTitleProps={{
        title: t("Add Diagnoses By Disease"),
        onClose: handleToggle,
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={isSubmitting} type='submit' onClick={() => handleAddDiagnosesToHealthCondition(diseases)}>
            {!!isSubmitting && <CircularProgress size={12} />} {t("Add Diagnosis")}
          </Button>
        ),
      }}
      button={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            color={"secondary"}
            onClick={handleToggle}
            sx={{ minWidth: "max-content" }}
            startIcon={<PlusIcon fill={theme.palette.common.white} />}
          >
            {t("Add Diagnoses By Disease")}
          </Button>
        </Box>
      }
      DialogContentProps={{
        sx: { minHeight: "unset" },
      }}
    >
      <TextField
        fullWidth
        value={diseases}
        onChange={onDiseasesValueChange}
        label={t("Disease Name")}
        error={Boolean(error)}
        helperText={error}
      />
    </CustomDialog>
  );
};
