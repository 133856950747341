/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthConditionOperationsFragmentFragmentDoc } from '../../fragments/__generated__/healthConditionOperationsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddHealthConditionMutationVariables = Types.Exact<{
  input: Types.HealthConditionInput;
}>;


export type AddHealthConditionMutation = { __typename?: 'Mutation', addHealthCondition?: { __typename?: 'HealthCondition', arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, disease?: string | null, display?: string | null, id?: string | null, isActive?: boolean | null, type?: Types.HealthConditionType | null, umlsCode?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null };


export const AddHealthConditionDocument = gql`
    mutation AddHealthCondition($input: HealthConditionInput!) {
  addHealthCondition(input: $input) {
    ...HealthConditionOperationsFragment
  }
}
    ${HealthConditionOperationsFragmentFragmentDoc}`;
export type AddHealthConditionMutationFn = Apollo.MutationFunction<AddHealthConditionMutation, AddHealthConditionMutationVariables>;

/**
 * __useAddHealthConditionMutation__
 *
 * To run a mutation, you first call `useAddHealthConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHealthConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHealthConditionMutation, { data, loading, error }] = useAddHealthConditionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHealthConditionMutation(baseOptions?: Apollo.MutationHookOptions<AddHealthConditionMutation, AddHealthConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHealthConditionMutation, AddHealthConditionMutationVariables>(AddHealthConditionDocument, options);
      }
export type AddHealthConditionMutationHookResult = ReturnType<typeof useAddHealthConditionMutation>;
export type AddHealthConditionMutationResult = Apollo.MutationResult<AddHealthConditionMutation>;
export type AddHealthConditionMutationOptions = Apollo.BaseMutationOptions<AddHealthConditionMutation, AddHealthConditionMutationVariables>;