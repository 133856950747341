import { replacePathParams } from "utils";

export const riskFactorTemplatesRoute = `/risk-factor-templates`;
const riskFactorTemplatesNewRoute = "new";
const riskFactorTemplatesEditRoute = "edit/:id";
const riskFactorTemplatesCloneRoute = "clone/:id";
const riskFactorTemplatesFieldRoute = "field/:id";

export const riskFactorTemplatesPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${riskFactorTemplatesRoute}${this.route}`;
    },
  },
  newPath: {
    route: riskFactorTemplatesNewRoute,
    get fullPath() {
      return `${riskFactorTemplatesRoute}/${this.route}`;
    },
  },
  editPath: {
    route: riskFactorTemplatesEditRoute,
    get fullPath() {
      return `${riskFactorTemplatesRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  clonePath: {
    route: riskFactorTemplatesCloneRoute,
    get fullPath() {
      return `${riskFactorTemplatesRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  fieldPath: {
    route: riskFactorTemplatesFieldRoute,
    get fullPath() {
      return `${riskFactorTemplatesRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
