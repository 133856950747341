import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useGridContext } from "@toolkit/ui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAddDiagnosisToHealthConditionMutation } from "../gql";

export const useAddDiagnosesToHealthCondition = (handleToggle?) => {
  const { t } = useTranslation("gcadmin");
  const params = useParams();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);
  const { customTableProps } = useGridContext();
  const [addDiagnosesToHealthCondition, { loading: loadingAdd }] = useAddDiagnosisToHealthConditionMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.addDiagnosisToHealthCondition?.[0]?.errors || [];
      if (mutationErrors?.length === 0) {
        customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
        succeeded(t("Diagnoses Added Successfully"));
        handleToggle();
      } else {
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleAddDiagnosesToHealthCondition = diagnosisCode => {
    addDiagnosesToHealthCondition({
      variables: {
        diagnosisCode: diagnosisCode,
        healthConditionId: params.id!,
      },
    });
  };

  return {
    errors,
    handleAddDiagnosesToHealthCondition,
    isSubmitting: loadingAdd,
  };
};
