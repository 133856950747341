import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAddDiagnosesToHealthCondition } from "./useAddDiagnosesToHealthCondition";

export const AddFields: FC<{ open: boolean; onToggle: () => void }> = ({ open, onToggle: handleToggle }) => {
  const { t } = useTranslation("gcadmin");

  const { handleAddDiagnosesToHealthCondition, isSubmitting } = useAddDiagnosesToHealthCondition(handleToggle);

  const methods = useForm({
    mode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmit = input => {
    handleAddDiagnosesToHealthCondition(input?.diagnose?.value?.code);
  };

  return (
    <FormProvider {...methods}>
      <CustomDialog
        type='base'
        maxWidth='sm'
        open={open}
        DialogActionsProps={{
          children: (
            <Button disabled={isSubmitting} type='submit' onClick={handleSubmit(onSubmit)}>
              {t("Add Diagnosis")}
            </Button>
          ),
        }}
        button={
          <Button variant='outlined' onClick={handleToggle}>
            {t("Diagnosis")}
          </Button>
        }
        DialogContentProps={{
          sx: { minHeight: "unset" },
        }}
        DialogTitleProps={{
          title: t(t("Diagnoses")),
          onClose: handleToggle,
        }}
      >
        <SystemCodeAutocomplete name={"diagnose"} label={t("Diagnose")} filter={{ codeSystemCode: CodeSystemCode.Diagnosis }} />
      </CustomDialog>
    </FormProvider>
  );
};
