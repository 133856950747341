/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthConditionDiagnosisFragmentFragmentDoc } from '../../fragments/__generated__/healthConditionDiagnosisFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteHealthConditionDiagnosisMutationVariables = Types.Exact<{
  deleteHealthConditionDiagnosisId: Types.Scalars['ID'];
}>;


export type DeleteHealthConditionDiagnosisMutation = { __typename?: 'Mutation', deleteHealthConditionDiagnosis?: { __typename?: 'HealthConditionDiagnosis', id?: string | null, diagnosisCode?: string | null, diagnosisDisplay?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null };


export const DeleteHealthConditionDiagnosisDocument = gql`
    mutation DeleteHealthConditionDiagnosis($deleteHealthConditionDiagnosisId: ID!) {
  deleteHealthConditionDiagnosis(id: $deleteHealthConditionDiagnosisId) {
    ...healthConditionDiagnosisFragment
    errors {
      code
      errorType
      field
      message
    }
  }
}
    ${HealthConditionDiagnosisFragmentFragmentDoc}`;
export type DeleteHealthConditionDiagnosisMutationFn = Apollo.MutationFunction<DeleteHealthConditionDiagnosisMutation, DeleteHealthConditionDiagnosisMutationVariables>;

/**
 * __useDeleteHealthConditionDiagnosisMutation__
 *
 * To run a mutation, you first call `useDeleteHealthConditionDiagnosisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHealthConditionDiagnosisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHealthConditionDiagnosisMutation, { data, loading, error }] = useDeleteHealthConditionDiagnosisMutation({
 *   variables: {
 *      deleteHealthConditionDiagnosisId: // value for 'deleteHealthConditionDiagnosisId'
 *   },
 * });
 */
export function useDeleteHealthConditionDiagnosisMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHealthConditionDiagnosisMutation, DeleteHealthConditionDiagnosisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHealthConditionDiagnosisMutation, DeleteHealthConditionDiagnosisMutationVariables>(DeleteHealthConditionDiagnosisDocument, options);
      }
export type DeleteHealthConditionDiagnosisMutationHookResult = ReturnType<typeof useDeleteHealthConditionDiagnosisMutation>;
export type DeleteHealthConditionDiagnosisMutationResult = Apollo.MutationResult<DeleteHealthConditionDiagnosisMutation>;
export type DeleteHealthConditionDiagnosisMutationOptions = Apollo.BaseMutationOptions<DeleteHealthConditionDiagnosisMutation, DeleteHealthConditionDiagnosisMutationVariables>;