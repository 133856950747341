/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/PageInfo';
import { HealthConditionFragmentFragmentDoc } from '../../fragments/__generated__/healthConditionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHealthConditionsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthConditionFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.HealthConditionSortingInput>;
}>;


export type GetHealthConditionsQuery = { __typename?: 'Query', getHealthConditions?: { __typename?: 'HealthConditioneConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges?: Array<{ __typename?: 'HealthConditionEdge', node?: { __typename?: 'HealthCondition', arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, disease?: string | null, display?: string | null, id?: string | null, isActive?: boolean | null, type?: Types.HealthConditionType | null, umlsCode?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null> | null } | null } | null> | null } | null };


export const GetHealthConditionsDocument = gql`
    query GetHealthConditions($after: String, $before: String, $filter: HealthConditionFilterInput, $first: Int, $last: Int, $sortBy: HealthConditionSortingInput) {
  getHealthConditions(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        ...HealthConditionFragment
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${HealthConditionFragmentFragmentDoc}`;

/**
 * __useGetHealthConditionsQuery__
 *
 * To run a query within a React component, call `useGetHealthConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthConditionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetHealthConditionsQuery(baseOptions?: Apollo.QueryHookOptions<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>(GetHealthConditionsDocument, options);
      }
export function useGetHealthConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>(GetHealthConditionsDocument, options);
        }
export type GetHealthConditionsQueryHookResult = ReturnType<typeof useGetHealthConditionsQuery>;
export type GetHealthConditionsLazyQueryHookResult = ReturnType<typeof useGetHealthConditionsLazyQuery>;
export type GetHealthConditionsQueryResult = Apollo.QueryResult<GetHealthConditionsQuery, GetHealthConditionsQueryVariables>;