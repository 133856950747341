/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthConditionFragmentFragmentDoc } from '../../fragments/__generated__/healthConditionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHealthConditionMutationVariables = Types.Exact<{
  updateHealthConditionId: Types.Scalars['ID'];
  input: Types.HealthConditionUpdateInput;
}>;


export type UpdateHealthConditionMutation = { __typename?: 'Mutation', updateHealthCondition?: { __typename?: 'HealthCondition', arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, disease?: string | null, display?: string | null, id?: string | null, isActive?: boolean | null, type?: Types.HealthConditionType | null, umlsCode?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null> | null } | null };


export const UpdateHealthConditionDocument = gql`
    mutation UpdateHealthCondition($updateHealthConditionId: ID!, $input: HealthConditionUpdateInput!) {
  updateHealthCondition(id: $updateHealthConditionId, input: $input) {
    ...HealthConditionFragment
  }
}
    ${HealthConditionFragmentFragmentDoc}`;
export type UpdateHealthConditionMutationFn = Apollo.MutationFunction<UpdateHealthConditionMutation, UpdateHealthConditionMutationVariables>;

/**
 * __useUpdateHealthConditionMutation__
 *
 * To run a mutation, you first call `useUpdateHealthConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthConditionMutation, { data, loading, error }] = useUpdateHealthConditionMutation({
 *   variables: {
 *      updateHealthConditionId: // value for 'updateHealthConditionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHealthConditionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHealthConditionMutation, UpdateHealthConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHealthConditionMutation, UpdateHealthConditionMutationVariables>(UpdateHealthConditionDocument, options);
      }
export type UpdateHealthConditionMutationHookResult = ReturnType<typeof useUpdateHealthConditionMutation>;
export type UpdateHealthConditionMutationResult = Apollo.MutationResult<UpdateHealthConditionMutation>;
export type UpdateHealthConditionMutationOptions = Apollo.BaseMutationOptions<UpdateHealthConditionMutation, UpdateHealthConditionMutationVariables>;