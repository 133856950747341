/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthConditionFragmentFragmentDoc } from '../../fragments/__generated__/healthConditionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DuplicateHealthConditionMutationVariables = Types.Exact<{
  duplicateHealthConditionId: Types.Scalars['ID'];
  input: Types.HealthConditionInput;
}>;


export type DuplicateHealthConditionMutation = { __typename?: 'Mutation', duplicateHealthCondition?: { __typename?: 'HealthCondition', arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, disease?: string | null, display?: string | null, id?: string | null, isActive?: boolean | null, type?: Types.HealthConditionType | null, umlsCode?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null> | null } | null };


export const DuplicateHealthConditionDocument = gql`
    mutation DuplicateHealthCondition($duplicateHealthConditionId: ID!, $input: HealthConditionInput!) {
  duplicateHealthCondition(id: $duplicateHealthConditionId, input: $input) {
    ...HealthConditionFragment
  }
}
    ${HealthConditionFragmentFragmentDoc}`;
export type DuplicateHealthConditionMutationFn = Apollo.MutationFunction<DuplicateHealthConditionMutation, DuplicateHealthConditionMutationVariables>;

/**
 * __useDuplicateHealthConditionMutation__
 *
 * To run a mutation, you first call `useDuplicateHealthConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateHealthConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateHealthConditionMutation, { data, loading, error }] = useDuplicateHealthConditionMutation({
 *   variables: {
 *      duplicateHealthConditionId: // value for 'duplicateHealthConditionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateHealthConditionMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateHealthConditionMutation, DuplicateHealthConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateHealthConditionMutation, DuplicateHealthConditionMutationVariables>(DuplicateHealthConditionDocument, options);
      }
export type DuplicateHealthConditionMutationHookResult = ReturnType<typeof useDuplicateHealthConditionMutation>;
export type DuplicateHealthConditionMutationResult = Apollo.MutationResult<DuplicateHealthConditionMutation>;
export type DuplicateHealthConditionMutationOptions = Apollo.BaseMutationOptions<DuplicateHealthConditionMutation, DuplicateHealthConditionMutationVariables>;