import { useBreadCrumbs } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { ellipseName } from "utils";
import { HealthConditionForm } from "../HealthConditionForm";
import { useHealthConditionEdit } from "./useHealthConditionEdit";
import {
  healthConditionsEditBreadcrumb,
  healthConditionsListBreadcrumb,
  healthConditionsTitleBreadcrumb,
} from "pages/HealthConditions/constants/healthConditionsBreadcrumbs";

export const HealthConditionEditPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, loading, healthCondition, handleSubmit, isSubmitting } = useHealthConditionEdit();

  useEffect(() => {
    if (healthCondition?.display) {
      setBreadCrumb({
        title: healthConditionsTitleBreadcrumb(),
        values: [healthConditionsListBreadcrumb(), healthConditionsEditBreadcrumb(ellipseName(healthCondition.display, 50))],
      });
    }
  }, [healthCondition?.display]);

  return (
    <HealthConditionForm
      loading={loading}
      healthCondition={healthCondition!}
      errors={errors}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};
