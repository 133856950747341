/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { LabTemplateFragmentDoc } from '../../fragments/__generated__/LabTemplate';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLabTemplateQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetLabTemplateQuery = { __typename?: 'Query', getLabTemplate?: { __typename?: 'LabTemplate', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, createdDate?: string | null, isActive?: boolean | null, labCodeDisplay?: string | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, display?: string | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null> | null } | null };


export const GetLabTemplateDocument = gql`
    query getLabTemplate($id: ID!) {
  getLabTemplate(id: $id) {
    ...LabTemplate
  }
}
    ${LabTemplateFragmentDoc}`;

/**
 * __useGetLabTemplateQuery__
 *
 * To run a query within a React component, call `useGetLabTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLabTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetLabTemplateQuery, GetLabTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabTemplateQuery, GetLabTemplateQueryVariables>(GetLabTemplateDocument, options);
      }
export function useGetLabTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabTemplateQuery, GetLabTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabTemplateQuery, GetLabTemplateQueryVariables>(GetLabTemplateDocument, options);
        }
export type GetLabTemplateQueryHookResult = ReturnType<typeof useGetLabTemplateQuery>;
export type GetLabTemplateLazyQueryHookResult = ReturnType<typeof useGetLabTemplateLazyQuery>;
export type GetLabTemplateQueryResult = Apollo.QueryResult<GetLabTemplateQuery, GetLabTemplateQueryVariables>;