/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { articlesPaths, articlesRoute } from "./ArticlesPaths";
import { ArticleCategoriesList } from "../containers/ArticlesList/ArticlesListPage";
import { ArticleUpdateContainer } from "../containers/ArticleUpdate/ArticleUpdateContainer";
import { ArticleCreateContainer } from "../containers/ArticleCerate/ArticleCreateContainer";

export const articlesRoutes: (props: { navigate: NavigateFunction; enabled: boolean }) => RouteItem = ({ navigate, enabled }) => {
  return {
    id: "articles",
    text: i18n.t("Articles", { ns: "gcadmin" }),
    route: articlesRoute,
    fullPath: articlesPaths.main.fullPath,
    onClick: route => navigate(route),
    subItems: [
      {
        id: "article-main",
        text: i18n.t("Articles", { ns: "gcadmin" }),
        route: articlesPaths.main.route,
        fullPath: articlesPaths.main.fullPath,
        element: <ArticleCategoriesList />,
        hidden: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "article-new",
        text: i18n.t("New", { ns: "gcadmin" }),
        element: <ArticleCreateContainer />,
        route: articlesPaths.new.route,
        fullPath: articlesPaths.new.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "article-update",
        text: i18n.t("Update", { ns: "gcadmin" }),
        element: <ArticleUpdateContainer />,
        route: articlesPaths.update.route,
        fullPath: articlesPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
    ],
  };
};
