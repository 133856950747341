/* eslint-disable max-lines */
import { isEqual } from "lodash";
import {
  HealthProgramTemplate,
  HealthProgramTemplateInput,
  TemplateActivityInput,
  TemplateGoalInput,
  TemplateInterventionActivityInput,
  TemplateTeamMemberInput,
} from "@health/queries/types";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { IGuidedCareTemplateUpsertFormValues } from "../forms/TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { IGuidedCareTemplateActivityUpsertFormValues } from "../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues } from "../forms/TemplateGoalRecommendationInterventionActivityUpsert/GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormSchema";
import { IGuidedCareTemplateGoalUpsertFormValues } from "../forms/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";

export const convertTemplateFormValuesToBackEndValues = (
  values: IGuidedCareTemplateUpsertFormValues,
  template?: HealthProgramTemplate
): HealthProgramTemplateInput => {
  return {
    name: values.name,
    guidedCareType: values.guidedCareType?.value,
    isRenewable: values.isRenewable,
    isVerified: values.isVerified,
    standardCode: values.standardCode,
    standardName: values.standardName,
    standardURL: values.standardURL,
    templateDuration: values.templateDuration,
    healthConditionId: values.healthCondition?.value?.id || undefined,
    riskFactorTemplateId: values.riskFactorTemplate?.value?.id || undefined,
    templateTeamMembers: convertTemplateTeamMembersFormValuesToBackEndValues(values?.teamMembers),
    templateActivities: convertTemplateActivitiesFormValuesToBackEndValues(values?.activities, values.templateDuration, template),
    templateGoals: convertTemplateGoalsFormValuesToBackEndValues(values?.goals, values?.templateDuration, template),
  };
};

const convertTemplateTeamMembersFormValuesToBackEndValues = (
  teamMembers: IGuidedCareTemplateMemberUpsertFormValues[]
): TemplateTeamMemberInput[] => {
  return (
    teamMembers?.map(item => {
      return {
        id: item?.teamMemberId!,
        uniqueId: item.uniqueId!,
        position: item.position?.value,
        specialization: item?.specialization?.value?.code,
        isLicencedHealthProfessional: item?.isLicencedHealthProfessional,
        isBackupPersonMandatory: item?.isBackupPersonMandatory,
        isKeyTeamMember: item?.isKeyTeamMember,
      };
    }) || []
  );
};

const convertTemplateActivitiesFormValuesToBackEndValues = (
  activities: IGuidedCareTemplateActivityUpsertFormValues[],
  newTemplateDuration: number,
  template?: HealthProgramTemplate
): TemplateActivityInput[] => {
  return activities?.map(item => ({
    id: item?.activityId,
    numberOfOccurrences: item?.numberOfOccurrences,
    templateTeamMember: {
      uniqueId: item?.teamMember?.value?.uniqueId!,
    },
    templateActivityType: item?.templateActivityType.value,
    frequencyType: item?.frequencyType?.value,
    medicalFormId: item?.medicalForm?.value?.id,
    medicalMessageId: item?.medicalMessage?.value?.id,
    beforeCallMessageId: item?.beforeActivity?.value?.id,
    beforeCallMessageOffsetDays: item?.beforeActivityOffset,
    afterCallMessageId: item?.afterActivity?.value?.id,
    afterCallMessageOffsetDays: item?.afterActivityOffset,
    medicalFormClassificationToInterventionLinkList: item?.medicalFormClassificationsInterventions?.map(classificationIntervention => ({
      id: classificationIntervention?.id,
      classification: classificationIntervention?.classification,
      intervention: classificationIntervention?.intervention?.value?.uniqueId
        ? { uniqueId: classificationIntervention?.intervention?.value?.uniqueId! }
        : undefined,
    })),
    serviceOfHealthProgramTemplateDetails: item.activityServices?.map(service => ({
      id: service?.serviceId,
      serviceCode: service.service?.value.code!,
      serviceName: service.service?.value.display!,
      standard: service.standard?.value!,
    })),
    isModified: checkIfTemplateActivityIsModified(item, newTemplateDuration, template),
  }));
};

const checkIfTemplateActivityIsModified = (
  item: IGuidedCareTemplateActivityUpsertFormValues,
  newTemplateDuration: number,
  template?: HealthProgramTemplate
): boolean => {
  if (!template) return false;

  const existingItem = template.templateActivities?.find(oldItem => oldItem?.id === item?.activityId);
  const isNewItem = !existingItem;

  const isExistingItemModified =
    existingItem &&
    (!isEqual(existingItem.numberOfOccurrences, item.numberOfOccurrences) || !isEqual(existingItem.frequencyType, item.frequencyType));

  return isNewItem || isExistingItemModified || template.templateDuration !== newTemplateDuration;
};

const convertTemplateGoalsFormValuesToBackEndValues = (
  goals: IGuidedCareTemplateGoalUpsertFormValues[],
  newTemplateDuration: number,
  template?: HealthProgramTemplate
): TemplateGoalInput[] => {
  return goals.map(goal => ({
    id: goal?.uniqueId,
    name: goal?.name,
    nameAr: goal?.nameAr! || "",
    target: goal?.target,
    description: goal?.description,
    descriptionAr: goal?.descriptionAr,
    templateRecommendations: goal?.recommendations?.map(recommendation => ({
      id: recommendation?.uniqueId,
      name: recommendation?.name,
      nameAr: recommendation?.nameAr,
      code: recommendation?.code,
      description: recommendation?.description,
      descriptionAr: recommendation?.descriptionAr,
      templateInterventions: recommendation?.interventions?.map(intervention => ({
        id: intervention?.interventionId!,
        uniqueId: intervention?.uniqueId!,
        name: intervention?.name,
        nameAr: intervention?.nameAr,
        duration: intervention?.duration,
        description: intervention?.description,
        descriptionAr: intervention?.descriptionAr,
        interventionTemplateActivities: convertTemplateGoalRecommendationInterventionActivitiesFormValuesToBackEndValues(
          intervention?.activities,
          goal?.uniqueId!,
          recommendation?.uniqueId!,
          intervention?.uniqueId!,
          newTemplateDuration,
          intervention?.duration,
          template
        ),
      })),
    })),
  }));
};

const convertTemplateGoalRecommendationInterventionActivitiesFormValuesToBackEndValues = (
  activities: IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues[],
  goalId: string,
  recommendationId: string,
  interventionId: string,
  newTemplateDuration: number,
  newInterventionDuration: number,
  template?: HealthProgramTemplate
): TemplateInterventionActivityInput[] => {
  return activities?.map(item => ({
    id: item?.activityId,
    numberOfOccurrences: item?.numberOfOccurrences,
    templateTeamMember: {
      uniqueId: item?.teamMember?.value?.uniqueId!,
    },
    templateActivityType: item?.templateActivityType.value,
    frequencyType: item?.frequencyType?.value,
    medicalFormId: item?.medicalForm?.value?.id,
    medicalMessageId: item?.medicalMessage?.value?.id,
    beforeCallMessageId: item?.beforeActivity?.value?.id,
    beforeCallMessageOffsetDays: item?.beforeActivityOffset,
    afterCallMessageId: item?.afterActivity?.value?.id,
    afterCallMessageOffsetDays: item?.afterActivityOffset,
    // eslint-disable-next-line sonarjs/no-identical-functions
    serviceOfInterventionActivityDetails: item.activityServices?.map(service => ({
      id: service?.serviceId,
      serviceCode: service.service?.value.code!,
      serviceName: service.service?.value.display!,
      standard: service.standard?.value!,
    })),
    isModified: checkIfTemplateGoalRecommendationInterventionActivityIsModified(
      item,
      goalId,
      recommendationId,
      interventionId,
      newTemplateDuration,
      newInterventionDuration,
      template
    ),
  }));
};

const checkIfTemplateGoalRecommendationInterventionActivityIsModified = (
  item: IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues,
  goalId: string,
  recommendationId: string,
  interventionId: string,
  newTemplateDuration: number,
  newInterventionDuration: number,
  template?: HealthProgramTemplate
): boolean => {
  if (!template) return false;

  const existingIntervention = template.templateGoals
    ?.find(_goal => _goal?.id === goalId)
    ?.templateRecommendations?.find(_recommendation => _recommendation?.id === recommendationId)
    ?.templateInterventions?.find(_intervention => _intervention?.id === interventionId);

  const existingItem = existingIntervention?.interventionTemplateActivities?.find(oldItem => oldItem?.id === item?.activityId);

  const isNewItem = !existingItem;

  const isExistingItemModified =
    existingItem &&
    (!isEqual(existingItem.numberOfOccurrences, item.numberOfOccurrences) || !isEqual(existingItem.frequencyType, item.frequencyType));

  return (
    isNewItem ||
    isExistingItemModified ||
    template.templateDuration !== newTemplateDuration ||
    existingIntervention?.duration !== newInterventionDuration
  );
};
