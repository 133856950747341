import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateActivityUpsertFormEvent } from "pages/Templates/types";
import { FC, MouseEvent, useRef } from "react";
import {
  GuidedCareTemplateActivityUpsertForm,
  GuidedCareTemplateActivityUpsertFormRef,
} from "../../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertForm";
import { IGuidedCareTemplateActivityUpsertFormValues } from "../../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { IGuidedCareTemplateGoalUpsertFormValues } from "../../forms/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";

type GuidedCareTemplateActivityUpsertModalProps = {
  teamMembers: IGuidedCareTemplateMemberUpsertFormValues[];
  activity?: IGuidedCareTemplateActivityUpsertFormValues;
  goals: IGuidedCareTemplateGoalUpsertFormValues[];
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: IGuidedCareTemplateActivityUpsertFormEvent) => void;
};

export const GuidedCareTemplateActivityUpsertModal: FC<GuidedCareTemplateActivityUpsertModalProps> = props => {
  const { teamMembers, activity, goals, isOpen, onChange, onClose } = props;

  const { t } = useTranslation("gcadmin");
  const activityUpsertFormRef = useRef<GuidedCareTemplateActivityUpsertFormRef>(null);

  const onButtonClick = () => {
    activityUpsertFormRef.current?.submit();
  };

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: activity ? t("Update Activity") : t("Create Activity"),
        onClose,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: activity ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateActivityUpsertForm
        teamMembers={teamMembers}
        activity={activity}
        goals={goals}
        onChange={onChange}
        ref={activityUpsertFormRef}
      />
    </CustomDialog>
  );
};
