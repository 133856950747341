/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthParameterFragmentDoc } from '../../../../../../HealthParameters/Parameters/gql/fragments/__generated__/HealthParameter';
export type RiskFactorTemplateFragmentFragment = { __typename?: 'RiskFactorTemplate', id?: string | null, isActive?: boolean | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, fields?: Array<{ __typename?: 'HealthParameter', createdDate?: string | null, allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null> | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', message?: string | null, field?: string | null, errorType?: Types.ErrorType | null, code?: Types.DecisionMakerErrorCodes | null } | null> | null };

export const RiskFactorTemplateFragmentFragmentDoc = gql`
    fragment RiskFactorTemplateFragment on RiskFactorTemplate {
  id
  isActive
  fields {
    createdDate
    ...HealthParameter
  }
  code
  display
  arabicDisplay
  errors {
    message
    field
    errorType
    code
  }
}
    ${HealthParameterFragmentDoc}`;