import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { GuidedCareTemplateGoalRecommendationInterventionActivityServicesForm } from "../../forms/TemplateGoalRecommendationInterventionActivityServices/GuidedCareTemplateGoalRecommendationInterventionActivityServicesForm";

type IGuidedCareTemplateGoalRecommendationInterventionActivityServicesModalShowOptions = {
  activityIndex: number;
  goalIndex: number;
  recommendationIndex: number;
  interventionIndex: number;
};

type IShow = (show: boolean, showOptions?: IGuidedCareTemplateGoalRecommendationInterventionActivityServicesModalShowOptions) => void;

export const GuidedCareTemplateGoalRecommendationInterventionActivityServicesModalApi: { show: IShow } = {
  show: () => {},
};

export const GuidedCareTemplateGoalRecommendationInterventionActivityServicesModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState<IGuidedCareTemplateGoalRecommendationInterventionActivityServicesModalShowOptions>();

  const { t } = useTranslation("gcadmin");

  const show: IShow = (open, _showOptions) => {
    setIsOpen(open);
    setShowOptions(_showOptions);
  };

  useEffect(() => {
    GuidedCareTemplateGoalRecommendationInterventionActivityServicesModalApi.show = show;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Activity Services"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <GuidedCareTemplateGoalRecommendationInterventionActivityServicesForm
        goalIndex={showOptions?.goalIndex!}
        recommendationIndex={showOptions?.recommendationIndex!}
        interventionIndex={showOptions?.interventionIndex!}
        activityIndex={showOptions?.activityIndex!}
      />
    </CustomDialog>
  );
};
