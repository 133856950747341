/* eslint-disable max-lines */
import { healthConditionTypeOptions } from "@health/enum-options";
import { HealthCondition, HealthConditionSortingField, SortDirection } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomIcon, CustomTableColumnProps, ShowButton, TruncateTypography, formatDate } from "@toolkit/ui";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getActiveInActiveFilter } from "shared/components";
import { convertToTitleCase } from "utils";
import { healthConditionsPaths } from "../../constants/healthConditionsPaths";
import { HealthConditionToggleButton, UpdateRequiredInput } from "./HealthConditionToggleButton.component";

export type HealthConditionsColumnTypes = CustomTableColumnProps<HealthCondition>;

export const useHealthConditionsColumns = (): HealthConditionsColumnTypes[] => {
  const { t, i18n } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const handleShowClick = id => () => {
    navigate(healthConditionsPaths.fieldPath.fullPathWithParams(id));
  };

  const handleClone = id => () => {
    navigate(healthConditionsPaths.clonePath.fullPathWithParams(id));
  };

  const handleShowDiagnosisClick = id => () => {
    navigate(healthConditionsPaths.diagnosisPath.fullPathWithParams(id));
  };

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: ({ code }) => <TruncateTypography text={code || "-"} />,
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthConditionSortingField.Code,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "display",
        header: t("risk-display"),
        accessor: ({ display, arabicDisplay }) => (
          <TruncateTypography text={(i18n.language === "ar" ? arabicDisplay || display : display) || "-"} />
        ),
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: i18n.language === "ar" ? HealthConditionSortingField.ArabicDisplay : HealthConditionSortingField.Display,
        filter: {
          type: "string",
          name: i18n.language === "ar" ? "arabicDisplay" : "display",
        },
      },
      {
        key: "ArabicDisplay",
        header: t("risk-arabic-display"),
        type: "string",
        filter: {
          type: "string",
          name: "arabicDisplay",
        },
        showOnlyForFilterField: true,
        hideFromSettings: true,
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => t(convertToTitleCase(type || "-")),
        type: "string",
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: HealthConditionSortingField.Type,
        filter: {
          type: "autocomplete",
          name: "type",
          options: healthConditionTypeOptions || [],
          getOptionLabel: option => option?.label,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "disease",
        header: t("Disease"),
        accessor: ({ disease }) => <TruncateTypography text={disease || "-"} />,
        type: "string",
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: HealthConditionSortingField.Disease,
        filter: {
          type: "string",
          name: "disease",
        },
      },
      {
        key: "umlsCode",
        header: t("UMLS Code"),
        accessor: ({ umlsCode }) => <TruncateTypography text={umlsCode || "-"} />,
        type: "string",
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthConditionSortingField.CreatedDate,
      },
      {
        key: "Parameters",
        header: t("Parameters"),
        accessor: ({ id }) => <ShowButton variant='text' type='submit' onClick={handleShowClick(id)} />,
        type: "string",
      },
      {
        key: "diagnosis",
        header: t("Diagnosis"),
        accessor: ({ id }) => <ShowButton variant='text' type='submit' onClick={handleShowDiagnosisClick(id)} />,
        type: "string",
      },
      {
        key: "Clone",
        header: t("Clone"),
        accessor: ({ id }) => (
          <Button startIcon={<CustomIcon icon='clone' />} variant='text' type='submit' onClick={handleClone(id)}>
            {t("Clone")}
          </Button>
        ),
        type: "string",
      },
      {
        key: "isActive",
        header: t("Active"),
        type: "string",
        accessor: ({ arabicDisplay, type, disease, display, umlsCode, isActive, id }) => {
          return (
            <HealthConditionToggleButton
              id={id as string}
              isDisabled={false}
              updateRequiredInput={
                {
                  umlsCode,
                  type,
                  disease,
                  arabicDisplay,
                  display,
                } as UpdateRequiredInput
              }
              isActive={Boolean(isActive)}
            />
          );
        },
        filter: {
          ...getActiveInActiveFilter(t),
        },
      },
    ];
  }, []);
};
