import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  GuidedCareTemplateActivityItemsUpdateMutation,
  useGuidedCareTemplateGoalRecommendationInterventionActivityGetQuery,
  useGuidedCareTemplateGoalRecommendationInterventionActivityItemsUpdateMutation,
} from "pages/Templates/gql";
import { useGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { convertActivityItemsFormValuesToBackEndValues } from "pages/Templates/others";
import {
  IGuidedCareTemplateGoalRecommendationInterventionActivityItemListContainerParams,
  IGuidedCareTemplateGoalRecommendationInterventionActivityItemsFormEvent,
} from "pages/Templates/types";
import { GuidedCareTemplateGoalRecommendationInterventionActivityItemsForm } from "../../forms/TemplateGoalRecommendationInterventionActivityItems/GuidedCareTemplateGoalRecommendationInterventionActivityItemsForm";
import { TemplateInterventionActivity } from "@health/queries/types/types";

export const GuidedCareTemplateGoalRecommendationInterventionActivityItemListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();

  const { setGuidedCareTemplateGoalRecommendationInterventionActivityItemListContainerBreadcrumb } = useGuidedCareTemplatesBreadcrumbs();

  const { activityId } = useParams<IGuidedCareTemplateGoalRecommendationInterventionActivityItemListContainerParams>();

  const { data, loading, refetch } = useGuidedCareTemplateGoalRecommendationInterventionActivityGetQuery({
    variables: {
      activityId: activityId!,
    },
  });

  const templateInterventionActivity = data?.templateInterventionActivity as TemplateInterventionActivity;

  const [updateGuidedCareTemplateGoalRecommendationInterventionActivityItems, { loading: isSubmitting }] =
    useGuidedCareTemplateGoalRecommendationInterventionActivityItemsUpdateMutation({
      onCompleted: (mutationData: GuidedCareTemplateActivityItemsUpdateMutation) => {
        const errors = mutationData?.updateTemplateActivityItems?.templateActivityErrors;

        if (errors?.length) {
          failed(formatMessageErrors(errors));
        } else {
          succeeded(t("Activity items have been updated successfully"));
        }
      },
      onError: ({ graphQLErrors }) => {
        failed(formatGraphQLError(graphQLErrors));
      },
    });

  const onGuidedCareTemplateGoalRecommendationInterventionActivityItemsFormChange = (
    event: IGuidedCareTemplateGoalRecommendationInterventionActivityItemsFormEvent
  ) => {
    if (event.type === "SUBMIT") {
      updateGuidedCareTemplateGoalRecommendationInterventionActivityItems({
        variables: {
          activityId: activityId!,
          activityItems: convertActivityItemsFormValuesToBackEndValues(event.payload.values.activityItems),
        },
      }).then(() => refetch());
    }
  };

  useEffect(() => {
    setGuidedCareTemplateGoalRecommendationInterventionActivityItemListContainerBreadcrumb();
  }, [setGuidedCareTemplateGoalRecommendationInterventionActivityItemListContainerBreadcrumb]);

  return (
    <GuidedCareTemplateGoalRecommendationInterventionActivityItemsForm
      activity={templateInterventionActivity}
      isLoading={loading || isSubmitting}
      onChange={onGuidedCareTemplateGoalRecommendationInterventionActivityItemsFormChange}
    />
  );
};
