import { HealthConditionUpdateInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { UpdateHealthConditionMutation, useUpdateHealthConditionMutation } from "pages/HealthConditions/gql";
import { FC, useState } from "react";
export type UpdateRequiredInput = Omit<HealthConditionUpdateInput, "isActive">;
type ActiveToggleProps = {
  id: string;
  isActive: boolean;
  isDisabled?: boolean;
  updateRequiredInput: UpdateRequiredInput;
};

export const HealthConditionToggleButton: FC<ActiveToggleProps> = ({ id, isActive, isDisabled = false, updateRequiredInput }) => {
  const { t } = useTranslation("gcadmin");
  const [checked, setChecked] = useState(isActive);
  const { succeeded, failed } = useAddToast();

  const [fetchHealthConditionUpdate, { loading }] = useUpdateHealthConditionMutation({
    onCompleted: (data: UpdateHealthConditionMutation) => {
      const errors = data?.updateHealthCondition?.errors;
      if (errors && errors.length > 0) {
        const error = formatMessageErrors(errors);
        failed(t(error));
      } else {
        setChecked(!!data?.updateHealthCondition?.isActive);
        succeeded(t("Health Condition Updated Successfully"));
      }
    },
    onError: () => {
      failed(t("Health Condition Update Failed"));
    },
  });

  const handleChangeActivity = event => {
    const isChecked: boolean = event.target.checked;
    fetchHealthConditionUpdate({
      variables: {
        updateHealthConditionId: id,
        input: {
          isActive: isChecked,
          ...updateRequiredInput,
        },
      },
    });
  };
  return <CustomToggleButton disabled={isDisabled} checked={checked} onChange={handleChangeActivity} isLoading={loading} />;
};
