import { useBreadCrumbs } from "@toolkit/ui";
import {
  riskFactorTemplateFieldBreadcrumb,
  riskFactorTemplateListBreadcrumb,
  riskFactorTemplatesTitleBreadcrumb,
} from "../../../constants/riskFactorTemplatesBreadcrumbs";
import React, { FC, useEffect } from "react";
import { ellipseName } from "utils";
import { RiskFactorTemplateForm } from "../RiskFactorTemplateForm";
import { useRiskFactorTemplateEdit } from "./useRiskFactorTemplateEdit";

export const RiskFactorTemplateEditPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, loading, riskTemplate, handleSubmit, isSubmitting } = useRiskFactorTemplateEdit();

  useEffect(() => {
    if (riskTemplate?.display) {
      setBreadCrumb({
        title: riskFactorTemplatesTitleBreadcrumb(),
        values: [riskFactorTemplateListBreadcrumb(), riskFactorTemplateFieldBreadcrumb(ellipseName(riskTemplate.display, 50))],
      });
    }
  }, [riskTemplate?.display]);

  return (
    <RiskFactorTemplateForm
      loading={loading}
      riskTemplate={riskTemplate!}
      errors={errors}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
};
