import { uniqueId } from "lodash";
import { z } from "zod";
import { zodSchema } from "@health/domains";
import { guidedCareTeamMemberPositionOptionsMap } from "@health/enum-options";
import { TeamMemberPosition } from "@health/queries/types";
import { GuidedCareTemplateActivityUpsertFormSchema } from "../TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { GuidedCareTemplateMemberUpsertFormSchema } from "../TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { guidedCareTemplateGoalUpsertFormSchema } from "../TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";

export const GuidedCareTemplateUpsertFormSchema = z.object({
  name: z.string().min(1).max(70),
  guidedCareType: zodSchema.guidedCareTypeAutocompleteEnum,
  templateDuration: z.coerce.number().min(1),
  isRenewable: z.boolean(),
  isVerified: z.boolean().optional(),
  standardCode: z.string().max(255).optional(),
  standardName: z.string().max(255).optional(),
  standardURL: z.string().max(255).optional(),
  healthCondition: zodSchema.healthConditionAutocompleteOptional,
  riskFactorTemplate: zodSchema.riskFactorTemplateAutocompleteOptional,
  teamMembers: z.array(GuidedCareTemplateMemberUpsertFormSchema),
  activities: z.array(GuidedCareTemplateActivityUpsertFormSchema),
  goals: z.array(guidedCareTemplateGoalUpsertFormSchema),
});

export type IGuidedCareTemplateUpsertFormValues = z.infer<typeof GuidedCareTemplateUpsertFormSchema>;

export const guidedCareTemplateUpsertFormDefaultValues: Partial<IGuidedCareTemplateUpsertFormValues> = {
  name: undefined,
  guidedCareType: undefined,
  isRenewable: true,
  templateDuration: undefined,
  isVerified: false,
  standardCode: "",
  standardName: "",
  standardURL: "",
  healthCondition: undefined,
  riskFactorTemplate: undefined,
  teamMembers: [
    {
      uniqueId: uniqueId("member_"),
      position: guidedCareTeamMemberPositionOptionsMap[TeamMemberPosition.TeamLeader],
      specialization: undefined,
      isLicencedHealthProfessional: true,
      isBackupPersonMandatory: true,
      isKeyTeamMember: true,
    },
    {
      uniqueId: uniqueId("member_"),
      position: guidedCareTeamMemberPositionOptionsMap[TeamMemberPosition.TeamAdmin],
      specialization: undefined,
      isLicencedHealthProfessional: true,
      isBackupPersonMandatory: true,
      isKeyTeamMember: true,
    },
  ],
  activities: [],
  goals: [],
};
