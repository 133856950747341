import { useBreadCrumbs } from "@toolkit/ui";
import {
  labTemplateCloneBreadcrumb,
  labTemplateListBreadcrumb,
  labTemplatesTitleBreadcrumb,
} from "pages/HealthTemplate/LabParameters/constants/labTemplatesBreadcrumbs";
import React, { useEffect } from "react";
import { LabTemplateForm } from "../form/LabTemplateForm";
import { useDuplicateLabTemplate } from "./useDuplicateLabTemplate";

export const DuplicateLabTemplate = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { errors, handleSubmit, isSubmitting, clonedData, isLoading } = useDuplicateLabTemplate();

  useEffect(() => {
    setBreadCrumb({
      title: labTemplatesTitleBreadcrumb(),
      values: [labTemplateListBreadcrumb(), labTemplateCloneBreadcrumb()],
    });
  }, []);
  return (
    <LabTemplateForm
      isClone
      loading={isLoading}
      defaultValue={clonedData!}
      isSubmitting={isSubmitting}
      errors={errors}
      onSubmit={handleSubmit}
    />
  );
};
