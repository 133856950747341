/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthParameterFragmentDoc } from '../../fragments/__generated__/HealthParameter';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHealthParameterMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.HealthParameterUpdateInput>;
}>;


export type UpdateHealthParameterMutation = { __typename?: 'Mutation', updateHealthParameter?: { __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null };


export const UpdateHealthParameterDocument = gql`
    mutation UpdateHealthParameter($id: ID!, $input: HealthParameterUpdateInput) {
  updateHealthParameter(id: $id, input: $input) {
    ...HealthParameter
  }
}
    ${HealthParameterFragmentDoc}`;
export type UpdateHealthParameterMutationFn = Apollo.MutationFunction<UpdateHealthParameterMutation, UpdateHealthParameterMutationVariables>;

/**
 * __useUpdateHealthParameterMutation__
 *
 * To run a mutation, you first call `useUpdateHealthParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthParameterMutation, { data, loading, error }] = useUpdateHealthParameterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHealthParameterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHealthParameterMutation, UpdateHealthParameterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHealthParameterMutation, UpdateHealthParameterMutationVariables>(UpdateHealthParameterDocument, options);
      }
export type UpdateHealthParameterMutationHookResult = ReturnType<typeof useUpdateHealthParameterMutation>;
export type UpdateHealthParameterMutationResult = Apollo.MutationResult<UpdateHealthParameterMutation>;
export type UpdateHealthParameterMutationOptions = Apollo.BaseMutationOptions<UpdateHealthParameterMutation, UpdateHealthParameterMutationVariables>;