import { z } from "zod";

export const ArticleCategoryUpsertFormSchema = z.object({
  display: z.string().min(3).max(255),
  displayAr: z.string().min(3).max(255).nullable().optional(),
  code: z.string(),
});

export type IArticleCategoryUpsertFormValues = z.infer<typeof ArticleCategoryUpsertFormSchema>;

export const articleCategoryUpsertFormSchemaDefaultValues: Partial<IArticleCategoryUpsertFormValues> = {
  display: "",
  displayAr: "",
  code: "",
};
