/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { RiskFactorTemplateOperationsFragmentFragmentDoc } from '../../fragments/__generated__/RiskFactorTemplateOperationsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddRiskFactorTemplateMutationVariables = Types.Exact<{
  input: Types.RiskFactorTemplateInput;
}>;


export type AddRiskFactorTemplateMutation = { __typename?: 'Mutation', addRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', id?: string | null, isActive?: boolean | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', message?: string | null, field?: string | null, errorType?: Types.ErrorType | null, code?: Types.DecisionMakerErrorCodes | null } | null> | null } | null };


export const AddRiskFactorTemplateDocument = gql`
    mutation AddRiskFactorTemplate($input: RiskFactorTemplateInput!) {
  addRiskFactorTemplate(input: $input) {
    ...RiskFactorTemplateOperationsFragment
  }
}
    ${RiskFactorTemplateOperationsFragmentFragmentDoc}`;
export type AddRiskFactorTemplateMutationFn = Apollo.MutationFunction<AddRiskFactorTemplateMutation, AddRiskFactorTemplateMutationVariables>;

/**
 * __useAddRiskFactorTemplateMutation__
 *
 * To run a mutation, you first call `useAddRiskFactorTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRiskFactorTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRiskFactorTemplateMutation, { data, loading, error }] = useAddRiskFactorTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRiskFactorTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddRiskFactorTemplateMutation, AddRiskFactorTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRiskFactorTemplateMutation, AddRiskFactorTemplateMutationVariables>(AddRiskFactorTemplateDocument, options);
      }
export type AddRiskFactorTemplateMutationHookResult = ReturnType<typeof useAddRiskFactorTemplateMutation>;
export type AddRiskFactorTemplateMutationResult = Apollo.MutationResult<AddRiskFactorTemplateMutation>;
export type AddRiskFactorTemplateMutationOptions = Apollo.BaseMutationOptions<AddRiskFactorTemplateMutation, AddRiskFactorTemplateMutationVariables>;