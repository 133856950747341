/* eslint-disable max-lines */
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormNumberField, Grid } from "@toolkit/ui";
import { MedicalFormsAutocomplete, MedicalMessagesAutocomplete } from "@health/autocompletes";
import {
  appointmentFrequencyTypeOptions,
  guidedCareActivityTypeTemplateOptions,
  isGuidedCareActivityTypeMedicalMessage,
} from "@health/enum-options";
import { GuidedCareActivityType, MedicalMessageCategory } from "@health/queries/types";
import {
  filterTeamMembersByActivityType,
  getMedicalMessageCategory,
  shouldTemplateActivityMessagesAndOffsetsShow,
} from "pages/Templates/others";
import { IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormEvent } from "pages/Templates/types";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import {
  guidedCareTemplateGoalRecommendationInterventionActivityUpsertFormSchema,
  IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues,
  guidedCareTemplateGoalRecommendationInterventionActivityUpsertFormDefaultValues,
} from "./GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormSchema";

type GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormProps = {
  goalIndex: number;
  recommendationIndex: number;
  interventionIndex: number;
  activityIndex?: number;
  teamMembers: IGuidedCareTemplateMemberUpsertFormValues[];
  activity?: IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues;
  onChange: (event: IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormEvent) => void;
};

export type GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues>;
  submit: () => void;
};

const GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormRef,
  GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormProps
> = (props, ref) => {
  const { activity, teamMembers, goalIndex, recommendationIndex, interventionIndex, activityIndex, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues>({
    defaultValues: guidedCareTemplateGoalRecommendationInterventionActivityUpsertFormDefaultValues,
    schema: guidedCareTemplateGoalRecommendationInterventionActivityUpsertFormSchema,
  });

  const { watch, setValues, handleSubmit } = form;

  const templateActivityType = watch("templateActivityType");
  const templateActivityTypeValue = templateActivityType?.value;

  const showTemplateActivityMessagesAndOffsets = shouldTemplateActivityMessagesAndOffsetsShow(templateActivityTypeValue);
  const filteredTeamMembers = filterTeamMembersByActivityType(teamMembers, templateActivityTypeValue);
  const medicalMessageCategory = getMedicalMessageCategory(templateActivityType?.label!);
  const medicalMessageActivity = isGuidedCareActivityTypeMedicalMessage(templateActivityType?.key!);

  const onSubmit = (values: IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues) => {
    if (activity) {
      onChange({
        type: "UPDATE",
        payload: {
          values: {
            ...values,
            activityServices: activity?.activityServices,
          },
          goalIndex,
          recommendationIndex,
          interventionIndex,
          activityIndex: activityIndex!,
        },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: { values, goalIndex, recommendationIndex, interventionIndex },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (activity) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { activityServices, ...rest } = activity;
      setValues(rest);
    }
  }, [activity, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={6}>
            <FormAutocomplete name={"templateActivityType"} label={t("Activity Type")} options={guidedCareActivityTypeTemplateOptions} />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete
              name={"teamMember"}
              label={t("Assignee")}
              disabled={!templateActivityTypeValue}
              options={filteredTeamMembers}
            />
          </Grid>

          {templateActivityTypeValue === GuidedCareActivityType.MedicalForm && (
            <Grid item xs={12}>
              <MedicalFormsAutocomplete name={"medicalForm"} filter={{ isAdminForm: true, isActive: true }} />
            </Grid>
          )}

          {medicalMessageActivity && (
            <Grid item xs={12}>
              <MedicalMessagesAutocomplete name={"medicalMessage"} filter={{ category: medicalMessageCategory, isPublished: true }} />
            </Grid>
          )}

          <Grid item xs={6}>
            <FormNumberField name={"numberOfOccurrences"} label={t("Occurrences")} />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete name={"frequencyType"} label={t("Frequency")} options={appointmentFrequencyTypeOptions} />
          </Grid>

          {showTemplateActivityMessagesAndOffsets && (
            <>
              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"beforeActivity"}
                  label={t("Before Activity")}
                  filter={{ category: MedicalMessageCategory.ActivityInstruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"afterActivity"}
                  label={t("After Activity")}
                  filter={{ category: MedicalMessageCategory.ActivityInstruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"beforeActivityOffset"} label={t("Before Activity - Offset (Days)")} />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"afterActivityOffset"} label={t("After Activity - Offset (Days)")} />
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareTemplateGoalRecommendationInterventionActivityUpsertForm = forwardRef(
  GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormForwardRef
);
