import { FormProvider, UseFormReturn } from "react-hook-form";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useCustomForm } from "@toolkit/core";
import { FormTextField, Grid } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent } from "../../types";
import {
  guidedCareTemplateGoalRecommendationUpsertFormSchema,
  IGuidedCareTemplateGoalRecommendationUpsertFormValues,
  guidedCareTemplateGoalRecommendationUpsertFormDefaultValues,
} from "./GuidedCareTemplateGoalRecommendationUpsertFormSchema";

type GuidedCareTemplateGoalRecommendationUpsertFormProps = {
  goalIndex: number;
  recommendationIndex?: number;
  recommendation?: IGuidedCareTemplateGoalRecommendationUpsertFormValues;
  onChange: (event: IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent) => void;
};

export type GuidedCareTemplateGoalRecommendationUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTemplateGoalRecommendationUpsertFormValues>;
  submit: () => void;
};

const GuidedCareTemplateGoalRecommendationUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateGoalRecommendationUpsertFormRef,
  GuidedCareTemplateGoalRecommendationUpsertFormProps
> = (props, ref) => {
  const { recommendation, goalIndex, recommendationIndex, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareTemplateGoalRecommendationUpsertFormValues>({
    defaultValues: guidedCareTemplateGoalRecommendationUpsertFormDefaultValues,
    schema: guidedCareTemplateGoalRecommendationUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IGuidedCareTemplateGoalRecommendationUpsertFormValues) => {
    if (recommendation) {
      onChange({
        type: "UPDATE",
        payload: {
          values: {
            ...values,
            interventions: recommendation?.interventions,
          },
          goalIndex,
          recommendationIndex: recommendationIndex!,
        },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: { values, goalIndex },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (recommendation) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { interventions, ...rest } = recommendation;
      setValues(rest);
    }
  }, [recommendation, setValues]);

  return (
    <FormProvider {...form}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
        </Grid>

        <Grid item xs={6}>
          <FormTextField name={"nameAr"} label={t("Name (Arabic)")} placeholder={t("Name (Arabic)")} />
        </Grid>

        <Grid item xs={12}>
          <FormTextField name={"code"} label={t("Code")} placeholder={t("Code")} />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormTextField name={"description"} label={t("Description")} placeholder={t("Description")} multiline rows={3} />
            </Grid>

            <Grid item xs={6}>
              <FormTextField
                name={"descriptionAr"}
                label={t("Description (Arabic)")}
                placeholder={t("Description (Arabic)")}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const GuidedCareTemplateGoalRecommendationUpsertForm = forwardRef(GuidedCareTemplateGoalRecommendationUpsertFormForwardRef);
