/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthParameterFragmentDoc } from '../../fragments/__generated__/HealthParameter';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHealthParameterQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetHealthParameterQuery = { __typename?: 'Query', getHealthParameter?: { __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null };


export const GetHealthParameterDocument = gql`
    query GetHealthParameter($id: ID!) {
  getHealthParameter(id: $id) {
    ...HealthParameter
  }
}
    ${HealthParameterFragmentDoc}`;

/**
 * __useGetHealthParameterQuery__
 *
 * To run a query within a React component, call `useGetHealthParameterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthParameterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthParameterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHealthParameterQuery(baseOptions: Apollo.QueryHookOptions<GetHealthParameterQuery, GetHealthParameterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthParameterQuery, GetHealthParameterQueryVariables>(GetHealthParameterDocument, options);
      }
export function useGetHealthParameterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthParameterQuery, GetHealthParameterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthParameterQuery, GetHealthParameterQueryVariables>(GetHealthParameterDocument, options);
        }
export type GetHealthParameterQueryHookResult = ReturnType<typeof useGetHealthParameterQuery>;
export type GetHealthParameterLazyQueryHookResult = ReturnType<typeof useGetHealthParameterLazyQuery>;
export type GetHealthParameterQueryResult = Apollo.QueryResult<GetHealthParameterQuery, GetHealthParameterQueryVariables>;