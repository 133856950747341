/* eslint-disable max-lines */
import { createAutocompleteOption } from "@toolkit/ui";
import {
  appointmentFrequencyTypeOptionsMap,
  codeSystemCodeOptionsMap,
  guidedCareActivityTypeOptionsMap,
  guidedCareTeamMemberPositionOptionsMap,
  guidedCareTypeOptionsMap,
} from "@health/enum-options";
import {
  HealthCondition,
  HealthProgramTemplate,
  Maybe,
  MedicalForm,
  MedicalFormClassificationToInterventionLink,
  RiskFactorTemplate,
  TemplateActivity,
  TemplateActivityEventItem,
  TemplateActivityEventItemInput,
  TemplateGoal,
  TemplateInterventionActivity,
  TemplateInterventionActivityEventItem,
  TemplateTeamMember,
} from "@health/queries/types";
import { IGuidedCareTemplateActivityItemUpdateFormValues } from "../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { IGuidedCareTemplateUpsertFormValues } from "../forms/TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { IGuidedCareTemplateActivityUpsertFormValues } from "../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues } from "../forms/TemplateGoalRecommendationInterventionActivityUpsert/GuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormSchema";
import { IGuidedCareTemplateGoalUpsertFormValues } from "../forms/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";
import { pickLocalizedValue } from "@toolkit/i18n";
import { getTeamMemberLabel } from "./utils";

export const convertTemplateToFormValues = (template: HealthProgramTemplate): IGuidedCareTemplateUpsertFormValues => {
  return {
    name: template?.name!,
    guidedCareType: guidedCareTypeOptionsMap[template?.guidedCareType!],
    isRenewable: !!template?.isRenewable,
    templateDuration: template?.templateDuration!,
    isVerified: !!template?.isVerified,
    standardCode: template?.standardCode! || "",
    standardName: template?.standardName! || "",
    standardURL: template?.standardURL! || "",
    healthCondition: convertTemplateHealthConditionToFormValues(template?.healthCondition),
    riskFactorTemplate: convertTemplateRiskFactorTemplateConditionToFormValues(template?.riskFactorTemplate),
    teamMembers: template?.templateTeamMembers?.map(item => convertTemplateTeamMemberToFormValues(item)) || [],
    activities: convertTemplateActivitiesToFormValues(template?.templateActivities, template?.templateTeamMembers),
    goals: convertTemplateGoalsToFormValues(template?.templateGoals, template?.templateTeamMembers),
  };
};

const convertTemplateHealthConditionToFormValues = (healthCondition?: Maybe<HealthCondition>) => {
  return healthCondition
    ? createAutocompleteOption(
        { id: healthCondition?.id!, code: healthCondition?.code!, display: healthCondition?.display! },
        "code",
        "display"
      )
    : undefined;
};

const convertTemplateRiskFactorTemplateConditionToFormValues = (riskFactorTemplate?: Maybe<RiskFactorTemplate>) => {
  return riskFactorTemplate
    ? createAutocompleteOption(
        {
          id: riskFactorTemplate?.id!,
          code: riskFactorTemplate?.code!,
          display: riskFactorTemplate?.display!,
        },
        "code",
        "display"
      )
    : undefined;
};

export const convertTemplateActivityItemsToFormValues = (
  activityItems?: Maybe<TemplateActivityEventItem>[] | Maybe<TemplateInterventionActivityEventItem>[]
): IGuidedCareTemplateActivityItemUpdateFormValues[] => {
  return (
    activityItems?.map(item => ({
      activityItemId: item?.id!,
      offsetInDays: item?.offsetInDays!,
      beforeActivity: item?.beforeCallMessage!
        ? createAutocompleteOption({ id: item?.beforeCallMessage?.id!, name: item?.beforeCallMessage?.name! }, "id", "name")
        : null,
      beforeActivityOffset: item?.beforeCallMessageOffsetDays! || null,
      afterActivity: item?.afterCallMessage!
        ? createAutocompleteOption({ id: item?.afterCallMessage?.id!, name: item?.afterCallMessage?.name! }, "id", "name")
        : null,
      afterActivityOffset: item?.afterCallMessageOffsetDays! || null,
    })) || []
  );
};

export const convertActivityItemsFormValuesToBackEndValues = (
  activityItems: IGuidedCareTemplateActivityItemUpdateFormValues[]
): TemplateActivityEventItemInput[] => {
  return activityItems.map(item => {
    return {
      offsetInDays: item.offsetInDays,
      beforeCallMessageId: item.beforeActivity?.value?.id,
      beforeCallMessageOffsetDays: item.beforeActivityOffset,
      afterCallMessageId: item.afterActivity?.value?.id,
      afterCallMessageOffsetDays: item.afterActivityOffset,
    };
  });
};

const convertTemplateTeamMemberToFormValues = (
  templateTeamMember?: Maybe<TemplateTeamMember>
): IGuidedCareTemplateMemberUpsertFormValues => {
  return {
    teamMemberId: templateTeamMember?.id!,
    uniqueId: templateTeamMember?.id!,
    position: guidedCareTeamMemberPositionOptionsMap[templateTeamMember?.position!],
    specialization: templateTeamMember?.specialization
      ? createAutocompleteOption(
          { code: templateTeamMember?.specialization!, display: templateTeamMember?.specializationDisplay! },
          "code",
          "display"
        )
      : undefined,
    isLicencedHealthProfessional: !!templateTeamMember?.isLicencedHealthProfessional,
    isBackupPersonMandatory: !!templateTeamMember?.isBackupPersonMandatory,
    isKeyTeamMember: !!templateTeamMember?.isKeyTeamMember,
  };
};

const convertTemplateActivityMedicalFormToFormValues = (medicalForm?: Maybe<MedicalForm>) => {
  return medicalForm
    ? createAutocompleteOption(
        {
          id: medicalForm?.id!,
          name: medicalForm?.name!,
          nameAr: medicalForm?.nameAr,
          medicalFormClassifications:
            medicalForm?.medicalFormClassifications?.map(classification => ({
              id: classification?.id!,
              name: classification?.name!,
              rangeFrom: classification?.rangeFrom!,
              rangeTo: classification?.rangeTo!,
            })) || [],
        },
        "id",
        "name"
      )
    : undefined;
};

const convertTemplateActivityMedicalFormClassificationsInterventionsToFormValues = (
  medicalFormClassificationsInterventions?: Maybe<Array<Maybe<MedicalFormClassificationToInterventionLink>>>
) => {
  return (
    medicalFormClassificationsInterventions?.map(item => ({
      id: item?.id,
      classification: item?.classification!,
      intervention: item?.intervention
        ? createAutocompleteOption(
            {
              uniqueId: item?.intervention?.id!,
              name: item?.intervention?.name!,
              nameAr: item?.intervention?.nameAr!,
              duration: item?.intervention?.duration!,
              description: item?.intervention?.description!,
              descriptionAr: item?.intervention?.descriptionAr!,
              activities: [],
            },
            "uniqueId",
            classificationInterventionItem =>
              pickLocalizedValue(classificationInterventionItem?.name, classificationInterventionItem?.nameAr)
          )
        : undefined,
    })) || []
  );
};

const convertTemplateActivitiesToFormValues = (
  activities?: Maybe<Maybe<TemplateActivity>[]>,
  members?: Maybe<Maybe<TemplateTeamMember>[]>
): IGuidedCareTemplateActivityUpsertFormValues[] => {
  return (
    activities?.map(item => {
      const teamMember = members?.find(member => member?.id! === item?.templateTeamMember?.id!);

      return {
        activityId: item?.id!,
        teamMember: createAutocompleteOption(convertTemplateTeamMemberToFormValues(teamMember), "uniqueId", getTeamMemberLabel),
        templateActivityType: guidedCareActivityTypeOptionsMap[item?.templateActivityType!],
        numberOfOccurrences: item?.numberOfOccurrences!,
        frequencyType: appointmentFrequencyTypeOptionsMap[item?.frequencyType!],
        medicalForm: convertTemplateActivityMedicalFormToFormValues(item?.medicalForm),
        medicalMessage: item?.medicalMessage
          ? createAutocompleteOption({ id: item?.medicalMessage?.id!, name: item?.medicalMessage?.name! }, "id", "name")
          : undefined,
        beforeActivity: item?.beforeCallMessage!
          ? createAutocompleteOption({ id: item?.beforeCallMessage?.id!, name: item?.beforeCallMessage?.name! }, "id", "name")
          : null,
        beforeActivityOffset: item?.beforeCallMessageOffsetDays!,
        afterActivity: item?.afterCallMessage!
          ? createAutocompleteOption({ id: item?.afterCallMessage?.id!, name: item?.afterCallMessage?.name! }, "id", "name")
          : null,
        afterActivityOffset: item?.afterCallMessageOffsetDays!,
        medicalFormClassificationsInterventions: convertTemplateActivityMedicalFormClassificationsInterventionsToFormValues(
          item?.medicalFormClassificationToInterventionLinkList
        ),
        activityServices:
          item?.serviceOfHealthProgramTemplateDetails?.map(service => ({
            serviceId: service?.id!,
            standard: codeSystemCodeOptionsMap[service?.standard!],
            service:
              service?.serviceCode && service?.serviceName
                ? createAutocompleteOption({ code: service?.serviceCode, display: service?.serviceName }, "code", "display")
                : null,
          })) || [],
      };
    }) || []
  );
};

const convertTemplateGoalsToFormValues = (
  templateGoals?: Maybe<Array<Maybe<TemplateGoal>>>,
  templateTeamMembers?: Maybe<Array<Maybe<TemplateTeamMember>>>
): IGuidedCareTemplateGoalUpsertFormValues[] => {
  return (
    templateGoals?.map(goal => ({
      uniqueId: goal?.id!,
      name: goal?.name!,
      nameAr: goal?.nameAr!,
      target: goal?.target!,
      description: goal?.description!,
      descriptionAr: goal?.descriptionAr!,
      recommendations:
        goal?.templateRecommendations?.map(recommendation => ({
          uniqueId: recommendation?.id!,
          name: recommendation?.name!,
          nameAr: recommendation?.nameAr!,
          code: recommendation?.code!,
          description: recommendation?.description!,
          descriptionAr: recommendation?.descriptionAr!,
          interventions:
            recommendation?.templateInterventions?.map(intervention => ({
              interventionId: intervention?.id!,
              uniqueId: intervention?.id!,
              name: intervention?.name!,
              nameAr: intervention?.nameAr!,
              duration: intervention?.duration!,
              description: intervention?.description!,
              descriptionAr: intervention?.descriptionAr!,
              activities:
                convertTemplateGoalRecommendationInterventionActivitiesToFormValues(
                  intervention?.interventionTemplateActivities,
                  templateTeamMembers
                ) || [],
            })) || [],
        })) || [],
    })) || []
  );
};

const convertTemplateGoalRecommendationInterventionActivitiesToFormValues = (
  activities?: Maybe<Maybe<TemplateInterventionActivity>[]>,
  members?: Maybe<Maybe<TemplateTeamMember>[]>
): IGuidedCareTemplateGoalRecommendationInterventionActivityUpsertFormValues[] => {
  return (
    activities?.map(item => {
      const teamMember = members?.find(member => member?.id! === item?.templateTeamMember?.id!);

      return {
        activityId: item?.id!,
        teamMember: createAutocompleteOption(convertTemplateTeamMemberToFormValues(teamMember), "uniqueId", getTeamMemberLabel),
        templateActivityType: guidedCareActivityTypeOptionsMap[item?.templateActivityType!],
        numberOfOccurrences: item?.numberOfOccurrences!,
        frequencyType: appointmentFrequencyTypeOptionsMap[item?.frequencyType!],
        medicalForm: item?.medicalForm
          ? createAutocompleteOption({ id: item?.medicalForm?.id!, name: item?.medicalForm?.name! }, "id", "name")
          : undefined,
        medicalMessage: item?.medicalMessage
          ? createAutocompleteOption({ id: item?.medicalMessage?.id!, name: item?.medicalMessage?.name! }, "id", "name")
          : undefined,
        beforeActivity: item?.beforeCallMessage!
          ? createAutocompleteOption({ id: item?.beforeCallMessage?.id!, name: item?.beforeCallMessage?.name! }, "id", "name")
          : null,
        beforeActivityOffset: item?.beforeCallMessageOffsetDays!,
        afterActivity: item?.afterCallMessage!
          ? createAutocompleteOption({ id: item?.afterCallMessage?.id!, name: item?.afterCallMessage?.name! }, "id", "name")
          : null,
        afterActivityOffset: item?.afterCallMessageOffsetDays!,
        activityServices:
          // eslint-disable-next-line sonarjs/no-identical-functions
          item?.serviceOfInterventionActivityDetails?.map(service => ({
            serviceId: service?.id!,
            standard: codeSystemCodeOptionsMap[service?.standard!],
            service:
              service?.serviceCode && service?.serviceName
                ? createAutocompleteOption({ code: service?.serviceCode, display: service?.serviceName }, "code", "display")
                : null,
          })) || [],
      };
    }) || []
  );
};
