import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { riskFactorTemplatesPaths } from "pages/HealthTemplate/RiskRulesParameters/constants/riskFactorTemplatesPaths";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRiskFactorTemplateQuery, useUpdateRiskFactorTemplateMutation } from "../../gql";
import { IFormData } from "../types";

export const useRiskFactorTemplateEdit = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const params = useParams() as { id: string };
  const { id } = params;

  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const { data, loading } = useGetRiskFactorTemplateQuery({
    variables: {
      getRiskFactorTemplateId: id!,
    },
  });

  const [riskFactorTemplateUpdate, { loading: isSubmitting }] = useUpdateRiskFactorTemplateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.updateRiskFactorTemplate?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Risk Factor Template Updated Successfully"));
        navigate(riskFactorTemplatesPaths.listPath.fullPath);
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Risk Factor Template Update Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handleSubmit = (input: Partial<IFormData>) => {
    delete input?.code;
    riskFactorTemplateUpdate({
      variables: {
        updateRiskFactorTemplateId: id,
        input: {
          ...input,
          isActive: input?.isActive || false,
        } as IFormData,
      },
    });
  };

  const riskTemplate = data?.getRiskFactorTemplate;

  return {
    errors,
    loading,
    riskTemplate,
    handleSubmit,
    isSubmitting,
  };
};
