import { SystemCodeAutocomplete } from "@health/autocompletes";
import { combineErrors, patternArabicTextWithSpecialCharAndNumbers, required, validateValueIsNotEmpty } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, createAutocompleteOption, Divider, Grid, TextField, ToggleButtonController } from "@toolkit/ui";
import { CodeSystemCode } from "@health/queries/types";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IForm } from "../types";

export const GeneralInformation: FC<IForm> = ({ defaultValue, errors, isClone }) => {
  const { t } = useTranslation("gcadmin");

  const {
    register,
    setValue,
    formState: { errors: reactFormErrors },
    control,
  } = useFormContext();

  const formErrors = combineErrors(reactFormErrors, errors);

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const code =
      !isClone && defaultValue?.code
        ? createAutocompleteOption(
            { code: defaultValue?.code, display: defaultValue?.code + " - " + defaultValue?.labCodeDisplay },
            "code",
            "display"
          )
        : undefined;

    setValue("code", code);
  }, [defaultValue, isClone, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("Display")}
          error={Boolean(formErrors.display?.message)}
          helperText={t(formErrors.display?.message)}
          defaultValue={defaultValue?.display}
          {...register("display", { required: required(t), validate: value => validateValueIsNotEmpty(value, t) })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("Arabic Display")}
          error={Boolean(formErrors.arabicDisplay?.message)}
          helperText={t(formErrors.arabicDisplay?.message)}
          defaultValue={defaultValue?.arabicDisplay}
          {...register("arabicDisplay", {
            required: required(t),
            pattern: patternArabicTextWithSpecialCharAndNumbers,
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <SystemCodeAutocomplete
          name={"code"}
          disabled={!isClone && Boolean(defaultValue?.code)}
          filter={{ codeSystemCode: CodeSystemCode.LabTest }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Box display='flex' alignItems='center'>
          <ToggleButtonController
            {...register("isActive")}
            control={control}
            defaultValue={defaultValue?.isActive || false}
            label={t("Is Active")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
