import { TFunction } from "@toolkit/i18n";

import { labTemplatesPaths, labTemplatesRoute } from "pages/HealthTemplate/LabParameters/constants/labTemplatesPaths";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { DuplicateLabTemplate, LabFieldsTemplateList, LabTemplateEditPage, LabTemplateNewPage, LabTemplatesList } from ".";

export const labRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "lab-templates-routes",
    text: t("Lab Templates", { ns: "gcadmin" }),
    route: labTemplatesRoute,
    subItems: [
      {
        id: "lab-templates-list-route",
        text: t("Lab Templates", { ns: "gcadmin" }),
        route: labTemplatesPaths.listPath.route,
        fullPath: labTemplatesPaths.listPath.fullPath,
        element: <LabTemplatesList />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-templates-new-route",
        text: t("Lab Templates New", { ns: "gcadmin" }),
        route: labTemplatesPaths.newPath.route,
        fullPath: labTemplatesPaths.newPath.fullPath,
        element: <LabTemplateNewPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-templates-edit-route",
        text: t("Lab Templates Edit", { ns: "gcadmin" }),
        route: labTemplatesPaths.editPath.route,
        fullPath: labTemplatesPaths.editPath.fullPath,
        element: <LabTemplateEditPage />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-templates-field-route",
        text: t("Lab Template Fields", { ns: "gcadmin" }),
        route: labTemplatesPaths.fieldPath.route,
        fullPath: labTemplatesPaths.fieldPath.fullPath,
        element: <LabFieldsTemplateList />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-templates-clone-route",
        text: t("Lab Templates Clone", { ns: "gcadmin" }),
        route: labTemplatesPaths.clonePath.route,
        fullPath: labTemplatesPaths.clonePath.fullPath,
        element: <DuplicateLabTemplate />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
