/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { articlesCategoriesRoutes } from "pages/KnowledgeBaseArticlesCategories";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { knowledgeBaseRoute } from "./knowledgeBasePaths";
import { articlesRoutes } from "pages/KnowledgeBaseArticles";
import { MedicationIcon } from "@toolkit/ui";

export const knowledgeBaseRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }) => {
  const isKnowledgeBaseRootHidden = false;
  return {
    id: "knowledge-base",
    text: i18n.t("Knowledge Base", { ns: "admin" }),
    icon: <MedicationIcon />,
    isProhibited: isKnowledgeBaseRootHidden,
    hidden: isKnowledgeBaseRootHidden,
    route: knowledgeBaseRoute,
    subItems: [
      articlesCategoriesRoutes({ navigate, enabled: isKnowledgeBaseRootHidden }),
      articlesRoutes({ navigate, enabled: isKnowledgeBaseRootHidden }),
    ],
  };
};
