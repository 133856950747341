import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { i18n, useTranslation } from "@toolkit/i18n";
import { useAddToast, useAutocompleteCustomHook } from "@toolkit/ui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAddFieldToHealthConditionMutation, useGetHealthParametersForAutoCompleteQuery } from "../gql";

export const useAddFieldToHealthCondition = (handleToggle?) => {
  const { t } = useTranslation("gcadmin");
  const params = useParams();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const [addFieldToHealthCondition, { loading: loadingAdd }] = useAddFieldToHealthConditionMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.addFieldToHealthCondition?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Add Parameter To Health Condition Successfully"));
        handleToggle();
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Add Parameter To Health Condition Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const {
    data,
    isLoading: loading,
    onFetchMoreData,
    refetch,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useGetHealthParametersForAutoCompleteQuery,
    input: {
      first: 10,
    },
    dataAccessor: "getHealthParameters",
  });

  const getRiskFactorFields = data?.getHealthParameters?.edges?.map(item => item?.node);
  const pageInfo = data?.getHealthParameters?.pageInfo;

  const handleAddFieldToHealthCondition = healthParameterId => {
    addFieldToHealthCondition({
      variables: {
        healthParameterId,
        healthConditionId: params.id!,
      },
    });
  };
  const handleSearch = (filter: string) => {
    refetch({
      filter: {
        [i18n.language === "ar" ? "arabicDisplay" : "display"]: filter,
      },
    });
  };

  return {
    errors,
    loading: loading || loadingAdd,
    pageInfo,
    getRiskFactorFields,
    fetchMoreData: onFetchMoreData,
    handleAddFieldToHealthCondition,
    isSubmitting: loadingAdd,
    handleSearch,
  };
};
