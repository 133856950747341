/* eslint-disable max-lines */
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode, FieldStandard, TemplateFieldType } from "@health/queries/types";
import {
  combineErrors,
  getRequiredValidation,
  patternArabicTextWithSpecialCharAndNumbers,
  required,
  validateValueIsNotEmpty,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, createAutocompleteOption, Divider, Grid, TextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ArrayOfValueAutocomplete } from "shared/components";
import { IForm, IFormData } from "../types";
import { HealthParameterCategory } from "./HealthParameterCategory";
import { HealthParameterChartType } from "./HealthParameterChartType";
import { ToggleButtonGroup } from "./ToggleButtonGroup";
import { Units } from "./Units";
import { templateFieldTypeOptions, templateFieldTypeOptionsMap } from "@health/enum-options";

export const GeneralInformation: FC<IForm> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("gcadmin");
  const {
    register,
    setValue,
    control,
    watch,
    formState: { errors: reactFormErrors },
  } = useFormContext<IFormData>();

  const isString = watch("type")?.value === TemplateFieldType.String || defaultValue?.type === TemplateFieldType.String;

  const standard = Object.keys(FieldStandard).map(key => ({
    value: FieldStandard[key],
    name: key,
  }));

  const formErrors = combineErrors(reactFormErrors, errors);
  const allowedValues = watch("allowedValues") || [];
  const standardValue = watch("standard")?.value || defaultValue?.standard;

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("standardCode", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const codeDefaultValue = defaultValue?.code
      ? createAutocompleteOption(
          { code: defaultValue?.standardCode!, display: defaultValue?.standardCode! + " - " + defaultValue?.standardCodeDisplay! },
          "code",
          "display"
        )
      : undefined;

    setValue("standardCode", codeDefaultValue);
    setValue("type", templateFieldTypeOptionsMap[defaultValue?.type || ""] || undefined);
  }, [defaultValue, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("Code")}
          error={Boolean(formErrors.code?.message)}
          helperText={t(formErrors.code?.message)}
          defaultValue={defaultValue?.code}
          disabled={!!defaultValue}
          {...register("code", {
            required: defaultValue ? undefined : required(t),
            validate: value => validateValueIsNotEmpty(value, t),
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("Display")}
          error={Boolean(formErrors.display?.message)}
          helperText={t(formErrors.display?.message)}
          defaultValue={defaultValue?.display}
          {...register("display", { required: required(t), validate: value => validateValueIsNotEmpty(value, t) })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          fullWidth
          size='small'
          label={t("Arabic Display")}
          error={Boolean(formErrors.arabicDisplay?.message)}
          helperText={t(formErrors.arabicDisplay?.message)}
          defaultValue={defaultValue?.arabicDisplay}
          {...register("arabicDisplay", {
            required: required(t),
            validate: value => validateValueIsNotEmpty(value, t),
            pattern: patternArabicTextWithSpecialCharAndNumbers,
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <HealthParameterCategory defaultValue={defaultValue} errors={errors} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Units defaultValue={defaultValue} errors={errors} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AutocompleteController
          ControllerProps={{
            control: control,
            name: "type",
          }}
          options={templateFieldTypeOptions}
          disabled={Boolean(defaultValue)}
          TextFieldProps={{
            label: t("Type"),
            error: Boolean(formErrors.type?.message),
            helperText: formErrors.type?.message,
          }}
          getOptionLabel={option => option?.label}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <HealthParameterChartType defaultValue={defaultValue} errors={errors} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AutocompleteController
          ControllerProps={{
            control: control,
            name: "standard",
            defaultValue: standard?.find(el => el?.value === defaultValue?.standard) || undefined,
          }}
          options={standard}
          TextFieldProps={{
            label: t("Coding Standard"),
            error: Boolean(formErrors.standard?.message),
            helperText: formErrors.standard?.message,
          }}
          getOptionLabel={option => option.name?.toUpperCase()}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <SystemCodeAutocomplete
          name={"standardCode"}
          label={t("Standard Code")}
          disabled={!standard}
          filter={{ codeSystemCode: standardValue === FieldStandard.Cpt ? CodeSystemCode.Cpt : CodeSystemCode.Loinc }}
        />
      </Grid>

      {isString && (
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={12} sm={6} md={8}>
            <ArrayOfValueAutocomplete
              isRequired={false}
              defaultValue={defaultValue}
              errors={errors}
              name='allowedValues'
              placeholder={t("Allowed Values")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AutocompleteController
              options={allowedValues}
              getOptionLabel={option => option}
              TextFieldProps={{
                label: t("Default Value"),
                error: Boolean(formErrors.defaultValue?.message),
                helperText: formErrors.defaultValue?.message,
              }}
              ControllerProps={{
                control: control,
                name: "defaultValue",
                defaultValue: defaultValue?.allowedValues?.find(el => el === defaultValue?.defaultValue) || undefined,
                rules: {
                  required: false,
                },
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <TextField
          fullWidth
          size='small'
          multiline
          rows={5}
          label={t("Reference Range")}
          error={Boolean(formErrors.referenceRange?.message)}
          helperText={t(formErrors.referenceRange?.message)}
          defaultValue={defaultValue?.referenceRange}
          {...register("referenceRange")}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ToggleButtonGroup defaultValue={defaultValue} />
      </Grid>
    </Grid>
  );
};
