/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type HealthConditionOperationsFragmentFragment = { __typename?: 'HealthCondition', arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, disease?: string | null, display?: string | null, id?: string | null, isActive?: boolean | null, type?: Types.HealthConditionType | null, umlsCode?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null };

export const HealthConditionOperationsFragmentFragmentDoc = gql`
    fragment HealthConditionOperationsFragment on HealthCondition {
  arabicDisplay
  code
  createdDate
  disease
  display
  errors {
    code
    errorType
    field
    message
  }
  id
  isActive
  type
  umlsCode
}
    `;