import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useCustomFormContext } from "@toolkit/core";
import { Box, Typography } from "@toolkit/ui";
import { useFieldArray, UseFieldArrayReturn } from "react-hook-form";
import React, { createRef, forwardRef, ForwardRefRenderFunction, RefObject, useEffect, useImperativeHandle, useRef } from "react";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import {
  GuidedCareTemplateCustomAccordion,
  IGuidedCareTemplateCustomAccordionEvent,
} from "../../components/TemplateCustomAccordion/GuidedCareTemplateCustomAccordion";
import {
  GuidedCareTemplateGoalRecommendationInterventionActivitiesForm,
  GuidedCareTemplateGoalRecommendationInterventionActivitiesFormRef,
} from "../TemplateGoalRecommendationInterventionActivities/GuidedCareTemplateGoalRecommendationInterventionActivitiesForm";
import { GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi } from "../../modals/TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertModal";
import { GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalApi } from "../../modals/TemplateGoalRecommendationInterventionActivityUpsert/GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModal";
import { GuidedCareTemplateGoalDeleteConfirmationModalApi } from "../../modals/TemplateGoalDeleteConfirmation/GuidedCareTemplateGoalDeleteConfirmationModal";
import { IGuidedCareTemplateGoalRecommendationInterventionsFields } from "../../types";

type GuidedCareTemplateGoalRecommendationInterventionsFormProps = {
  goalIndex: number;
  recommendationIndex: number;
};

export type GuidedCareTemplateGoalRecommendationInterventionsFormRef = {
  fieldArray: () => UseFieldArrayReturn<IGuidedCareTemplateUpsertFormValues, IGuidedCareTemplateGoalRecommendationInterventionsFields>;
  interventionsRefs: RefObject<GuidedCareTemplateGoalRecommendationInterventionActivitiesFormRef[]>;
};

const GuidedCareTemplateGoalRecommendationInterventionsFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateGoalRecommendationInterventionsFormRef,
  GuidedCareTemplateGoalRecommendationInterventionsFormProps
> = (props, ref) => {
  const { goalIndex, recommendationIndex } = props;

  const refs = useRef<GuidedCareTemplateGoalRecommendationInterventionActivitiesFormRef[]>([]);

  const { t } = useTranslation("gcadmin");

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();
  const { teamMembers } = form.getValues();

  const fieldArray = useFieldArray<IGuidedCareTemplateUpsertFormValues, IGuidedCareTemplateGoalRecommendationInterventionsFields>({
    control: form.control,
    name: `goals.${goalIndex}.recommendations.${recommendationIndex}.interventions`,
  });

  const onGuidedCareTemplateCustomAccordionChange = (event: IGuidedCareTemplateCustomAccordionEvent, interventionIndex: number) => {
    if (event.type === "ADD_CLICK") {
      GuidedCareTemplateGoalRecommendationInterventionActivityUpsertModalApi.show(true, {
        goalIndex,
        recommendationIndex,
        interventionIndex,
        teamMembers,
      });
    } else if (event.type === "UPDATE_CLICK") {
      GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi.show(true, {
        goalIndex,
        recommendationIndex,
        interventionIndex,
        intervention: fieldArray.fields[interventionIndex],
      });
    } else if (event.type === "DELETE_CLICK") {
      GuidedCareTemplateGoalDeleteConfirmationModalApi.show(true, {
        type: "INTERVENTION",
        goalIndex,
        recommendationIndex,
        interventionIndex,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    fieldArray: () => fieldArray,
    interventionsRefs: refs,
  }));

  useEffect(() => {
    refs.current = fieldArray.fields.map((_, index) => refs.current[index] || createRef());
  }, [fieldArray.fields]);

  return (
    <Box>
      {fieldArray?.fields?.length ? (
        fieldArray?.fields.map((item, index) => {
          return (
            <GuidedCareTemplateCustomAccordion
              key={item?.id}
              type={"INTERVENTION"}
              title={pickLocalizedValue(item?.name, item?.nameAr || item?.name)}
              description={pickLocalizedValue(item?.description, item?.descriptionAr || item?.description)}
              onChange={event => onGuidedCareTemplateCustomAccordionChange(event, index)}
            >
              <GuidedCareTemplateGoalRecommendationInterventionActivitiesForm
                goalIndex={goalIndex}
                recommendationIndex={recommendationIndex}
                interventionIndex={index}
                ref={el => (refs.current[index] = el as GuidedCareTemplateGoalRecommendationInterventionActivitiesFormRef)}
              />
            </GuidedCareTemplateCustomAccordion>
          );
        })
      ) : (
        <Typography>{t("There are no interventions linked to this recommendation")}.</Typography>
      )}
    </Box>
  );
};

export const GuidedCareTemplateGoalRecommendationInterventionsForm = forwardRef(
  GuidedCareTemplateGoalRecommendationInterventionsFormForwardRef
);
