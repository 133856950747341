/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthConditionOperationsFragmentFragmentDoc } from '../../fragments/__generated__/healthConditionOperationsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFieldFromHealthConditionMutationVariables = Types.Exact<{
  healthConditionId: Types.Scalars['ID'];
  healthParameterId: Types.Scalars['ID'];
}>;


export type DeleteFieldFromHealthConditionMutation = { __typename?: 'Mutation', deleteFieldFromHealthCondition?: { __typename?: 'HealthCondition', arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, disease?: string | null, display?: string | null, id?: string | null, isActive?: boolean | null, type?: Types.HealthConditionType | null, umlsCode?: string | null, fields?: Array<{ __typename?: 'HealthParameter', id?: string | null } | null> | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null };


export const DeleteFieldFromHealthConditionDocument = gql`
    mutation DeleteFieldFromHealthCondition($healthConditionId: ID!, $healthParameterId: ID!) {
  deleteFieldFromHealthCondition(
    healthConditionId: $healthConditionId
    healthParameterId: $healthParameterId
  ) {
    ...HealthConditionOperationsFragment
    fields {
      id
    }
  }
}
    ${HealthConditionOperationsFragmentFragmentDoc}`;
export type DeleteFieldFromHealthConditionMutationFn = Apollo.MutationFunction<DeleteFieldFromHealthConditionMutation, DeleteFieldFromHealthConditionMutationVariables>;

/**
 * __useDeleteFieldFromHealthConditionMutation__
 *
 * To run a mutation, you first call `useDeleteFieldFromHealthConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldFromHealthConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldFromHealthConditionMutation, { data, loading, error }] = useDeleteFieldFromHealthConditionMutation({
 *   variables: {
 *      healthConditionId: // value for 'healthConditionId'
 *      healthParameterId: // value for 'healthParameterId'
 *   },
 * });
 */
export function useDeleteFieldFromHealthConditionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFieldFromHealthConditionMutation, DeleteFieldFromHealthConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFieldFromHealthConditionMutation, DeleteFieldFromHealthConditionMutationVariables>(DeleteFieldFromHealthConditionDocument, options);
      }
export type DeleteFieldFromHealthConditionMutationHookResult = ReturnType<typeof useDeleteFieldFromHealthConditionMutation>;
export type DeleteFieldFromHealthConditionMutationResult = Apollo.MutationResult<DeleteFieldFromHealthConditionMutation>;
export type DeleteFieldFromHealthConditionMutationOptions = Apollo.BaseMutationOptions<DeleteFieldFromHealthConditionMutation, DeleteFieldFromHealthConditionMutationVariables>;