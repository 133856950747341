import { chartTypeTypeOptionsMap, templateFieldTypeOptionsMap } from "@health/enum-options";
import { HealthParameter, HealthParameterSortingField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography, Typography, formatDate } from "@toolkit/ui";
import { defaultTo } from "lodash";
import { useMemo } from "react";
import { AllowedValues } from "./AllowedValues";

export type HealthParametersColumnTypes = CustomTableColumnProps<HealthParameter>;

export const useHealthParametersColumns = (): HealthParametersColumnTypes[] => {
  const { t, i18n } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "standardCode",
        header: t("Standard Code"),
        accessor: "standardCode",
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthParameterSortingField.StandardCode,
        filter: {
          type: "string",
          name: "standardCode",
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthParameterSortingField.Code,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "display",
        header: t("Display"),
        accessor: ({ display, arabicDisplay }) => (
          <TruncateTypography text={defaultTo(i18n.language === "ar" ? arabicDisplay || display : display, "-")} />
        ),
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: i18n.language === "ar" ? HealthParameterSortingField.ArabicDisplay : HealthParameterSortingField.Display,
        filter: {
          type: "string",
          name: i18n.language === "ar" ? "arabicDisplay" : "display",
        },
      },
      {
        key: "category",
        header: t("Category"),
        accessor: ({ category }) => (i18n.language === "ar" ? category?.arabicDisplay || category?.display : category?.display),
        type: "string",
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => templateFieldTypeOptionsMap[type!]?.label || "-",
        type: "string",
      },
      {
        key: "ChartType",
        header: t("Chart Type"),
        accessor: ({ chartType }) => <Typography textAlign='center'>{chartTypeTypeOptionsMap[chartType!]?.label || "-"}</Typography>,
        type: "string",
      },
      {
        key: "unit",
        header: t("Unit"),
        accessor: ({ unit }) => defaultTo(i18n.language === "ar" ? unit?.arabicDisplay || unit?.display : unit?.display, "-"),
        type: "string",
      },
      {
        key: "referenceRange",
        header: t("Reference Range"),
        accessor: ({ referenceRange }) => defaultTo(referenceRange, "-"),
        type: "string",
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthParameterSortingField.CreatedDate,
      },
      {
        key: "defaultRangeValues",
        header: t("Default Range Values"),
        accessor: ({ allowedValues, type, minNormalRangeValue, maxNormalRangeValue, defaultValue }) => (
          <AllowedValues
            minNormalRangeValue={minNormalRangeValue}
            defaultValue={defaultValue}
            maxNormalRangeValue={maxNormalRangeValue}
            allowedValues={allowedValues}
            type={type}
          />
        ),
        type: "string",
      },
    ];
  }, []);
};
