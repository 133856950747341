/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { LabTemplateFragmentDoc } from '../../fragments/__generated__/LabTemplate';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddFieldToLabTemplateMutationVariables = Types.Exact<{
  healthParameterId: Types.Scalars['ID'];
  labTemplateId: Types.Scalars['ID'];
}>;


export type AddFieldToLabTemplateMutation = { __typename?: 'Mutation', addFieldToLabTemplate?: { __typename?: 'LabTemplate', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, createdDate?: string | null, isActive?: boolean | null, labCodeDisplay?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, fields?: Array<{ __typename?: 'HealthParameter', allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, createdDate?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, display?: string | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null> | null } | null };


export const AddFieldToLabTemplateDocument = gql`
    mutation addFieldToLabTemplate($healthParameterId: ID!, $labTemplateId: ID!) {
  addFieldToLabTemplate(
    healthParameterId: $healthParameterId
    labTemplateId: $labTemplateId
  ) {
    ...LabTemplate
    errors {
      code
      errorType
      field
      message
    }
  }
}
    ${LabTemplateFragmentDoc}`;
export type AddFieldToLabTemplateMutationFn = Apollo.MutationFunction<AddFieldToLabTemplateMutation, AddFieldToLabTemplateMutationVariables>;

/**
 * __useAddFieldToLabTemplateMutation__
 *
 * To run a mutation, you first call `useAddFieldToLabTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFieldToLabTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFieldToLabTemplateMutation, { data, loading, error }] = useAddFieldToLabTemplateMutation({
 *   variables: {
 *      healthParameterId: // value for 'healthParameterId'
 *      labTemplateId: // value for 'labTemplateId'
 *   },
 * });
 */
export function useAddFieldToLabTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddFieldToLabTemplateMutation, AddFieldToLabTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFieldToLabTemplateMutation, AddFieldToLabTemplateMutationVariables>(AddFieldToLabTemplateDocument, options);
      }
export type AddFieldToLabTemplateMutationHookResult = ReturnType<typeof useAddFieldToLabTemplateMutation>;
export type AddFieldToLabTemplateMutationResult = Apollo.MutationResult<AddFieldToLabTemplateMutation>;
export type AddFieldToLabTemplateMutationOptions = Apollo.BaseMutationOptions<AddFieldToLabTemplateMutation, AddFieldToLabTemplateMutationVariables>;