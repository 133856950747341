import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";
import { RouteItem } from "./routeTypes";

export const useAppRoutes = (): RouteItem[] => {
  const navigate = useNavigate();
  const { t } = useTranslation("gcadmin");
  const auth = useAuth();
  const userInfo = auth?.user?.profile;

  return useMemo(
    () => [
      ROUTE_PATHS.adminManagedListsRoutes({ navigate, t }),
      ROUTE_PATHS.HealthConditionsRoutes({ navigate, t }),
      ROUTE_PATHS.guidedCareRoutes({ navigate, t }),
      ROUTE_PATHS.medicalFormsRoutes({ navigate, t }),
      ROUTE_PATHS.medicalMessagesRoutes({ navigate, t }),
      ROUTE_PATHS.HealthParametersRoutes({ navigate, t }),
      ROUTE_PATHS.HealthTemplates({ navigate, t }),
      ROUTE_PATHS.HealthSymptomsRoutes({ navigate, t }),
      ROUTE_PATHS.decisionsRoutes({ navigate, t }),
      ROUTE_PATHS.knowledgeBaseRoutes({ navigate }),
      ROUTE_PATHS.agentsRoutes({ navigate }),
    ],
    [userInfo]
  );
};
