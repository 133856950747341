import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useBreadCrumbs } from "@toolkit/ui";
import { GridProvider } from "@health/domains";
import { DecisionMakerOrderDirection, HealthParameterSortingField } from "@health/queries/types";
import { HealthParameterListPageWrapper } from "../../components/HealthParameterListPageWrapper/HealthParameterListPageWrapper";
import { healthParametersTitleBreadcrumb } from "../../constants/healthParametersBreadcrumbs";
import { healthParametersPaths } from "../../constants/healthParametersPaths";
import { useGetHealthParametersQuery } from "../gql";
import { useHealthParametersColumns } from "./useHealthParametersColumns";

export const HealthParametersList: FC = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const handleEditRow = data => {
    navigate(healthParametersPaths.editPath.fullPathWithParams(data?.id));
  };

  useEffect(() => {
    setBreadCrumb({
      title: healthParametersTitleBreadcrumb(),
    });
  }, []);

  return (
    <GridProvider
      gridName='getHealthParameters'
      query={useGetHealthParametersQuery}
      columns={useHealthParametersColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
      }}
      variables={{
        sortBy: {
          direction: DecisionMakerOrderDirection.Desc,
          field: HealthParameterSortingField.CreatedDate,
        },
      }}
    >
      <HealthParameterListPageWrapper />
    </GridProvider>
  );
};
