import { GridProvider } from "@health/domains";
import { LabTemplate } from "@health/queries/types";
import { useToggle } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  FormCard,
  Grid,
  MainListActions,
  PageWrapper,
  TableGrid,
  TextField,
  extractCountableConnectionData,
  useBreadCrumbs,
  useGridContext,
} from "@toolkit/ui";
import { useHealthParametersColumns } from "pages/HealthParameters";
import {
  labTemplateFieldBreadcrumb,
  labTemplateListBreadcrumb,
  labTemplateParametersBreadcrumb,
  labTemplatesTitleBreadcrumb,
} from "pages/HealthTemplate/LabParameters/constants/labTemplatesBreadcrumbs";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetLabTemplateQuery } from "../gql";
import { AddFields } from "./AddFields";

export const LabFieldsTemplateList: FC = () => {
  const params = useParams() as { id: string };
  const { id } = params;
  const [template, setTemplate] = useState<LabTemplate | undefined>(undefined);

  const handleChangeTemplate = template => {
    setTemplate(template);
  };

  return (
    <GridProvider
      gridName='getLabTemplate'
      columns={useHealthParametersColumns()}
      extractCountableConnectionData={({ responseData }) => {
        handleChangeTemplate(responseData?.getLabTemplate);
        return extractCountableConnectionData({ responseData: responseData?.getLabTemplate!, entityName: "fields" });
      }}
      variables={{
        id: id!,
      }}
      query={useGetLabTemplateQuery}
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
    >
      <LabFieldsTemplate template={template} />
    </GridProvider>
  );
};

const LabFieldsTemplate: FC<{ template: LabTemplate | undefined }> = ({ template }) => {
  const { t } = useTranslation("gcadmin");
  const { open, handleToggle } = useToggle();

  const { setBreadCrumb } = useBreadCrumbs();

  const { customTableProps } = useGridContext();

  useEffect(() => {
    if (template?.display) {
      setBreadCrumb({
        title: labTemplatesTitleBreadcrumb(),
        values: [labTemplateListBreadcrumb(), labTemplateFieldBreadcrumb(template.display), labTemplateParametersBreadcrumb()],
      });
    }
  }, [template?.display]);

  return (
    <>
      <PageWrapper actions={<MainListActions hasAddNew onAddNewItem={handleToggle} />}>
        <Box sx={{ marginBottom: 1 }}>
          <FormCard title={t("Template")} loading={customTableProps?.isLoading} doYouHaveData={Boolean(template)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("Display")} defaultValue={template?.display} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("Arabic Display")} defaultValue={template?.arabicDisplay} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField disabled fullWidth size='small' label={t("Code")} defaultValue={template?.code} />
              </Grid>
            </Grid>
          </FormCard>
        </Box>
        <TableGrid />
      </PageWrapper>

      {open && <AddFields onToggle={handleToggle} open={open} />}
    </>
  );
};
