import { useBreadCrumbs } from "@toolkit/ui";
import {
  labTemplateListBreadcrumb,
  labTemplateNewBreadcrumb,
  labTemplatesTitleBreadcrumb,
} from "pages/HealthTemplate/LabParameters/constants/labTemplatesBreadcrumbs";
import React, { FC, useEffect } from "react";
import { LabTemplateForm } from "../LabTemplateForm";
import { useLabTemplateAdd } from "./useLabTemplateAdd";

export const LabTemplateNewPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, handleSubmit, isSubmitting } = useLabTemplateAdd();

  useEffect(() => {
    setBreadCrumb({
      title: labTemplatesTitleBreadcrumb(),
      values: [labTemplateListBreadcrumb(), labTemplateNewBreadcrumb()],
    });
  }, []);

  return <LabTemplateForm errors={errors} onSubmit={handleSubmit} isSubmitting={isSubmitting} />;
};
