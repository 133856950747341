import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import React, { FC, useEffect, useState } from "react";

export type IGuidedCareTemplateGoalDeleteConfirmationModalEvent =
  | {
      type: "DELETE_GOAL";
      payload: {
        goalIndex: number;
      };
    }
  | {
      type: "DELETE_RECOMMENDATION";
      payload: {
        goalIndex: number;
        recommendationIndex: number;
      };
    }
  | {
      type: "DELETE_INTERVENTION";
      payload: {
        goalIndex: number;
        recommendationIndex: number;
        interventionIndex: number;
      };
    };

type IGuidedCareTemplateGoalDeleteConfirmationModalShowOptions =
  | {
      type: "GOAL";
      goalIndex: number;
    }
  | {
      type: "RECOMMENDATION";
      goalIndex: number;
      recommendationIndex: number;
    }
  | {
      type: "INTERVENTION";
      goalIndex: number;
      recommendationIndex: number;
      interventionIndex: number;
    };

type IShow = (show: boolean, showOptions?: IGuidedCareTemplateGoalDeleteConfirmationModalShowOptions) => void;

export const GuidedCareTemplateGoalDeleteConfirmationModalApi: { show: IShow } = {
  show: () => {},
};

type GuidedCareTemplateActivityUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateGoalDeleteConfirmationModalEvent) => void;
};

export const GuidedCareTemplateGoalDeleteConfirmationModal: FC<GuidedCareTemplateActivityUpsertModalProps> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState<IGuidedCareTemplateGoalDeleteConfirmationModalShowOptions>();

  const { t } = useTranslation("gcadmin");

  const getCustomDialogText = () => {
    if (showOptions?.type === "GOAL") {
      return {
        title: t("Delete Goal Confirmation"),
        body: t(
          "Please be aware that deleting this goal will also remove all associated recommendations, along with their interventions and activities"
        ),
      };
    } else if (showOptions?.type === "RECOMMENDATION") {
      return {
        title: t("Delete Recommendation Confirmation"),
        body: t("Please be aware that deleting this recommendation will also remove all associated interventions and activities"),
      };
    } else if (showOptions?.type === "INTERVENTION") {
      return {
        title: t("Delete Intervention Confirmation"),
        body: t("Please be aware that deleting this intervention will also remove all associated activities"),
      };
    }
  };

  const onCustomDialogClose = () => {
    setIsOpen(false);
  };

  const onCustomDialogDelete = () => {
    if (showOptions?.type === "GOAL") {
      onChange({
        type: "DELETE_GOAL",
        payload: {
          goalIndex: showOptions?.goalIndex,
        },
      });
    } else if (showOptions?.type === "RECOMMENDATION") {
      onChange({
        type: "DELETE_RECOMMENDATION",
        payload: {
          goalIndex: showOptions?.goalIndex,
          recommendationIndex: showOptions?.recommendationIndex,
        },
      });
    } else if (showOptions?.type === "INTERVENTION") {
      onChange({
        type: "DELETE_INTERVENTION",
        payload: {
          goalIndex: showOptions?.goalIndex,
          recommendationIndex: showOptions?.recommendationIndex,
          interventionIndex: showOptions?.interventionIndex,
        },
      });
    }
  };

  const show: IShow = (open, _showOptions) => {
    setIsOpen(open);
    setShowOptions(_showOptions);
  };

  useEffect(() => {
    GuidedCareTemplateGoalDeleteConfirmationModalApi.show = show;
  }, []);

  return (
    <CustomDialog
      type={"delete"}
      isOpen={isOpen}
      text={{ ...getCustomDialogText() }}
      onDelete={onCustomDialogDelete}
      onClose={onCustomDialogClose}
    />
  );
};
