import { replacePathParams } from "utils";

export const healthParametersRoute = `/health-parameters`;
const healthParametersNewRoute = "new";
const healthParametersEditRoute = "edit/:id";
const healthParametersNewDecisionRoute = ":healthParameterId/rules/new";
const healthParametersUpdateDecisionRoute = ":healthParameterId/rules/:id";

export const healthParametersPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${healthParametersRoute}${this.route}`;
    },
  },
  newPath: {
    route: healthParametersNewRoute,
    get fullPath() {
      return `${healthParametersRoute}/${this.route}`;
    },
  },
  editPath: {
    route: healthParametersEditRoute,
    get fullPath() {
      return `${healthParametersRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
  newDecisionPath: {
    route: healthParametersNewDecisionRoute,
    get fullPath() {
      return `${healthParametersRoute}/${this.route}`;
    },
    fullPathWithParams: function (healthParameterId: string) {
      return replacePathParams(this.fullPath, { healthParameterId });
    },
  },
  updateDecisionPath: {
    route: healthParametersUpdateDecisionRoute,
    get fullPath() {
      return `${healthParametersRoute}/${this.route}`;
    },
    fullPathWithParams: function (healthParameterId: string, id: string) {
      return replacePathParams(this.fullPath, { healthParameterId, id });
    },
  },
};
