import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateGoalFormUpsertFormEvent } from "pages/Templates/types";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateGoalUpsertForm,
  GuidedCareTemplateGoalUpsertFormRef,
} from "../../forms/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertForm";
import { IGuidedCareTemplateGoalUpsertFormValues } from "../../forms/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";

type IGuidedCareTemplateGoalUpsertModalShowOptions = {
  goal: IGuidedCareTemplateGoalUpsertFormValues;
};
type IShow = (show: boolean, showOptions?: IGuidedCareTemplateGoalUpsertModalShowOptions) => void;

export const GuidedCareTemplateGoalUpsertModalApi: { show: IShow } = {
  show: () => {},
};

type GuidedCareTemplateGoalUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateGoalFormUpsertFormEvent) => void;
};

export const GuidedCareTemplateGoalUpsertModal: FC<GuidedCareTemplateGoalUpsertModalProps> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState<IGuidedCareTemplateGoalUpsertModalShowOptions>();

  const { t } = useTranslation("gcadmin");

  const templateGoalUpsertFormRef = useRef<GuidedCareTemplateGoalUpsertFormRef>(null);

  const show: IShow = (open, _showOptions) => {
    setIsOpen(open);
    setShowOptions(_showOptions);
  };

  const onButtonClick = () => {
    templateGoalUpsertFormRef.current?.submit();
  };

  useEffect(() => {
    GuidedCareTemplateGoalUpsertModalApi.show = show;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: showOptions?.goal ? t("Update Goal") : t("Add New Goal"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: !!showOptions?.goal,
        submitTitle: showOptions?.goal ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateGoalUpsertForm goal={showOptions?.goal} ref={templateGoalUpsertFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
