import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Button, CustomDialog } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAddFieldToRiskFactorTemplate } from "./useAddFieldToRiskFactorTemplate";

export const AddFields: FC<{ open: boolean; onToggle: () => void }> = ({ open, onToggle: handleToggle }) => {
  const { t, i18n } = useTranslation("gcadmin");

  const { errors, pageInfo, getRiskFactorFields, fetchMoreData, handleAddFieldToRiskFactorTemplate, handleSearch } =
    useAddFieldToRiskFactorTemplate(handleToggle);

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    control,
    formState: { errors: reactFormErrors },
  } = methods;

  const formErrors = combineErrors(reactFormErrors, errors);

  const onSubmit = input => {
    handleAddFieldToRiskFactorTemplate(input?.riskFactorFieldId?.id);
  };

  return (
    <FormProvider {...methods}>
      <CustomDialog
        type='base'
        maxWidth='sm'
        title={t("Parameters")}
        open={open}
        DialogTitleProps={{
          title: t("Parameters"),
          onClose: handleToggle,
        }}
        DialogActionsProps={{
          children: (
            <Button type='submit' onClick={handleSubmit(onSubmit)}>
              {t("Add Parameter")}
            </Button>
          ),
        }}
        button={
          <Button variant='outlined' onClick={handleToggle}>
            {t("Parameters")}
          </Button>
        }
        DialogContentProps={{
          sx: { minHeight: "unset" },
        }}
      >
        <AutocompleteController
          hasMore={pageInfo?.hasNextPage}
          fetchMoreData={fetchMoreData}
          options={getRiskFactorFields}
          getOptionLabel={option => (i18n.language === "ar" ? option.arabicDisplay : option.display)}
          ControllerProps={{
            control: control,
            name: "riskFactorFieldId",
          }}
          TextFieldProps={{
            label: t("Parameters"),
            error: Boolean(formErrors.riskFactorFieldId?.message),
            helperText: formErrors.riskFactorFieldId?.message,
          }}
          onSearchInput={handleSearch}
        />
      </CustomDialog>
    </FormProvider>
  );
};
