import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { i18n, useTranslation } from "@toolkit/i18n";
import { useAddToast, useAutocompleteCustomHook } from "@toolkit/ui";
import { useGetHealthParametersForAutoCompleteQuery } from "pages/HealthConditions/gql";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAddFieldToRiskFactorTemplateMutation } from "../gql";

export const useAddFieldToRiskFactorTemplate = (handleToggle?) => {
  const { t } = useTranslation("gcadmin");
  const params = useParams();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const [addFieldToRiskFactorTemplate, { loading: loadingAdd }] = useAddFieldToRiskFactorTemplateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.addFieldToRiskFactorTemplate?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Add Field To Risk Factor Template Successfully"));
        handleToggle();
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Add Field To Risk Factor Template Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const {
    data,
    isLoading: loading,
    onFetchMoreData,
    refetch,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useGetHealthParametersForAutoCompleteQuery,
    input: {
      first: 10,
    },
    dataAccessor: "getHealthParameters",
  });
  const getRiskFactorFields = data?.getHealthParameters?.edges?.map(item => item?.node);

  const pageInfo = data?.getHealthParameters?.pageInfo;

  const handleAddFieldToRiskFactorTemplate = healthParameterId => {
    addFieldToRiskFactorTemplate({
      variables: {
        healthParameterId,
        riskFactorTemplateId: params.id!,
      },
    });
  };
  const handleSearch = (filter: string) => {
    refetch({
      filter: {
        [i18n.language === "ar" ? "arabicDisplay" : "display"]: filter,
      },
    });
  };

  return {
    errors,
    loading: loading || loadingAdd,
    pageInfo,
    getRiskFactorFields,
    fetchMoreData: onFetchMoreData,
    handleAddFieldToRiskFactorTemplate,
    isSubmitting: loadingAdd,
    handleSearch,
  };
};
