import { useBreadCrumbs } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { HealthProviderForm } from "../HealthProviderForm";
import {
  healthParametersListBreadcrumb,
  healthParametersTitleBreadcrumb,
  healthParametersNewBreadcrumb,
} from "pages/HealthParameters/constants/healthParametersBreadcrumbs";
import { useHealthParameterAdd } from "./useHealthParameterAdd";

export const HealthParameterNewPage: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  const { errors, handleSubmit, isSubmitting } = useHealthParameterAdd();

  useEffect(() => {
    setBreadCrumb({
      title: healthParametersTitleBreadcrumb(),
      values: [healthParametersListBreadcrumb(), healthParametersNewBreadcrumb()],
    });
  }, []);

  return <HealthProviderForm errors={errors} onSubmit={handleSubmit} isSubmitting={isSubmitting} />;
};
