/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminMedicalFormGetQueryVariables = Types.Exact<{
  medicalFormId: Types.Scalars['ID'];
}>;


export type AdminMedicalFormGetQuery = { __typename?: 'Query', medicalForm?: { __typename?: 'MedicalForm', id: string, active?: boolean | null, name?: string | null, nameAr?: string | null, code?: string | null, type?: Types.MedicalFormType | null, category?: Types.MedicalFormCategory | null, description?: string | null, medicalFormClassifications?: Array<{ __typename?: 'MedicalFormClassification', id: string, name?: string | null, rangeFrom?: number | null, rangeTo?: number | null } | null> | null, questions?: Array<{ __typename?: 'Question', id?: string | null, question?: string | null, questionType?: Types.QuestionType | null, required?: boolean | null, options?: Array<string | null> | null, questionOptionScores?: Array<{ __typename?: 'QuestionOptionScore', id?: string | null, option?: string | null, score?: number | null } | null> | null }> | null } | null };


export const AdminMedicalFormGetDocument = gql`
    query AdminMedicalFormGet($medicalFormId: ID!) {
  medicalForm(id: $medicalFormId) {
    id
    active
    name
    nameAr
    code
    type
    category
    description
    medicalFormClassifications {
      id
      name
      rangeFrom
      rangeTo
    }
    questions {
      id
      question
      questionType
      required
      options
      questionOptionScores {
        id
        option
        score
      }
    }
  }
}
    `;

/**
 * __useAdminMedicalFormGetQuery__
 *
 * To run a query within a React component, call `useAdminMedicalFormGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMedicalFormGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMedicalFormGetQuery({
 *   variables: {
 *      medicalFormId: // value for 'medicalFormId'
 *   },
 * });
 */
export function useAdminMedicalFormGetQuery(baseOptions: Apollo.QueryHookOptions<AdminMedicalFormGetQuery, AdminMedicalFormGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMedicalFormGetQuery, AdminMedicalFormGetQueryVariables>(AdminMedicalFormGetDocument, options);
      }
export function useAdminMedicalFormGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMedicalFormGetQuery, AdminMedicalFormGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMedicalFormGetQuery, AdminMedicalFormGetQueryVariables>(AdminMedicalFormGetDocument, options);
        }
export type AdminMedicalFormGetQueryHookResult = ReturnType<typeof useAdminMedicalFormGetQuery>;
export type AdminMedicalFormGetLazyQueryHookResult = ReturnType<typeof useAdminMedicalFormGetLazyQuery>;
export type AdminMedicalFormGetQueryResult = Apollo.QueryResult<AdminMedicalFormGetQuery, AdminMedicalFormGetQueryVariables>;