import { DecisionMakerGraphqlError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { i18n, useTranslation } from "@toolkit/i18n";
import { useAddToast, useAutocompleteCustomHook } from "@toolkit/ui";
import { useGetHealthParametersForAutoCompleteQuery } from "pages/HealthConditions/gql";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAddFieldToLabTemplateMutation } from "../gql";

export const useAddFieldToLabTemplate = (handleToggle?) => {
  const { t } = useTranslation("gcadmin");
  const params = useParams();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<DecisionMakerGraphqlError[] | undefined>([]);

  const [addFieldToLabTemplate, { loading: loadingAdd }] = useAddFieldToLabTemplateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.addFieldToLabTemplate?.errors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Add Field To Lab Template Successfully"));
        handleToggle();
      } else {
        formatMessageErrors(mutationErrors);
        setErrors(mutationErrors as DecisionMakerGraphqlError[]);
        failed(t("Add Field To Lab Template Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const {
    data,
    isLoading: loading,
    onFetchMoreData,
    refetch,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useGetHealthParametersForAutoCompleteQuery,
    input: {
      first: 10,
      filter: {
        categoryCode: "lab",
      },
    },
    dataAccessor: "getHealthParameters",
  });
  const getLabFields = data?.getHealthParameters?.edges?.map(item => item?.node);

  const pageInfo = data?.getHealthParameters?.pageInfo;
  const handleAddFieldToLabTemplate = labFieldId => {
    addFieldToLabTemplate({
      variables: {
        labTemplateId: params.id!,
        healthParameterId: labFieldId,
      },
    });
  };
  const handleSearch = (filter: string) => {
    refetch({
      first: 10,
      filter: {
        categoryCode: "lab",
        [i18n.language === "ar" ? "arabicDisplay" : "display"]: filter,
      },
    });
  };

  return {
    errors,
    loading: loading || loadingAdd,
    pageInfo,
    getLabFields,
    fetchMoreData: onFetchMoreData,
    handleAddFieldToLabTemplate,
    isSubmitting: loadingAdd,
    handleSearch,
  };
};
