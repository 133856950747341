/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { RiskFactorTemplateFragmentFragmentDoc } from '../../fragments/__generated__/RiskFactorTemplateFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DuplicateRiskFactorTemplateMutationVariables = Types.Exact<{
  duplicateRiskFactorTemplateId: Types.Scalars['ID'];
  input: Types.RiskFactorTemplateInput;
}>;


export type DuplicateRiskFactorTemplateMutation = { __typename?: 'Mutation', duplicateRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', id?: string | null, isActive?: boolean | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, fields?: Array<{ __typename?: 'HealthParameter', createdDate?: string | null, allowedValues?: Array<string | null> | null, arabicDisplay?: string | null, code?: string | null, standardCode?: string | null, standardCodeDisplay?: string | null, defaultValue?: string | null, display?: string | null, id?: string | null, isMandatory?: boolean | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, referenceRange?: string | null, standard?: Types.FieldStandard | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, visibleForPatientOverview?: boolean | null, category?: { __typename?: 'FieldCategory', arabicDisplay?: string | null, code?: string | null, id?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, unit?: { __typename?: 'FieldUnit', arabicDisplay?: string | null, code?: string | null, display?: string | null, id?: string | null } | null } | null> | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', message?: string | null, field?: string | null, errorType?: Types.ErrorType | null, code?: Types.DecisionMakerErrorCodes | null } | null> | null } | null };


export const DuplicateRiskFactorTemplateDocument = gql`
    mutation DuplicateRiskFactorTemplate($duplicateRiskFactorTemplateId: ID!, $input: RiskFactorTemplateInput!) {
  duplicateRiskFactorTemplate(id: $duplicateRiskFactorTemplateId, input: $input) {
    ...RiskFactorTemplateFragment
  }
}
    ${RiskFactorTemplateFragmentFragmentDoc}`;
export type DuplicateRiskFactorTemplateMutationFn = Apollo.MutationFunction<DuplicateRiskFactorTemplateMutation, DuplicateRiskFactorTemplateMutationVariables>;

/**
 * __useDuplicateRiskFactorTemplateMutation__
 *
 * To run a mutation, you first call `useDuplicateRiskFactorTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRiskFactorTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRiskFactorTemplateMutation, { data, loading, error }] = useDuplicateRiskFactorTemplateMutation({
 *   variables: {
 *      duplicateRiskFactorTemplateId: // value for 'duplicateRiskFactorTemplateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateRiskFactorTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateRiskFactorTemplateMutation, DuplicateRiskFactorTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateRiskFactorTemplateMutation, DuplicateRiskFactorTemplateMutationVariables>(DuplicateRiskFactorTemplateDocument, options);
      }
export type DuplicateRiskFactorTemplateMutationHookResult = ReturnType<typeof useDuplicateRiskFactorTemplateMutation>;
export type DuplicateRiskFactorTemplateMutationResult = Apollo.MutationResult<DuplicateRiskFactorTemplateMutation>;
export type DuplicateRiskFactorTemplateMutationOptions = Apollo.BaseMutationOptions<DuplicateRiskFactorTemplateMutation, DuplicateRiskFactorTemplateMutationVariables>;