/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { articlesCategoriesPaths, articlesCategoriesRoute } from "./ArticleCategoriesPaths";
import { ArticleCategoriesList } from "../containers/ArticleCategoriesList/ArticleCategoriesListPage";
import { ArticleCategoryUpdateContainer } from "../containers/ArticleCategoryUpdate/ArticleCategoryUpdateContainer";
import { ArticleCategoryCreateContainer } from "../containers/ArticleCategoryCerate/ArticleCategoryCreateContainer";

export const articlesCategoriesRoutes: (props: { navigate: NavigateFunction; enabled: boolean }) => RouteItem = ({ navigate, enabled }) => {
  return {
    id: "articles-categories",
    text: i18n.t("Article's categories", { ns: "gcadmin" }),
    route: articlesCategoriesRoute,
    fullPath: articlesCategoriesPaths.main.fullPath,
    onClick: route => navigate(route),
    subItems: [
      {
        id: "article-categories-main",
        text: i18n.t("Article's categories", { ns: "gcadmin" }),
        route: articlesCategoriesPaths.main.route,
        fullPath: articlesCategoriesPaths.main.fullPath,
        element: <ArticleCategoriesList />,
        hidden: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "article-categories-new",
        text: i18n.t("New", { ns: "gcadmin" }),
        element: <ArticleCategoryCreateContainer />,
        route: articlesCategoriesPaths.new.route,
        fullPath: articlesCategoriesPaths.new.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "article-categories-update",
        text: i18n.t("Update", { ns: "gcadmin" }),
        element: <ArticleCategoryUpdateContainer />,
        route: articlesCategoriesPaths.update.route,
        fullPath: articlesCategoriesPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
    ],
  };
};
