import { useTranslation } from "@toolkit/i18n";
import { Box, ToggleButtonController } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IForm } from "../types";

export const ToggleButtonGroup: FC<IForm> = ({ defaultValue }) => {
  const { t } = useTranslation("gcadmin");
  const { register, control, setValue } = useFormContext();
  useEffect(() => {
    setValue("isMandatory", defaultValue?.isMandatory ?? false);
    setValue("visibleForPatientOverview", defaultValue?.visibleForPatientOverview ?? false);
  }, []);
  return (
    <Box display='flex' flexWrap='wrap'>
      <Box display='flex' alignItems='center'>
        <ToggleButtonController
          {...register("isMandatory")}
          control={control}
          defaultValue={defaultValue?.isMandatory ?? false}
          label={t("Is Mandatory")}
        />
      </Box>
      <Box display='flex' alignItems='center'>
        <ToggleButtonController
          {...register("visibleForPatientOverview")}
          control={control}
          defaultValue={defaultValue?.visibleForPatientOverview ?? false}
          label={t("Is Visible For Patient")}
        />
      </Box>
    </Box>
  );
};
